export enum FilterOperator {
  contains = 'contains',
  notContains = 'notContains',
  equals = 'equals',
  notEqual = 'notEqual',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lessThanOrEqual = 'lessThanOrEqual',
  inRange = 'inRange',
  empty = 'empty',
}
