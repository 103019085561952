import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { FrontendFormsModule } from '@heitown/frontend-forms';
import { UiKitModule } from '@heitown/frontend-ui-kit';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import * as fromAuth from './+state/auth.reducer';
import { AuthConfig } from './auth-config';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { CognitoAuthService } from './cognito-auth.service';
import {
    AcceptInvitationContainer
} from './containers/accept-invitation/accept-invitation.container';
import { ForgotPasswordContainer } from './containers/forgot-password/forgot-password.container';
import { LoginRootContainer } from './containers/login-root/login-root.container';
import { LoginContainer } from './containers/login/login.container';
import { OAuthContainer } from './containers/oauth/oauth.container';
import { PasswordChangedContainer } from './containers/password-changed/password-changed.container';
import { ProfileContainer } from './containers/profile/profile.container';
import { ProfileGuard } from './containers/profile/profile.guard';
import { ResetPasswordContainer } from './containers/reset-password/reset-password.container';
import { MockAuthService } from './mock-auth.service';
import { TokenInterceptorService } from './token-interceptor.service';

export function AuthServiceFactory(config: AuthConfig, http: HttpClient) {
  if (config.localMock) {
    return new MockAuthService(config, http);
  }
  return new CognitoAuthService(config, http);
}

export function configureAuth(config: AuthConfig) {
  return () => {
    if (!config.localMock) {
      let storage = sessionStorage;
      const rememberMe = localStorage.getItem('oauth_remember_me');
      if (rememberMe != null && Boolean(rememberMe)) {
        storage = localStorage;
        localStorage.removeItem('oauth_remember_me');
      }
      return CognitoAuthService.configureAmplify(config, storage);
    }
  };
}

const routes: Route[] = [
  {
    path: 'auth',
    component: LoginRootContainer,
    children: [
      {
        path: 'login',
        pathMatch: 'full',
        component: LoginContainer,
      },
      {
        path: 'accept-invitation',
        pathMatch: 'full',
        component: AcceptInvitationContainer,
      },
      {
        path: 'forgot-password',
        pathMatch: 'full',
        component: ForgotPasswordContainer,
      },
      {
        path: 'reset-password',
        pathMatch: 'full',
        component: ResetPasswordContainer,
      },
      {
        path: 'password-changed',
        pathMatch: 'full',
        component: PasswordChangedContainer,
      },
    ],
  },
  {
    path: 'oauth',
    pathMatch: 'full',
    component: OAuthContainer,
  },
];

// const cognitoConfig = new InjectionToken<CognitoConfig>('CognitoConfig');

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FrontendFormsModule,
    UiKitModule,
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
    HttpClientModule,
  ],
  declarations: [
    LoginContainer,
    OAuthContainer,
    ProfileContainer,
    AcceptInvitationContainer,
    LoginRootContainer,
    ForgotPasswordContainer,
    ResetPasswordContainer,
    PasswordChangedContainer,
  ],
  exports: [],
})
export class AuthModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AuthModule
  ) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import only in AppModule');
    }
  }

  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthEffects,
        AuthGuard,
        AuthFacade,
        TokenInterceptorService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptorService,
          multi: true,
        },
        {
          provide: AuthService,
          useFactory: AuthServiceFactory,
          deps: [AuthConfig, HttpClient],
        },
        {
          provide: AuthConfig,
          useValue: config,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: configureAuth,
          deps: [AuthConfig],
          multi: true,
        },
        ProfileGuard,
      ],
    };
  }
}
