import { NgxPermissionsService } from 'ngx-permissions';
import { map, takeUntil, withLatestFrom } from 'rxjs';

import {
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseDtoModel } from '@heitown/common-dto';
import { FilterValue } from '@heitown/common-interfaces';
import {
  AgGridFilterModel,
  FacadeInterface,
  mapFromColumnState,
  mapFromFilterModel,
  SharedFacadeInterface,
} from '@heitown/frontend-common';
import { NgrxLoaderFacade } from '@heitown/frontend-ngrx-loader';
import { RouterService } from '@heitown/frontend-routing-utils';

import { BaseListContainer } from './base-list.container';
import { loadPage } from './base-paged-list-action';

@Component({
  template: '',
})
export abstract class BasePagedListContainer<T extends BaseDtoModel>
  extends BaseListContainer<T>
  implements OnInit
{
  constructor(
    @Inject('SharedEntityFacade')
    sharedFacade: SharedFacadeInterface<T>,
    @Inject('EntityFacade')
    facade: FacadeInterface<T>,
    routerService: RouterService,
    permissionsService: NgxPermissionsService,
    private loaderFacade: NgrxLoaderFacade
  ) {
    super(sharedFacade, facade, routerService, permissionsService);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.gridOptions$ = this.columnDefs$.pipe(
      withLatestFrom(this.pageSize$),
      map(([columnDefs, pageSize]) => {
        // this.columnDefs = columnDefs;

        const customGridOptions = {
          rowModelType: 'serverSide',
          serverSideStoreType: 'partial',
          cacheBlockSize: pageSize,
          paginationPageSize: pageSize,
          onGridReady: (e: GridReadyEvent) => this.onGridReady(e),
        };
        return Object.assign(this.commonGridOptions, customGridOptions);
      })
    );
  }

  override onGridReady(event: GridReadyEvent) {
    super.onGridReady(event);

    this.pageSize$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pageSize) => {
      setTimeout(() => {
        this.setDatasource(event.api, pageSize);
      }, 0);
    });
  }

  private setDatasource(api: GridApi, pageSize: number) {
    api.setServerSideDatasource({
      destroy: () => {
        console.log('destroy datasource');
      },
      getRows: async (params: IServerSideGetRowsParams) => {
        // Anti redux pattern
        try {
          this.loaderFacade.showLoader(loadPage);
          this.facade.setPageLoaded(false);

          const filters = mapFromFilterModel(
            params.request.filterModel as AgGridFilterModel
          );

          const orderBy = mapFromColumnState(params.request.sortModel);

          const totalCount = await this.getRows(
            pageSize,
            params,
            orderBy,
            filters
          );

          this.facade.setTotalRows(totalCount);
          this.facade.setPageLoaded(true);
          this.loaderFacade.hideLoader(loadPage);
        } catch (err) {
          this.sharedFacade.loadPagedFailure(err as any);
        }
      },
    });
  }

  protected abstract getRows(
    pageSize: number,
    params: IServerSideGetRowsParams,
    orderBy: { [x: string]: any } | undefined,
    filters: FilterValue[]
  ): Promise<number>;
}
