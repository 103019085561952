export enum FieldType {
  text = 'text',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  decimal = 'decimal',
  integer = 'integer',
  percent = 'percent',
  currency = 'currency',
  boolean = 'boolean',
  enum = 'enum',
  document = 'document',
  image = 'image'
}
