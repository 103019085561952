/* eslint-disable @angular-eslint/use-lifecycle-interface */
import * as momentNs from 'moment';
import {
    catchError, debounceTime, distinctUntilChanged, filter, map, Observable, of, OperatorFunction,
    switchMap, tap
} from 'rxjs';

/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Injector, Input, OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDtoModel } from '@heitown/common-dto';
import { FieldType, FilterOperator, FilterType, FilterValue } from '@heitown/common-interfaces';
import { getDescendantProp } from '@heitown/common-utils';
import { BaseApiClient } from '@heitown/frontend-api-client';
import { RouterService } from '@heitown/frontend-routing-utils';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { BaseFormFieldComponent } from '../base-form-field.component';

export interface AutocompleteConfig {
  entityName: string;
  decodeField: string[];
  decodeFieldDisabled?: boolean;
  decodeFieldFormat?: FieldType[];
  codeField?: string;
  filters?: FilterValue[];
  join?: string[];
}

@Component({
  selector: 'heitown-autocomplete-field',
  styleUrls: ['./autocomplete-field.component.scss'],
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative code  me-1" [hidden]="!config.codeField">
          <input
            #codeInput
            type="text"
            class="form-control"
            [class.is-invalid]="shouldShowError"
            [formControl]="formControl"
            [ngbTypeahead]="searchByCode"
            [inputFormatter]="codeFormatter"
            [resultFormatter]="resultFormatter"
            [showHint]="true"
            [editable]="false"
            (selectItem)="selected($event)"
            (blur)="blur(codeInput, decodeInput)"
            [readonly]="readOnly"
            [container]="'body'"
          />
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="visually-hidden">Caricamento...</span>
          </div>
        </div>
        <div class="position-relative decode">
          <input
            #decodeInput
            type="text"
            class="form-control "
            [class.is-invalid]="shouldShowError"
            [formControl]="formControl"
            [ngbTypeahead]="searchByDecode"
            [inputFormatter]="decodeFormatter"
            [resultFormatter]="resultFormatter"
            [showHint]="true"
            [editable]="decodeFieldDisabled()"
            (selectItem)="selected($event)"
            (blur)="blur(codeInput, decodeInput)"
            [readonly]="decodeFieldDisabled() || readOnly"
            [container]="'body'"
          />
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="visually-hidden">Caricamento...</span>
          </div>
        </div>
        <ng-container *ngIf="formControl.enabled && !disabled">
          <i
            *ngIf="canView && !readOnly"
            class="ms-1 cursor-pointer text-primary feather icon-search"
            (click)="search()"
          ></i>
          <i
            *ngIf="canView && formControl.value"
            class="ms-1 cursor-pointer text-primary feather icon-eye"
            (click)="view()"
          ></i>
          <i
            *ngIf="canAdd"
            class="ms-1 cursor-pointer text-primary feather icon-file-plus"
            (click)="add()"
          ></i>
        </ng-container>
      </div>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteFieldComponent
  extends BaseFormFieldComponent
  implements ControlValueAccessor, OnInit
{
  @Input()
  placeholder = '';

  @Input()
  config!: AutocompleteConfig;

  // @Input()
  // bindValue = 'id';

  @Input()
  canAdd? = false;

  @Input()
  canView? = false;

  @Input()
  disabled = false;

  @Input()
  startSearchAfterInsertedChars = 2;
  isLoading = false;

  momentDate = momentNs;
  // decodeFormControl = new FormControl();

  get apiClient() {
    return this.injector.get(
      this.config.entityName + 'ApiClient'
    ) as BaseApiClient<BaseDtoModel>;
  }

  decodeFieldDisabled(): boolean {
    if (this.config.decodeFieldDisabled === true) {
      return true;
    }
    return false;
  }

  // TODO: - quali FieldType consideriamo?
  getFormattedValue(value?: any, type?: FieldType): any {
    switch (type) {
      case FieldType.date:
        return value != null ? this.momentDate(value).format('DD/MM/YYYY') : '';
      case FieldType.time:
        return value != null ? this.momentDate(value).format('HH:mm:ss') : '';
      case FieldType.datetime:
        return value != null
          ? this.momentDate(value).format('DD/MM/YYYY HH:mm:ss')
          : '';
    }

    return value;
  }

  // moment(arg0: any) {
  //   throw new Error('Method not implemented.');
  // }

  codeFormatter = (x: any) => {
    if (this.config.codeField)
      return getDescendantProp(x, this.config.codeField);
  };

  decodeFormatter = (x: any) => {
    let value = '';
    this.config.decodeField.map((dec, index) => {
      let type: FieldType = FieldType.text;
      if (this.config.decodeFieldFormat != undefined) {
        type = this.config.decodeFieldFormat[index];
      }
      value +=
        (value == '' ? '' : ' ') +
        this.getFormattedValue(getDescendantProp(x, dec), type);
    });
    return value;
  };

  resultFormatter = (x: any) => {
    let result = '';

    this.config.decodeField.map((dec, index) => {
      let type: FieldType = FieldType.text;
      if (this.config.decodeFieldFormat != undefined) {
        type = this.config.decodeFieldFormat[index];
      }
      result +=
        (result == '' ? '' : ' ') +
        this.getFormattedValue(getDescendantProp(x, dec), type);
    });

    if (this.config.codeField) {
      return `${x[this.config.codeField]} - ${result}`;
    } else {
      return `${result}`;
    }
  };

  blur(codeInput: HTMLInputElement, decodeInput: HTMLInputElement) {
    if (!this.formControl.value) {
      codeInput.value = decodeInput.value = '';
    }
  }

  selected($event: NgbTypeaheadSelectItemEvent) {
    this.formControl.setValue($event.item);
  }

  searchByCode: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) => {
    if (!this.config.codeField) return of([]);

    const codeField = this.config.codeField;
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= this.startSearchAfterInsertedChars),
      tap(() => {
        this.isLoading = true;
        this.cd.detectChanges();
      }),
      switchMap((term) => {
        // const join: string[] = [];
        const allFilters: FilterValue[] = [
          {
            field: codeField,
            operator: FilterOperator.startsWith,
            value: [term],
            type: FilterType.text,
          },
        ];
        if (this.config.filters) {
          this.config.filters.map((f) => {
            // if (f.field.indexOf('.') > 0) {
            //   const splitted = f.field.split('.');
            //   if (splitted.length > 1) {
            //     join.push(splitted[0]);
            //   }
            // }
            allFilters.push(f);
          });
        }
        return this.apiClient
          .getPaged(
            10,
            0,
            {
              [codeField]: 'asc',
            },
            allFilters,
            this.config.join
            // join
          )
          .pipe(
            map((res) => res.items),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isLoading = false;
              this.cd.detectChanges();
            })
          );
      })
    );
  };

  searchByDecode: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => {
        return term.length >= this.startSearchAfterInsertedChars;
      }),
      tap(() => {
        this.isLoading = true;
        this.cd.detectChanges();
      }),
      switchMap((term) => {
        let sortBy: any = {};
        // const join: string[] = [];
        // sort multiplo
        // filtro su più campi con OR
        const allFilters: FilterValue[] = this.config.decodeField.map((f) => {
          sortBy = Object.assign(sortBy, {
            [f]: 'asc',
          });
          return {
            field: f,
            operator: FilterOperator.startsWith,
            value: [term],
            type: FilterType.text,
            bracketCode: 'multi',
          };
        });

        // considero solo il primo campo
        // const allFilters: FilterValue[] = [
        //   {
        //     field: this.config.decodeField[0],
        //     operator: FilterOperator.contains,
        //     value: [term],
        //     type: FilterType.text,
        //   },
        // ];
        // sortBy = Object.assign(sortBy, {
        //    [this.config.decodeField[0]]: 'asc',
        // })

        if (this.config.filters) {
          this.config.filters.map((f) => {
            // if (f.field.indexOf('.') > 0) {
            //   const splitted = f.field.split('.');
            //   if (splitted.length > 1) {
            //     join.push(splitted[0]);
            //   }
            // }
            allFilters.push(f);
          });
        }
        return this.apiClient
          .getPaged(
            10,
            0,
            sortBy,
            allFilters,
            this.config.join
            // join,
          )
          .pipe(
            map((res) => res.items),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isLoading = false;
              this.cd.detectChanges();
            })
          );
      })
    );

  async add() {
    if (this.config.entityName) {
      try {
        const addedItem = await this.routerService.addNewEntity(
          this.config.entityName
        );

        this.formControl.setValue(addedItem);
        this.cd.detectChanges();
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  async search() {
    if (this.config.entityName) {
      try {
        const foundItem = await this.routerService.searchEntity(
          this.config.entityName
        );

        this.formControl.setValue(foundItem);
        this.cd.detectChanges();
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  async view() {
    if (this.config.entityName) {
      this.routerService.viewEntityDetail(
        this.config.entityName,
        this.formControl.value.id
      );
    }
    // if (w) {
    //   w.onunload = (e) => alert('new window closed');
    // }
  }

  constructor(
    private cd: ChangeDetectorRef,
    private routerService: RouterService,
    private injector: Injector
  ) {
    super();
  }
}
