<!-- <label>{{ label }}</label> -->
<div class="input-group" [class.is-invalid]="hasError">
  <input
    [ngStyle]="{ visibility: hide ? 'hidden' : null }"
    #dp="ngbDatepicker"
    #input
    type="text"
    [class]="inputClasses"
    [class.is-invalid]="hasError"
    name="dp"
    ngbDatepicker
    [(ngModel)]="innerValue"
    (ngModelChange)="onModelChange()"
    [disabled]="isDisabled"
    [container]="$any(container)"
    [autoClose]="autoClose"
    [minDate]="$any(internalMinDate)"
    [maxDate]="$any(internalMaxDate)"
    [readOnly]="readOnly"
  />
  <div class="input-group-append" *ngIf="showIcon && !readOnly">
    <div class="input-group-text pointer" (click)="onToggle()">
      <span class="material-icons text-primary"> calendar_month </span>
    </div>
  </div>
</div>
