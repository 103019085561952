import { createReducer, on } from '@ngrx/store';
import * as LoaderActions from './ngrx-loader.actions';

export const LOADER_FEATURE_KEY = 'loader';

export interface NgrxLoaderPartialState {
  readonly [LOADER_FEATURE_KEY]: LoaderState;
}

export interface LoaderState {
  active: number;
  actionsInProgress: string[];
}

const initialState: LoaderState = {
  active: 0,
  actionsInProgress: [],
};

export const reducer = createReducer(
  initialState,
  on(LoaderActions.showLoader, (state, { actionType }) => {
    const isActionAlreadyInProgress = state.actionsInProgress.filter(
      (currentAction) => currentAction === actionType
    ).length;
    // If the action in already in progress and is registered
    // we don't modify the state
    if (isActionAlreadyInProgress) {
      return state;
    }
    // Adding the action type in our actionsInProgress array
    const newActionsInProgress = [...state.actionsInProgress, actionType];

    return {
      active: newActionsInProgress.length,
      actionsInProgress: newActionsInProgress,
    };
  }),
  on(LoaderActions.hideLoader, (state, { actionType }) => {
    // We remove trigger action from actionsInProgress array
    const newActionsInProgress = actionType
      ? state.actionsInProgress.filter(
          (currentAction) => currentAction !== actionType
        )
      : state.actionsInProgress;

    return {
      actionsInProgress: newActionsInProgress,
      active: state.active > 0 ? newActionsInProgress.length : 0,
    };
  }),
  on(LoaderActions.forceHideLoader, () => {
    const newActionsInProgress: string[] = [];

    return {
      actionsInProgress: newActionsInProgress,
      active: 0,
    };
  })
);
