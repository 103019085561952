import { CognitoUser } from 'amazon-cognito-identity-js';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import Amplify, {
  Auth,
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth';

import { AuthConfig } from './auth-config';
import { AuthErrors, AuthService } from './auth.service';

@Injectable()
export class CognitoAuthService extends AuthService {
  public static configureAmplify(config: AuthConfig, storage: Storage) {
    Amplify.configure({
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: config.identityPoolId,

        // REQUIRED - Amazon Cognito Region
        region: config.region,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'eu-west-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config.clientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: true,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //   domain: 'localhost',
        //   // OPTIONAL - Cookie path
        //   path: '/',
        //   // OPTIONAL - Cookie expiration in days
        //   expires: 365,
        //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //   // sameSite: "strict" | "lax",
        //   // OPTIONAL - Cookie secure flag
        //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //   secure: false,
        // },

        // OPTIONAL - customized storage object
        storage: storage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: config.oauthDomain,
          scope: ['openid'], // 'email', 'profile',
          redirectSignIn: window.location.origin + '/oauth',
          //'https://heitown-dev.auth.eu-west-1.amazoncognito.com/oauth2/idpresponse', //
          redirectSignOut: window.location.origin + '/auth/login',
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    });
  }

  constructor(@Optional() config: AuthConfig, http: HttpClient) {
    super(config, http);
  }

  login(
    username: string,
    password: string,
    rememberMe: boolean,
    newPassword?: string
  ) {
    return from(
      this.internalLogin(username, password, rememberMe, newPassword)
    );
  }

  logout() {
    return from(this.internalLogout());
  }

  signedIn() {
    return from(this.internalGetSession()).pipe(
      map((u) => u != null),
      catchError(() => of(false))
    );
  }

  getToken() {
    return from(this.internalGetToken());
  }

  googleSignIn(rememberMe: boolean) {
    CognitoAuthService.configureAmplify(
      this.config,
      rememberMe ? localStorage : sessionStorage
    );

    localStorage.setItem('oauth_remember_me', true + '');

    return from(
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      })
    );
  }

  facebookSignIn(rememberMe: boolean) {
    CognitoAuthService.configureAmplify(
      this.config,
      rememberMe ? localStorage : sessionStorage
    );

    localStorage.setItem('oauth_remember_me', true + '');

    return from(
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      })
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    return from(this.internalChangePassword(oldPassword, newPassword)).pipe(
      map((r) => true)
    );
  }

  forgotPassword(username: string) {
    // Send confirmation code to user's email
    return from(Auth.forgotPassword(username, { source: 'web' }));
    //{CodeDeliveryDetails:{AttributeName: "email", DeliveryMedium: "EMAIL", Destination: "s***@s***.it"}}
  }

  resetPassword(username: string, code: string, new_password: string) {
    // Collect confirmation code and new password, then
    return from(Auth.forgotPasswordSubmit(username, code, new_password));
    //{code: "ExpiredCodeException", name: "ExpiredCodeException", message: "Invalid code provided, please request a code again."}
  }

  private async internalLogout() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out', error);
    }
  }

  private async internalLogin(
    username: string,
    password: string,
    rememberMe: boolean,
    newPassword?: string
  ) {
    try {
      CognitoAuthService.configureAmplify(
        this.config,
        rememberMe ? localStorage : sessionStorage
      );

      let user = await Auth.signIn(username, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        if (newPassword) {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          user = await Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
            // OPTIONAL, the required attributes
            // {
            //   email: 'xxxx@example.com',
            //   phone_number: '1234567890'
            // }
          );
        } else {
          throw AuthErrors.UserMustResetPassword;
        }
      }
      return user as CognitoUser;
    } catch (error) {
      console.log('error signing in', error);
      throw error;
    }
  }

  private async internalLoggedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user as CognitoUser;
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  private async internalGetSession() {
    CognitoAuthService.configureAmplify(this.config, sessionStorage);
    try {
      return await Auth.currentSession();
    } catch {
      CognitoAuthService.configureAmplify(this.config, localStorage);
    }

    try {
      return await Auth.currentSession();
    } catch {
      return null;
    }
  }

  private async internalGetToken() {
    const session = await this.internalGetSession();

    return session?.getIdToken().getJwtToken();
  }

  private async internalChangePassword(
    oldPassword: string,
    newPassword: string
  ) {
    const user = await this.internalLoggedUser();

    return Auth.changePassword(user, oldPassword, newPassword);
  }
}
