import isEqual from 'lodash-es/isEqual';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
} from 'rxjs';

import { AgFrameworkComponent } from '@ag-grid-community/angular';
import {
  IFloatingFilter,
  IFloatingFilterParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FilterOperator, FilterType } from '@heitown/common-interfaces';
import {
  buildAgGridDateFilter,
  buildAgGridFilter,
  SelectItem,
} from '@heitown/frontend-common';

import { SingleFilterComponent } from '../filters/single-filter/single-filter.component';

export interface CustomGridFilterParams extends IFloatingFilterParams {
  values: Array<SelectItem>;
  filterType: FilterType;
  field: string;
  entityName?: string;
}

@Component({
  template: `
    <heitown-single-filter
      [type]="params.filterType"
      [showLabel]="false"
      [items]="params.values"
      [field]="params.field"
      [entityName]="params.entityName"
      [(ngModel)]="currentValue"
      (ngModelChange)="valueChanged()"
    ></heitown-single-filter>
  `,
  styles: [
    `
      :host {
        margin-top: 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomGridFilterComponent
  implements
    IFloatingFilter,
    AgFrameworkComponent<CustomGridFilterParams>,
    OnDestroy
{
  public params!: CustomGridFilterParams;
  public currentValue!: {
    operator: FilterOperator;
    value: any[];
  } | null;
  public subscription!: Subscription;

  valueChangedSubject = new Subject<any>();

  filterTypes = FilterType;

  @ViewChild(SingleFilterComponent)
  comp?: SingleFilterComponent;

  constructor(private cd: ChangeDetectorRef) {}

  agInit(params: CustomGridFilterParams): void {
    this.params = params;

    this.currentValue = null;

    this.subscription = this.valueChangedSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((currentValue) => {
        if (this.currentValue) {
          if (
            this.params.filterType === FilterType.date &&
            this.currentValue.operator === FilterOperator.inRange &&
            this.currentValue.value.some((v) => !v)
          ) {
            return;
          }
          const filterModel =
            this.params.filterType === FilterType.date
              ? buildAgGridDateFilter({
                  ...this.currentValue,
                  type: FilterType.date,
                })
              : buildAgGridFilter({
                  ...this.currentValue,
                  type: this.params.filterType,
                });

          const actualModel = this.params.api
            .getFilterInstance(this.params.column)
            ?.getModel();
          if (!isEqual(filterModel, actualModel)) {
            const tPromiseSet = this.params.api
              .getFilterInstance(this.params.column)
              ?.setModel(filterModel);
            tPromiseSet?.then(() => {
              const tmodel = this.params.api
                .getFilterInstance(this.params.column)
                ?.getModel();
              if (tmodel) {
                this.params.api.onFilterChanged();
              }
            });
          }
        } else {
          this.params.api.getFilterInstance(this.params.column)?.setModel({});
          this.params.api.onFilterChanged();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  valueChanged() {
    this.valueChangedSubject.next(this.currentValue);
  }

  onParentModelChanged(parentModel: any): void {
    console.log('parent changed');
    if (!parentModel) {
      this.currentValue = null;
    } else {
      const op = parentModel.type as string;
      const newValue = {
        operator: (<any>FilterOperator)[op],
        value: [],
      } as { value: any[]; operator: FilterOperator };

      if (parentModel.filter) {
        newValue.value.push(parentModel.filter);
      }
      if (parentModel.filterTo) {
        newValue.value.push(parentModel.filterTo);
      }
      if (parentModel.dateFrom) {
        newValue.value.push(parentModel.dateFrom);
      }
      if (parentModel.dateTo) {
        newValue.value.push(parentModel.dateTo);
      }
      this.currentValue = newValue;
    }

    this.cd.detectChanges();
  }
}
