import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NgbDateParserFormatter, NgbDatepickerModule, NgbModalModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatepickerParserFormatter } from './datepicker/datepicker-parser-formatter';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { FileReaderComponent } from './file-reader/file-reader.component';
import { NumericInputComponent } from './numeric-input/numeric-input.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { SwitchComponent } from './switch/switch.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TimepickerComponent } from './timepicker/timepicker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    NgbModalModule,
  ],
  declarations: [
    CheckboxComponent,
    SelectComponent,
    DatepickerComponent,
    RadioComponent,
    NumericInputComponent,
    SwitchComponent,
    TextInputComponent,
    FileReaderComponent,
    TimepickerComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    CheckboxComponent,
    SelectComponent,
    DatepickerComponent,
    RadioComponent,
    NumericInputComponent,
    SwitchComponent,
    TextInputComponent,
    FileReaderComponent,
    TimepickerComponent,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter },
  ],
})
export class UiKitCoreModule {}
