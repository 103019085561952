/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-attachment-field',
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-file-reader
        [accept]="accept"
        [multiple]="multiple"
        [formControl]="formControl"
        [maximumSizeMB]="10"
        [readonly]="readOnly"
      >
      </heitown-file-reader>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttachmentFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentFieldComponent extends BaseFormFieldComponent {
  @Input()
  multiple = false;

  @Input()
  accept = '*';
}
