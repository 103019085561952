import { FormGroupState } from 'ngrx-forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, Observable, of, switchMap } from 'rxjs';

import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { BaseDtoModel } from '@heitown/common-dto';
import {
    FacadeInterface, FormValueInterface, PERMISSION_CREATE, PERMISSION_READ
} from '@heitown/frontend-common';
import { RouterService } from '@heitown/frontend-routing-utils';

import { BaseContainer } from './base-container';

@Component({
  template: '',
})
export abstract class BaseFormContainer<
  FormT extends FormValueInterface,
  T extends BaseDtoModel
> extends BaseContainer {
  entity$ = this.facade.selectedEntity$;
  formState$ = this.facade.selectForm$;
  isNew$ = this.facade.selectEntityId$.pipe(map((id) => id === '0'));
  forceCanDeactivate = false;
  createPermissions: string[] = [];
  updatePermissions: string[] = [];
  deletePermissions: string[] = [];
  savePermissions$ = this.isNew$.pipe(
    map((isNew) => {
      return isNew ? this.createPermissions : this.updatePermissions;
    })
  );

  canDelete$ = this.isNew$.pipe(
    switchMap((isNew) =>
      isNew
        ? of(false)
        : this.permissionsService.hasPermission(this.deletePermissions)
    ),
    map((hasPermissions) => {
      if (this.routerService.isAddMode() || this.routerService.isViewOnlyMode())
        return false;

      return hasPermissions;
    })
  );

  canSave$ = this.isNew$.pipe(
    switchMap((isNew) =>
      isNew
        ? this.permissionsService.hasPermission(this.createPermissions)
        : this.permissionsService.hasPermission(this.updatePermissions)
    ),
    map((hasPermissions) => {
      if (this.routerService.isViewOnlyMode()) return false;

      return hasPermissions;
    })
  );

  canNavigate$ = of(
    !this.routerService.isViewOnlyMode() && !this.routerService.isAddMode()
  );

  constructor(
    protected routerService: RouterService,
    @Inject('FormFacade') protected facade: FacadeInterface<T>,
    protected permissionsService: NgxPermissionsService,
    private location: Location
  ) {
    super();
  }

  navigateForward(commands: any[]) {
    this.forceCanDeactivate = true;
    this.routerService.router.navigate(commands);
  }

  canDeactivate(): Observable<boolean> {
    return of(true);
  }

  canAddEntity(entityName: string) {
    return !!(
      this.permissionsService.getPermission(
        `${PERMISSION_CREATE}_${entityName}`
      ) || this.permissionsService.getPermission(`${PERMISSION_CREATE}_all`)
    );
  }

  canViewEntity(entityName: string) {
    return !!(
      this.permissionsService.getPermission(
        `${PERMISSION_READ}_${entityName}`
      ) || this.permissionsService.getPermission(`${PERMISSION_READ}_all`)
    );
  }

  save(form: FormGroupState<FormT>) {
    console.log('isSubmitted: ' + form.isSubmitted);
    if (form.isValid) {
      this.facade.formSave(form);
    }
  }
  delete() {
    if (confirm('Confermi di voler eliminare questo elemento?')) {
      this.facade.formDelete();
    }
  }

  goBack() {
    this.location.back();
  }
}
