import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NgrxErrorFacade } from './+state/ngrx-error.facade';

export const SKIP_INTERCEPT_HEADER = 'skip-intercept-on-error';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private facade: NgrxErrorFacade) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.get(SKIP_INTERCEPT_HEADER) != null) {
      const newHeaders = request.headers.delete(SKIP_INTERCEPT_HEADER);
      const newRequest = request.clone({ headers: newHeaders });
      return next.handle(newRequest);
    }

    return next.handle(request).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          this.facade.addError(error.error.error);
        } else if (typeof error.error === 'string') {
          // client-side error
          errorMessage = `${error.error}`;
          this.facade.addApiErrorMessage(errorMessage, error.status);
        } else if (error instanceof ArrayBuffer) {
          return throwError(error);
        } else {
          // server-side error
          if (error.error) {
            error = error.error;
            errorMessage = error.message;
          } else if (error.status === 0) {
            errorMessage = `Network error, check your internet connection.`;
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          this.facade.addApiErrorMessage(errorMessage, error.status);
        }
        return throwError(errorMessage);
      })
    );
  }
}
