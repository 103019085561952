// stuttura filtri passata da ag-grid
// creare enum per type e filterType
export interface AgGridFilter {
  filterType: string; // tipo del campo (string, data, number, ...)?
  type: string; // operatore
  filter?: any; // valore
  filterTo?: any; // valore 2
  dateFrom?: string;
  dateTo?: string | null;
}

export interface AgGridFilterModel {
  [key: string]: AgGridFilter;
}
