import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotelSettingDtoModel } from '@heitown/common-dto';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class HotelSettingApiClient extends BaseCRUDApiClient<HotelSettingDtoModel> {
  baseUrl = this.config.apiEndpoint + '/hotel-setting';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }
}
