import { createReducer, on } from '@ngrx/store';
import * as ErrorActions from './ngrx-error.actions';

export const ERROR_FEATURE_KEY = 'error';

export interface NgrxErrorPartialState {
  readonly [ERROR_FEATURE_KEY]: ErrorState;
}

export interface ErrorState {
  errors: Error[];
  lastApiErrorMessage: string;
}

const initialState: ErrorState = {
  errors: [],
  lastApiErrorMessage: '',
};

export const reducer = createReducer(
  initialState,
  on(ErrorActions.addError, (state, { error }) => {
    return {
      ...state,
      errors: [...state.errors, error],
    };
  }),
  on(ErrorActions.addApiErrorMsg, (state, { errorMessage }) => {
    return {
      ...state,
      lastApiErrorMessage: errorMessage,
    };
  })
);
