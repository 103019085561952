import {
    box, Boxed, createFormGroupState, FormGroupState, onNgrxForms, updateGroup, validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { email, minLength, pattern, required } from 'ngrx-forms/validation';

import { fieldEqualTo } from '@heitown/frontend-forms';
import { createReducer, on } from '@ngrx/store';

import * as AuthActions from '../+state/auth.actions';
import { FileReaderValue } from '../containers/profile/profile.container';
import { LoggedUser } from './auth.models';

export const AUTH_FEATURE_KEY = 'auth';
export const LOGIN_FORM_ID = 'loginForm';
export const PROFILE_SETTINGS_FORM_ID = 'profileSettingsForm';
export const PROFILE_PHOTO_FORM_ID = 'profilePhotoForm';
export const PROFILE_CHANGE_PASSWORD_FORM_ID = 'profileChangePasswordForm';
export const FORGOT_PASSWORD_FORM_ID = 'forgotPasswordForm';
export const RESET_PASSWORD_FORM_ID = 'resetPasswordForm';
export interface LoginFormValue {
  username: string;
  password: string;
  rememberMe: boolean;
  newPassword: string;
  confirmPassword: string;
}

export interface ForgotPasswordFormValue {
  username: string;
}
export interface ResetPasswordFormValue {
  newPassword: string;
  confirmPassword: string;
}

export interface ProfileSettingsFormValue {
  email: string;
  roleId: string;
  firstName: string;
  lastName: string;
  // gender: string;
}
export interface ProfilePhotoFormValue {
  photo: Boxed<FileReaderValue | null>;
}
export interface ProfileChangePasswordFormValue {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export interface AuthState {
  loginForm: FormGroupState<LoginFormValue>;
  loggedUser?: LoggedUser;
  userMustResetPassword: boolean;
  source?: string;
  selectedProfileTab: number;
  profileSettingsForm: FormGroupState<ProfileSettingsFormValue>;
  profilePhotoForm: FormGroupState<ProfilePhotoFormValue>;
  profileChangePasswordForm: FormGroupState<ProfileChangePasswordFormValue>;
  forgotPasswordForm: FormGroupState<ForgotPasswordFormValue>;
  resetPasswordForm: FormGroupState<ResetPasswordFormValue>;
  authError: string;
  resetPasswordEmailSent: boolean;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const loginFormInitialState: LoginFormValue = {
  username: '',
  password: '',
  rememberMe: true,
  newPassword: '',
  confirmPassword: '',
};

export const profileSettingsFormInitialState: ProfileSettingsFormValue = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  // gender: '',
};
export const profilePhotoFormInitialState: ProfilePhotoFormValue = {
  photo: box(null),
};

export const profileChangePasswordFormInitialState: ProfileChangePasswordFormValue =
  {
    password: '',
    newPassword: '',
    confirmPassword: '',
  };

export const forgotPasswordFormInitialState: ForgotPasswordFormValue = {
  username: '',
};

export const resetPasswordFormInitialState: ResetPasswordFormValue = {
  newPassword: '',
  confirmPassword: '',
};

const initialState: AuthState = {
  // set initial required properties
  loginForm: createFormGroupState<LoginFormValue>(
    LOGIN_FORM_ID,
    loginFormInitialState
  ),
  loggedUser: undefined,

  userMustResetPassword: false,
  source: 'admin',
  selectedProfileTab: 0,
  profileSettingsForm: createFormGroupState<ProfileSettingsFormValue>(
    PROFILE_SETTINGS_FORM_ID,
    profileSettingsFormInitialState
  ),
  profilePhotoForm: createFormGroupState<ProfilePhotoFormValue>(
    PROFILE_PHOTO_FORM_ID,
    profilePhotoFormInitialState
  ),
  profileChangePasswordForm:
    createFormGroupState<ProfileChangePasswordFormValue>(
      PROFILE_CHANGE_PASSWORD_FORM_ID,
      profileChangePasswordFormInitialState
    ),
  forgotPasswordForm: createFormGroupState<ForgotPasswordFormValue>(
    FORGOT_PASSWORD_FORM_ID,
    forgotPasswordFormInitialState
  ),
  resetPasswordForm: createFormGroupState<ResetPasswordFormValue>(
    FORGOT_PASSWORD_FORM_ID,
    resetPasswordFormInitialState
  ),
  authError: '',
  resetPasswordEmailSent: false,
};

const validateLoginForm = (
  formState: FormGroupState<LoginFormValue>,
  state: AuthState
) => {
  return updateGroup(formState, {
    username: (controlState, parentState) => {
      controlState = validate(controlState, required, email);
      return controlState;
    },
    password: (controlState, parentState) => {
      controlState = validate(controlState, required);
      return controlState;
    },
    newPassword: (controlState, parentState) => {
      if (state.userMustResetPassword) {
        controlState = validate(controlState, required, minLength(8));

        if (controlState.isValid) {
          controlState = validate(
            controlState,
            pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
            )
          );
        }
      }
      return controlState;
    },
    confirmPassword: (controlState, parentState) => {
      if (state.userMustResetPassword) {
        controlState = validate(
          controlState,
          fieldEqualTo(
            parentState.value.newPassword,
            'Confirm password',
            'New Password'
          )
        );
      }
      return controlState;
    },
  });
};

const validateProfileSettingsForm = updateGroup<ProfileSettingsFormValue>({
  firstName: validate(required),
  lastName: validate(required),
});

const validateProfileChangePasswordForm = (
  formState: FormGroupState<ProfileChangePasswordFormValue>
) => {
  return updateGroup(formState, {
    password: (controlState, parentState) => {
      controlState = validate(controlState, required);
      return controlState;
    },
    newPassword: (controlState, parentState) => {
      controlState = validate(controlState, required, minLength(8));

      if (controlState.isValid) {
        controlState = validate(
          controlState,
          pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
        );
      }
      return controlState;
    },
    confirmPassword: (controlState, parentState) => {
      controlState = validate(
        controlState,
        fieldEqualTo(
          parentState.value.newPassword,
          'Confirm password',
          'New Password'
        )
      );
      return controlState;
    },
  });
};

const validateForgotPasswordForm = updateGroup<ForgotPasswordFormValue>({
  username: validate(required, email),
});

const validateResetPasswordForm = (
  formState: FormGroupState<ResetPasswordFormValue>
) => {
  return updateGroup(formState, {
    newPassword: (controlState, parentState) => {
      controlState = validate(controlState, required, minLength(8));

      if (controlState.isValid) {
        controlState = validate(
          controlState,
          pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
        );
      }
      return controlState;
    },
    confirmPassword: (controlState, parentState) => {
      controlState = validate(
        controlState,
        fieldEqualTo(
          parentState.value.newPassword,
          'Confirm password',
          'New Password'
        )
      );
      return controlState;
    },
  });
};

const rawReducer = createReducer(
  initialState,
  onNgrxForms(),
  on(AuthActions.loadLoggedUserSuccess, (state, { user }) => {
    return {
      ...state,
      loggedUser: user,
    };
  }),

  on(AuthActions.logout, (state) => {
    return {
      ...state,
      loggedUser: undefined,
      currentDomainId: undefined,
      domains: [],
    };
  }),
  on(AuthActions.setUserMustResetPassword, (state, { source }) => {
    return { ...state, userMustResetPassword: true, source: source };
  }),
  on(AuthActions.loginSuccess, (state) => {
    return { ...state, userMustResetPassword: false, authError: '' };
  }),
  on(AuthActions.selectProfileTab, (state, { index }) => {
    return { ...state, selectedProfileTab: index };
  }),
  on(AuthActions.loginFailure, (state, { error }) => {
    return { ...state, authError: error };
  }),
  on(AuthActions.forgotPasswordSuccess, (state) => {
    return { ...state, resetPasswordEmailSent: true };
  }),
  on(AuthActions.resetPasswordSuccess, (state) => {
    return { ...state, authError: '' };
  }),
  on(AuthActions.resetPasswordFailure, (state, { error }) => {
    return { ...state, authError: error };
  }),
  on(AuthActions.resetAuthError, (state) => {
    return { ...state, authError: '' };
  })
);

export const reducer = wrapReducerWithFormStateUpdate(
  wrapReducerWithFormStateUpdate(
    wrapReducerWithFormStateUpdate(
      wrapReducerWithFormStateUpdate(
        wrapReducerWithFormStateUpdate(
          rawReducer,
          // point to the form state to update
          (s) => s.resetPasswordForm,
          // this function is always called after the reducer
          validateResetPasswordForm
        ),
        // point to the form state to update
        (s) => s.forgotPasswordForm,
        // this function is always called after the reducer
        validateForgotPasswordForm
      ),
      // point to the form state to update
      (s) => s.profileChangePasswordForm,
      // this function is always called after the reducer
      validateProfileChangePasswordForm
    ),
    // point to the form state to update
    (s) => s.profileSettingsForm,
    // this function is always called after the reducer
    validateProfileSettingsForm
  ),
  // point to the form state to update
  (s) => s.loginForm,
  // this function is always called after the reducer
  validateLoginForm
);
