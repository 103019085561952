export const environment = {
  production: false,
  cognitoRegion: 'eu-west-1',
  cognitoUserPoolId: 'eu-west-1_PKizrzXpW',
  cognitoClientId: '464k6bso0935ej5ic0jllfk33u',
  cognitoIdentityPoolId: '',
  cognitoOAuthDomain: 'cognito.test.heitown.com',
  apiBaseUrl: 'https://api.test.heitown.com/api',
  environment: 'test',
  stripePublishableKey: 'pk_test_sGMempehRuf4t9fSH0FPeVbb',
  tsIntegration: true,
};
