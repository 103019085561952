/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-time-field',
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-timepicker
        [hasError]="shouldShowError"
        [formControl]="formControl"
        (blur)="onBlur()"
      >
      </heitown-timepicker>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFieldComponent extends BaseFormFieldComponent {
  _value: any = undefined;
  @Input()
  public set value(v: any) {
    this._value = v;
    this.formControl.setValue(v, { emitEvent: false });
  }
  @Output()
  changed = new EventEmitter<any>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  onBlur() {
    this.blur.emit();
  }
  override onChanged(v: any) {
    super.onChanged(v);
    this.changed.emit(v);
  }
}
