import { Observable } from 'rxjs';

import { FilterValue } from '@heitown/common-interfaces';

export interface SharedFacadeInterface<T> {
  allEntities$: Observable<T[]>;
  loadedAll$: Observable<boolean>;
  loadPaged: (
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' },
    filters?: FilterValue[],
    join?: string[]
  ) => void;
  loadPagedSuccess: (items: T[]) => void;
  loadPagedFailure: (error: string) => void;
  loadSingle: (id: string) => void;
  delete: (
    id: string,
    notify: boolean,
    goBack: boolean,
    callback?: () => void
  ) => void;
  loadAll: () => void;
}
