import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@heitown/frontend-auth';

import { LayoutComponent } from './containers/layout/layout.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: 'unauthorized',
      //   pathMatch: 'full',
      //   redirectTo: '',
      //   children: [],
      // },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },

      {
        path: 'home',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-home').then(
            (m) => m.FrontendLazyHomeModule
          ),
      },
      {
        path: 'user',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-user').then(
            (m) => m.FrontendLazyUserModule
          ),
      },
      {
        path: 'customer',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-customer').then(
            (m) => m.FrontendLazyCustomerModule
          ),
      },
      {
        path: 'hotel',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-hotel').then(
            (m) => m.FrontendLazyHotelModule
          ),
      },
      {
        path: 'booking',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-booking').then(
            (m) => m.FrontendLazyBookingModule
          ),
      },
      {
        path: 'reservation',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-reservation').then(
            (m) => m.FrontendLazyReservationModule
          ),
      },
      {
        path: 'reservation-person',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-reservation-person').then(
            (m) => m.FrontendLazyReservationPersonModule
          ),
      },
      {
        path: 'room-type',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-room-type').then(
            (m) => m.FrontendLazyRoomTypeModule
          ),
      },
      {
        path: 'room-price',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-room-price').then(
            (m) => m.FrontendLazyRoomPriceModule
          ),
      },
      {
        path: 'room',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-room').then(
            (m) => m.FrontendLazyRoomModule
          ),
      },

      {
        path: 'maintenance-request',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-maintenance-request').then(
            (m) => m.FrontendLazyMaintenanceRequestModule
          ),
      },

      {
        path: 'ps-istat-card',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-ps-istat-card').then(
            (m) => m.FrontendLazyPsIstatCardModule
          ),
      },
      {
        path: 'service-request',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-service-request').then(
            (m) => m.FrontendLazyServiceRequestModule
          ),
      },
      {
        path: 'debug',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-debug').then(
            (m) => m.FrontendLazyDebugModule
          ),
      },
      {
        path: 'service-type',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-service-type').then(
            (m) => m.FrontendLazyServiceTypeModule
          ),
      },
      {
        path: 'room-cleaning',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-room-cleaning').then(
            (m) => m.FrontendLazyRoomCleaningModule
          ),
      },
      {
        path: 'room-opening',
        // pathMatch: 'full',
        loadChildren: () =>
          import('@heitown/frontend-lazy-room-opening').then(
            (m) => m.FrontendLazyRoomOpeningModule
          ),
      },
      // {
      //   path: 'statusPage',
      //   // pathMatch: 'full',
      //   loadChildren: () =>
      //     import('@ellf/app-lazy-status-page').then(
      //       (m) => m.AppLazyStatusPageModule
      //     ),
      //   canActivate: [LoggedUserGuard],
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
