export * from './lib/entities/base.api-client';
export * from './lib/entities/baseCRUD.api-client';
export * from './lib/api-client-config';
export * from './lib/api-client.module';

export * from './lib/status-page.api-client';
export * from './lib/file.api-client';

//services
export * from './lib/entities/user.api-client';
export * from './lib/entities/customer.api-client';
export * from './lib/entities/municipality.api-client';
export * from './lib/entities/country.api-client';
export * from './lib/entities/reservation.api-client';
export * from './lib/entities/user-type.api-client';
export * from './lib/entities/hotel.api-client';
export * from './lib/entities/room-type.api-client';
export * from './lib/entities/allotment.api-client';
export * from './lib/entities/person.api-client';
export * from './lib/entities/reservation-person.api-client';
export * from './lib/entities/maintenance-request.api-client';
export * from './lib/entities/service-request.api-client';
export * from './lib/entities/room.api-client';
export * from './lib/entities/room-price.api-client';
export * from './lib/entities/hotel-setting.api-client';
export * from './lib/entities/ps-istat-card.api-client';
export * from './lib/entities/service-type.api-client';
export * from './lib/entities/audit.api-client';
export * from './lib/entities/payment.api-client';
