/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

let nextId = 0;

@Component({
  selector: 'heitown-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  public value = false;
  public isDisabled = false;

  id = `sw-${nextId++}`;

  @Input()
  label!: string;

  propagateChange = (value: boolean) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: boolean) => void) {
    this.propagateChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    return res;
  }

  ngOnInit(): void {
    if (this.label == undefined) {
      throw console.error('label undefined');
    }
  }

  onModelChange() {
    this.propagateChange(this.value);
    this.onTouched();
  }
}
