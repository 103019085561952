import * as moment from 'moment';
// import { ValidationErrors } from 'ngrx-forms';
import { equalTo, EqualToValidationError } from 'ngrx-forms/validation';

export interface DateLessThanError {
  comparand: string;
  actual: string;
  allowEqual: boolean;
}
export interface DateGreaterThanError {
  comparand: string;
  actual: string;
  allowEqual: boolean;
}

export interface EqualToError<T> extends EqualToValidationError<T> {
  field: string;
  compareField: string;
}
// declare module 'ngrx-forms/src/state' {
export interface ValidationErrors {
  dateLessThan?: DateLessThanError;
  dateGreaterThan?: DateGreaterThanError;
  fieldEqualTo?: EqualToError<string>;
  json?: boolean;
  jsonSchema?: boolean;
}
// }

export function json(value: string) {
  const err = {
    json: true,
  };

  let valid = true;
  if (value) {
    try {
      JSON.parse(value);
    } catch {
      valid = false;
    }
  }

  if (!valid) {
    return err;
  }

  return {};
}

// export function jsonWithSchema(value: string, schema: Record<string, unknown>) {
//   const err = {
//     jsonSchema: true,
//   };

//   let valid = true;
//   if (value) {
//     try {
//       const data = JSON.parse(value);
//       if (schema) {
//         const validator = new ajv({ allErrors: true, verbose: true }); // options can be passed, e.g. {allErrors: true}
//         const validate = validator.compile(schema);
//         valid = validate(data) as boolean;
//       }
//     } catch {
//       valid = false;
//     }
//   }

//   if (!valid) {
//     return err;
//   }

//   return {};
// }

export function dateLessThan(
  comparand: string,
  allowEqual = false,
  format = 'L'
): (value: string) => ValidationErrors {
  return (value: string): ValidationErrors => {
    const err = {
      dateLessThan: {
        comparand: moment(comparand).format(format),
        actual: moment(value).format(format),
        allowEqual,
      },
    };

    let valid = true;
    if (value && comparand) {
      const diff = moment(value)
        .startOf('d')
        .diff(moment(comparand).startOf('d'));
      if (diff < 0) {
        valid = true;
      } else if (diff === 0) {
        valid = allowEqual;
      } else {
        valid = false;
      }
    }
    if (!valid) {
      return err;
    }
    return {};
  };
}

export function dateGreaterThan(
  comparand: string,
  allowEqual = false,
  format = 'L'
): (value: string) => ValidationErrors {
  return (value: string): ValidationErrors => {
    const err = {
      dateGreaterThan: {
        comparand: moment(comparand).format(format),
        actual: moment(value).format(format),
        allowEqual,
      },
    };

    let valid = true;
    if (value && comparand) {
      const diff = moment(value)
        .startOf('d')
        .diff(moment(comparand).startOf('d'));
      if (diff > 0) {
        valid = true;
      } else if (diff === 0) {
        valid = allowEqual;
      } else {
        valid = false;
      }
    }
    if (!valid) {
      return err;
    }
    return {};
  };
}

export function fieldEqualTo(
  comparand: string,
  field: string,
  compareField: string
): (value: string) => ValidationErrors {
  return (value: string): ValidationErrors => {
    const equalToErr = equalTo(comparand)(value);
    if (equalToErr && equalToErr.equalTo) {
      return {
        fieldEqualTo: { ...equalToErr.equalTo, field, compareField },
      };
    }
    return {};
  };
}
