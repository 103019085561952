import { getRouterInfo } from '@heitown/frontend-routing-utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectLoginForm = createSelector(getAuthState, (s) => s.loginForm);
export const selectLoggedUser = createSelector(
  getAuthState,
  (s) => s.loggedUser
);

export const selectUserMustResetPassword = createSelector(
  getAuthState,
  (s) => s.userMustResetPassword
);

export const selectSource = createSelector(getAuthState, (s) => s.source);

export const selectAuthError = createSelector(getAuthState, (s) => s.authError);

export const resetPasswordEmailSent = createSelector(
  getAuthState,
  (s) => s.resetPasswordEmailSent
);

// export const selectQueryDomain = createSelector(
//   getRouterInfo,
//   (r) => r.queryParams.domainId
// );

export const selectProfileSettingsForm = createSelector(
  getAuthState,
  (s) => s.profileSettingsForm
);
export const selectProfilePhotoForm = createSelector(
  getAuthState,
  (s) => s.profilePhotoForm
);

export const selectProfileChangePasswordForm = createSelector(
  getAuthState,
  (s) => s.profileChangePasswordForm
);
export const getSelectedProfileTab = createSelector(
  getAuthState,
  (s) => s.selectedProfileTab
);
export const selectForgotPasswordForm = createSelector(
  getAuthState,
  (s) => s.forgotPasswordForm
);
export const selectResetPasswordForm = createSelector(
  getAuthState,
  (s) => s.resetPasswordForm
);
export const selectCanSaveProfileSettings = createSelector(
  getAuthState,
  (s) =>
    s.profileSettingsForm?.isValid &&
    !(
      s.profileSettingsForm.isPristine ||
      (s.profileSettingsForm?.isSubmitted && s.profileSettingsForm?.isValid)
    )
);
export const selectCanSaveProfileChangePassword = createSelector(
  getAuthState,
  (s) =>
    s.profileChangePasswordForm?.isValid &&
    !(
      s.profileChangePasswordForm.isPristine ||
      (s.profileChangePasswordForm?.isSubmitted &&
        s.profileChangePasswordForm?.isValid)
    )
);
export const selectCanSaveProfile = createSelector(
  getSelectedProfileTab,
  selectCanSaveProfileSettings,
  selectCanSaveProfileChangePassword,
  (tabIndex, canSaveProfileSettings, canSaveChangePassword) => {
    if (tabIndex === 0) {
      return canSaveProfileSettings;
    } else if (tabIndex === 1) {
      return canSaveChangePassword;
    } else {
      return false;
    }
  }
);

export const selectQueryParams = createSelector(
  getRouterInfo,
  (r) => r.queryParams
);
