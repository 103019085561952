import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';

import * as LoaderActions from './ngrx-loader.actions';
import { NgrxLoaderPartialState } from './ngrx-loader.reducer';
import { isLoaderVisible } from './ngrx-loader.selectors';

@Injectable({
  providedIn: 'root',
})
export class NgrxLoaderFacade {
  isLoaderVisible$ = this.store.pipe(select(isLoaderVisible));

  constructor(private store: Store<NgrxLoaderPartialState>) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  showLoader(action: Action) {
    this.store.dispatch(LoaderActions.showLoader({ actionType: action.type }));
  }

  hideLoader(triggerAction: Action) {
    this.store.dispatch(
      LoaderActions.hideLoader({ actionType: triggerAction.type })
    );
  }
}
