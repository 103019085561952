import { createAction, props } from '@ngrx/store';

export const showLoader = createAction(
  '[Ngrx/Loader] Show Loader',
  props<{ actionType: string }>()
);
export const hideLoader = createAction(
  '[Ngrx/Loader] Hide Loader',
  props<{ actionType: string }>()
);
export const forceHideLoader = createAction('[Ngrx/Loader] Force Hide Loader');
