import {
  Pipe,
  PipeTransform,
} from '@angular/core';

import {
  ValidationMessageProvider,
} from '../directives/validation-message-provider';

// import { error } from 'console';
// import { ValidationMessageProvider } from '../directives/validation-message-provider';

@Pipe({ name: 'ngrxFormsErr' })
export class NgrxFormsErrorPipe implements PipeTransform {
  constructor(private validationMessageProvider: ValidationMessageProvider) {}

  transform(value: {
    [errKey: string]: { [errInfo: string]: string };
  }): string[] {
    const errors: string[] = [];
    Object.keys(value).forEach((errKey) => {
      const err = value[errKey];
      errors.push(this.validationMessageProvider.getMessage(errKey, err));
      // errors.push('Errore: ' + errKey);
    });
    return errors;
  }
}
