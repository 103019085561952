import Inputmask from 'inputmask';
import * as momentNs from 'moment';

/* eslint-disable @typescript-eslint/no-empty-function */
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef,
    Input, OnDestroy, ViewChild
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';

const moment = momentNs;

@Component({
  selector: 'heitown-timepicker',
  template: `<input
    #input
    type="text"
    class="form-control"
    [class.is-invalid]="hasError"
    name="tp"
    [(ngModel)]="innerValue"
    (ngModelChange)="onModelChange()"
    [disabled]="isDisabled"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
})
export class TimepickerComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input()
  hasError = false;

  @Input()
  nullable = true;

  @ViewChild('input', { static: true })
  input?: ElementRef;

  innerValue: string | null = null;

  public isDisabled = false;

  constructor(private cd: ChangeDetectorRef) {}

  propagateChange = (value: string | null) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  writeValue(value: string | null) {
    if (value) {
      this.innerValue = value;
      // const mommentValue = moment.utc(value);
      // this.innerValue = mommentValue.format('HH:mm');
    } else {
      this.innerValue = null;
    }

    this.cd.detectChanges();
  }

  registerOnChange(fn: (value: string | null) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  //   get value() {
  //     return this.innerValue;
  //   }

  //   set value(val: string | null) {
  //     this.innerValue = val;
  //     this.propagateChange(val);
  //     this.cd.markForCheck();
  //   }

  ngAfterViewInit(): void {
    const mask = new Inputmask('datetime', {
      alias: 'datetime',
      inputFormat: 'HH:MM',
      clearMaskOnLostFocus: false,
      nullable: this.nullable,
    });

    mask.mask(this.input?.nativeElement);
  }

  ngOnDestroy() {
    this.cd.detach();
  }

  onModelChange() {
    console.log('timepicker model changes', this.innerValue);

    const parsedTime = moment.utc(this.innerValue, 'HH:mm');

    if (parsedTime) {
      //   const hours = this.innerValue.split(':')[0];
      //   const minutes = this.innerValue.split(':')[1];

      //   const newDate = moment.utc().set('y', 1970).startOf('y');
      //   newDate.set('hours', Number(hours));
      //   newDate.set('minutes', Number(minutes));

      this.propagateChange(this.innerValue);
    } else {
      this.propagateChange(null);
    }

    this.onTouched();
  }
}
