export enum ErrorCode {
  DUPLICATE_FISCAL_CODE = 'DUPLICATE_FISCAL_CODE',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  DUPLICATE_PHONE_NUMBER = 'DUPLICATE_PHONE_NUMBER',
  USER_ALREADY_SIGNEDUP = 'USER_ALREADY_SIGNEDUP',
  NO_ROOMS_AVAILABLE = 'NO_ROOMS_AVAILABLE',
  UNASSIGNED_ROOMS = 'UNASSIGNED_ROOMS',
  KEY_ALREADY_EXISTS = 'KEY_ALREADY_EXISTS',
  GUEST_DATA_MISMATCH = 'GUEST_DATA_MISMATCH',
}
