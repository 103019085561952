import { NgxPermissionsService } from 'ngx-permissions';

import { Location } from '@angular/common';
import { Inject } from '@angular/core';
import { BaseDtoModel } from '@heitown/common-dto';
import { FormValueInterface, LazyFacadeInterface } from '@heitown/frontend-common';
import { RouterService } from '@heitown/frontend-routing-utils';

import { BaseFormContainer } from './base-form-container';

export abstract class LazyBaseFormContainer<
  FormT extends FormValueInterface,
  T extends BaseDtoModel
> extends BaseFormContainer<FormT, T> {
  constructor(
    protected router: RouterService,
    @Inject('FormFacade') facade: LazyFacadeInterface<T>,
    permissionsService: NgxPermissionsService,
    location: Location
  ) {
    super(router, facade, permissionsService, location);
  }
}
