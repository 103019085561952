export interface Environment {
  production: boolean;
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoClientId: string;
  cognitoIdentityPoolId: string;
  cognitoOAuthDomain: string;
  apiBaseUrl: string;
  environment: string;
  stripePublishableKey: string;
  tsIntegration: boolean;
}
