/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-numeric-field',
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-numeric-input
        [hasError]="shouldShowError"
        [formControl]="formControl"
        (blur)="onBlur()"
        [digits]="digits"
        [min]="min"
        [max]="max"
        [readOnly]="readOnly"
        [prefix]="isCurrency ? '€ ' : ''"
        [digitsOptional]="isCurrency ? false : true"
        [nullable]="isCurrency ? false : nullable"
      >
      </heitown-numeric-input>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericFieldComponent extends BaseFormFieldComponent {
  @Input()
  digits: number | '*' = 2;

  @Input()
  min?: number;

  @Input()
  max?: number;

  @Input()
  isCurrency = false;

  @Input()
  nullable = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  onBlur() {
    this.blur.emit();
  }
}
