import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentDtoModel } from '@heitown/common-dto';

import { ApiClientConfig } from './api-client-config';

@Injectable()
export class FileApiClient {
  baseUrl = this.config.apiEndpoint + '/file';

  upload(list: FileList) {
    const files = Array.from(list);
    const fd = new FormData();
    files.forEach((file) => fd.append('files', file));
    return this.http.post<AttachmentDtoModel[]>(this.baseUrl + '/upload', fd);
  }

  download(attachment: AttachmentDtoModel) {
    return this.http.post(this.baseUrl + '/download', attachment, {
      responseType: 'arraybuffer',
    });
  }

  async getUrl(media: AttachmentDtoModel, sanitize = true) {
    if (!media.publicUrl) {
      const buffer = await firstValueFrom(this.download(media));
      const blob = new Blob([buffer], { type: media.mimeType });
      const url = window.URL.createObjectURL(blob);
      if (!sanitize) return url;
      else return this.sanitizer.bypassSecurityTrustUrl(url) as string;
    }
    return media.publicUrl;
  }

  // download(attachment: AttachmentDtoModel) {
  //   return this.http.get(
  //     `${this.baseUrl}/${attachment.containerName}/${attachment.prefix}/${attachment.fileName}/${attachment.mimeType}`,
  //     {
  //       responseType: 'arraybuffer',
  //     }
  //   );
  // }

  constructor(
    private http: HttpClient,
    private config: ApiClientConfig,
    private sanitizer: DomSanitizer
  ) {}
}
