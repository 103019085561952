<form
  *ngIf="forgotPasswordForm$ | async as form"
  [ngrxFormState]="form"
  (submit)="onSubmit(form)"
>
  <div>
    <h5 class="text-muted fw-normal mb-4">
      Password dimenticata? Inserisci la tua email.
    </h5>

    <div>
      <heitown-text-field
        [label]="'Email'"
        [ngrxFormControlState]="form.controls.username"
        [errors]="form.controls.username.errors | ngrxFormsErr"
        [shouldShowError]="form.controls.username.isInvalid && form.controls.username.isSubmitted"
      >
      </heitown-text-field>
    </div>
  </div>

  <div class="mt-3 text-end" *ngIf="!(resetPasswordEmailSent$ | ngrxPush)">
    <button type="submit" class="btn btn-primary">Inviami le istruzioni</button>
  </div>
</form>

<div
  class="login-info text-success text-end mt-3"
  *ngIf="(resetPasswordEmailSent$ | ngrxPush)"
>
  Controlla la tua email per resettare la tua password
</div>
