/* eslint-disable @typescript-eslint/member-ordering */
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';

import { ColDef, GridOptions } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterValue } from '@heitown/common-interfaces';
import {
  FilterConfig,
  FilterFormValueInterface,
} from '@heitown/frontend-common';

import { DataGridAction } from '../grid/data-grid/data-grid-action';

@Component({
  selector: 'heitown-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityListComponent {
  @Input()
  filterFormState$!: Observable<FormGroupState<FilterFormValueInterface>>;

  @Input()
  filters!: FilterConfig[];

  @Input()
  gridOptions!: GridOptions;

  @Input()
  customActions: DataGridAction[] = [];

  @Input()
  actions: DataGridAction[] = [];

  @Input()
  showDeleteBtn?: (x: any) => boolean;

  @Input()
  items?: any[];

  @Input()
  columnDefs!: ColDef[];

  @Input()
  currentPage!: number;

  @Input()
  pageSize!: number;

  @Input()
  totalRows!: number;

  @Input()
  canEdit? = false;

  @Input()
  canView? = false;

  @Input()
  canDelete? = false;

  @Input()
  canSelect? = false;

  @Input()
  canDownloadExcel = false;

  @Input()
  canDownloadPdf = false;

  @Input()
  fileName = '';

  @Input()
  title = '';

  @Input()
  paged = true;

  @Output()
  view = new EventEmitter<any>();

  @Output()
  edit = new EventEmitter<any>();

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  filterChanged: EventEmitter<FilterValue[]> = new EventEmitter<
    FilterValue[]
  >();

  @Output()
  pageChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  excel: EventEmitter<string> = new EventEmitter();

  @Output()
  pdf: EventEmitter<string> = new EventEmitter();

  pageSizeControl = new FormControl();

  get firstRecord() {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get lastRecord() {
    const max = (this.currentPage - 1) * this.pageSize + this.pageSize;
    if (this.totalRows < max) return this.totalRows;
    return max;
  }

  filter(filters: FilterValue[]) {
    this.filterChanged.emit(filters);
  }
  onPageChange(newPage: number) {
    this.pageChanged.emit(newPage);
  }
  downloadExcel() {
    console.log('Download Excel');
    this.excel.emit(this.fileName);
  }
  downloadPdf() {
    console.log('Download PDF');
    this.pdf.emit(this.fileName);
  }
}
