<div class="d-flex mb-3">
  <div class="title" *ngIf="title">{{ title }}</div>
  <div class="ms-auto"></div>
  <!-- serve un if canDownload? -->
  <button
    type="button"
    class="btn btn-primary"
    *ngIf="canDownloadExcel"
    (click)="downloadExcel()"
  >
    Excel
  </button>
</div>

<heitown-data-grid
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  [items]="items"
  [canView]="canView"
  [canEdit]="canEdit"
  [canDelete]="canDelete"
  [customActions]="customActions"
  [showDeleteBtn]="$any(showDeleteBtn)"
  (view)="view.emit($event)"
  (edit)="edit.emit($event)"
  (delete)="delete.emit($event)"
>
</heitown-data-grid>
<div class="d-flex align-items-center mt-2" *ngIf="paged && totalRows > 0">
  <div>
    Risultati:
    {{ firstRecord + ' - ' + lastRecord }}
    di {{ totalRows }}
  </div>

  <ngb-pagination
    class="d-flex justify-content-end ms-auto"
    [page]="currentPage"
    (pageChange)="onPageChange($event)"
    [pageSize]="pageSize"
    [maxSize]="5"
    [rotate]="true"
    [boundaryLinks]="false"
    [collectionSize]="totalRows"
    size="sm"
  ></ngb-pagination>
  <!-- <div class="text-right ms-3">
    Page Size:
    <select [formControl]="pageSizeControl">
      <option [ngValue]="10">10</option>
      <option [ngValue]="20">20</option>
      <option [ngValue]="50">50</option>
    </select>
  </div> -->
</div>
