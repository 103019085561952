import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';

import { BaseApiClient } from './base.api-client';

@Injectable()
export abstract class BaseCRUDApiClient<T> extends BaseApiClient<T> {
  getById(id: string) {
    return this.http.get<T>(`${this.baseUrl}/${id}`);
  }

  add(u: Partial<T>) {
    return this.http.post<T>(`${this.baseUrl}`, u);
  }

  update(u: Update<T>) {
    return this.http.put<T>(`${this.baseUrl}/${u.id}`, u.changes);
  }

  delete(id: string) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      }),
    });
  }
}
