import { BreadcrumbService } from 'xng-breadcrumb';

import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'heitown-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  showBack = false;
  constructor(
    private location: Location,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.breadcrumbs$.subscribe((bd) => {
      //console.log('breadcrumbs', bd);
      //this.showBack = bd && bd.length > 0;
    });
  }
  back() {
    this.location.back();
  }
}
