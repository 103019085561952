import { AdminSignUpRequestDto, UserDtoModel } from '@heitown/common-dto';
import { createAction, props } from '@ngrx/store';

import { LoggedUser } from './auth.models';

export const login = createAction(
  '[Auth] Login',
  props<{
    username: string;
    password: string;
    rememberMe: boolean;
    newPassword?: string;
    source?: string;
  }>()
);
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{
    source?: boolean;
  }>()
);
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string; userMustResetPassword?: boolean }>()
);
export const setUserMustResetPassword = createAction(
  '[Auth] User must reset password',
  props<{
    source?: string;
  }>()
);

export const sessionExpired = createAction('[Auth] Session expired');

export const loadLoggedUser = createAction('[Auth] Load logged user');
export const loadLoggedUserSuccess = createAction(
  '[Auth] Load logged user Success',
  props<{ user: LoggedUser }>()
);
export const loadLoggedUserFailure = createAction(
  '[Auth] Load logged user Failure',
  props<{ error: string }>()
);

export const logout = createAction('[Auth] Logout');

export const logoutSuccess = createAction('[Auth] Logout success');

export const logoutFailure = createAction('[Auth] Logout failure');

export const googleSignIn = createAction(
  '[Auth] Google Sign In',
  props<{ rememberMe: boolean }>()
);

export const facebookSignIn = createAction(
  '[Auth] Facebook Sign In',
  props<{ rememberMe: boolean }>()
);

export const profileSettingsSave = createAction('[Auth] Profile settings Save');

export const profileFormSave = createAction(
  '[Auth] Profile Form Save',
  props<{ profile: LoggedUser }>()
);
export const profileFormSaveSuccess = createAction(
  '[Auth] Profile Form Save Success',
  props<{ profile: LoggedUser }>()
);
export const profileFormSaveFailure = createAction(
  '[Auth] Profile Form Save Failure',
  props<{ error: string }>()
);

export const showProfile = createAction('[Auth] Show Profile');

export const showTutorial = createAction(
  '[Auth] Show Tutorial',
  props<{ onlyFirstTime: boolean }>()
);

export const selectProfileTab = createAction(
  '[Auth] Selected Profile tab',
  props<{ index: number }>()
);

export const profileChangePasswordFormSave = createAction(
  '[Auth] Profile change password Form Save',
  props<{ password: string; newPassword: string }>()
);
export const profileChangePasswordFormSaveSuccess = createAction(
  '[Auth] Profile change password Success'
);
export const profileChangePasswordFormSaveFailure = createAction(
  '[Auth] Profile change password Failure',
  props<{ error: string }>()
);

export const forgotPassword = createAction(
  '[Auth] Forgot password',
  props<{ username: string }>()
);
export const forgotPasswordSuccess = createAction(
  '[Auth] Forgot password Success',
  props<{ [key: string]: string }>()
);
export const forgotPasswordFailure = createAction(
  '[Auth] Forgot password Failure',
  props<{ error: string }>()
);

export const resetPassword = createAction(
  '[Auth] Reset password submit',
  props<{ username: string; code: string; source?: string }>()
);
export const resetPasswordSuccess = createAction(
  '[Auth] Reset password submit Success'
);
export const resetPasswordFailure = createAction(
  '[Auth] Reset password submit Failure',
  props<{ error: string }>()
);

export const profilePhotoSave = createAction('[Auth] Profile photo Save');
export const profilePhotoSaveSuccess = createAction(
  '[Auth] Profile photo Save Success',
  props<{ profile: LoggedUser }>()
);
export const profilePhotoSaveFailure = createAction(
  '[Auth] Profile photo Save Failure',
  props<{ error: string }>()
);
export const showAuth = createAction('[Auth] Show About ');

export const resetAuthError = createAction('[Auth] Reset auth error');

export const invite = createAction(
  '[Auth] Invite user',
  props<{ request: AdminSignUpRequestDto }>()
);
export const inviteSuccess = createAction(
  '[Auth] Invite Success',
  props<{ user: UserDtoModel }>()
);
export const inviteFailure = createAction(
  '[Auth] Profile photo Save Failure',
  props<{ error: string }>()
);
