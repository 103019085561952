import {
  AfterViewInit,
  Directive,
} from '@angular/core';

import * as feather from 'feather-icons';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appFeatherIcon]',
})
export class FeatherIconDirective implements AfterViewInit {
  ngAfterViewInit() {
    // feather icon
    feather.replace();
  }
}
