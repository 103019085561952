import isEqual from 'lodash-es/isEqual';

import { ColumnState } from '@ag-grid-community/core';
import {
  FilterOperator,
  FilterType,
  FilterValue,
} from '@heitown/common-interfaces';

import { AgGridFilter, AgGridFilterModel } from './aggrid-models';

export function mapToFilterModel(filters: FilterValue[]) {
  const filterModel: AgGridFilterModel = {};
  return filters.reduce((res, f) => {
    if (f.type !== FilterType.date) {
      res[f.field] = buildAgGridFilter(f);
    } else {
      res[f.field] = buildAgGridDateFilter(f);
    }

    return res;
  }, filterModel);
}

export function getAgGridFilterType(filterType: FilterType) {
  switch (filterType) {
    case FilterType.date:
      return 'date';
    case FilterType.number:
      return 'number';
    case FilterType.boolean:
      return 'set';
    default:
      return 'text';
  }
}

export function getDefaultOperator(type: FilterType) {
  if (type === FilterType.text) {
    return FilterOperator.contains;
  } else if (type === FilterType.date) {
    return FilterOperator.equals;
  } else if (type === FilterType.number) {
    return FilterOperator.equals;
  } else if (type === FilterType.currency) {
    return FilterOperator.notEqual;
  } else if (type === FilterType.boolean) {
    return FilterOperator.equals;
  } else if (type === FilterType.enum) {
    return FilterOperator.equals;
  } else if (type === FilterType.autocomplete) {
    return FilterOperator.equals;
  }

  return FilterOperator.contains;
}

export function buildAgGridFilter(f: {
  type: FilterType;
  operator: FilterOperator;
  value: any[];
}): AgGridFilter {
  const obj = {
    type: FilterOperator[f.operator],
    filterType: getAgGridFilterType(f.type),
    filter: f.value.length ? f.value[0] : undefined,
    filterTo: f.value.length === 2 ? f.value[1] : undefined,
  } as AgGridFilter;

  if (obj.filterTo == null) {
    delete obj.filterTo;
  }
  return obj;
}
export function buildAgGridDateFilter(f: {
  type: FilterType;
  operator: FilterOperator;
  value: any[];
}): AgGridFilter {
  const obj = {
    type: FilterOperator[f.operator],
    filterType: getAgGridFilterType(f.type),
    dateFrom: f.value.length ? f.value[0] : undefined,
    dateTo: f.value.length === 2 ? f.value[1] : undefined,
  } as AgGridFilter;

  if (obj.dateTo == null) {
    obj.dateTo = null;
  }
  return obj;
}

export function mapFromFilterModel(filterModel?: AgGridFilterModel) {
  if (!filterModel) {
    return [];
  }
  const filters: FilterValue[] = [];
  Object.keys(filterModel).forEach((key) => {
    const gridFilter = filterModel[key];
    const f = {
      field: key,
      operator: (<any>FilterOperator)[gridFilter.type],
      type: (<any>FilterType)[gridFilter.filterType],
      value: [],
    } as FilterValue;

    if (f.type !== FilterType.date) {
      if (gridFilter.filter != null) {
        f.value.push(gridFilter.filter);
      }

      if (gridFilter.filterTo != null) {
        f.value.push(gridFilter.filterTo);
      }
    } else {
      if (gridFilter.dateFrom != null) {
        f.value.push(gridFilter.dateFrom);
      }

      if (gridFilter.dateTo != null) {
        f.value.push(gridFilter.dateTo);
      }
    }

    filters.push(f);
  });
  return filters;
}

export function mapFromColumnState(columnState?: ColumnState[]) {
  if (!columnState) return undefined;
  return columnState
    .filter((cs) => !!cs.sort && !!cs.colId)
    .reduce((acc: { [key: string]: any }, item) => {
      const colId = item.colId as string;
      const sort = item.sort as string;
      return { ...acc, [colId]: sort.toUpperCase() };
    }, {});
}

export function equalFilters(
  gridFilterModel: AgGridFilterModel | undefined,
  filters: FilterValue[]
) {
  const filtersMapped = mapToFilterModel(filters);

  if (!isEqual(filtersMapped, gridFilterModel)) {
    return false;
  }
  return true;
}

export function getGridColumnType(type: FilterType) {
  switch (type) {
    case FilterType.date:
      return 'dateColumn';
    case FilterType.number:
      return 'numberColumn';
    case FilterType.currency:
      return 'currencyColumn';
    case FilterType.boolean:
      return 'boolColumn';
    case FilterType.enum:
      return 'enumColumn';
    default:
      return ''; // text dà errore in console
  }
}
