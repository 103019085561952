import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ErrorActions from './ngrx-error.actions';
import { NgrxErrorPartialState } from './ngrx-error.reducer';

@Injectable({
  providedIn: 'root',
})
export class NgrxErrorFacade {
  constructor(private store: Store<NgrxErrorPartialState>) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  addError(error: Error) {
    this.store.dispatch(ErrorActions.addError({ error }));
  }

  addApiErrorMessage(errorMessage: string, status: number) {
    this.store.dispatch(
      ErrorActions.addApiErrorMsg({ errorMessage, errorStatusCode: status })
    );
  }
}
