<div class="row w-100 mx-0 auth-page">
  <div class="col-md-8 col-xl-6 mx-auto">
    <div class="card">
      <div class="row">
        <div class="col-md-4 pe-md-0">
          <div
            class="auth-side-wrapper"
            
          ></div>
        </div>
        <div class="col-md-8 ps-md-0">
          <div class="auth-form-wrapper px-4 py-5">
            <a class="nobleui-logo d-block mb-2">Homie</a>

            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
