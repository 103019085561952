import { FormGroupState } from 'ngrx-forms';
import { first, takeUntil } from 'rxjs/operators';

/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseContainer } from '@heitown/frontend-base-ng-classes';

import { AuthFacade } from '../../+state/auth.facade';
import { ResetPasswordFormValue } from '../../+state/auth.reducer';

@Component({
  templateUrl: './reset-password.container.html',
  styleUrls: ['./reset-password.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordContainer extends BaseContainer implements OnInit {
  queryParams$ = this.authFacade.seletQueryParams$;
  resetForm$ = this.authFacade.selectResetPasswordForm$;
  authError$ = this.authFacade.selectAuthError$;

  private username?: string;
  private code?: string;
  private source?: string;

  get showResetButton() {
    return !!this.username && !!this.code;
  }

  constructor(private authFacade: AuthFacade) {
    super();
  }

  onSubmit(form: FormGroupState<ResetPasswordFormValue>) {
    if (this.username && this.code && form.isValid) {
      this.authFacade.resetPassword(this.username, this.code, this.source);
    }
  }

  ngOnInit() {
    this.queryParams$
      .pipe(first(), takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        if (params['data']) {
          const obj: { username: string; code: string } = JSON.parse(
            atob(params['data'])
          );
          this.username = obj.username;
          this.code = obj.code;
        } else {
          this.username = params['username'];
          this.source = params['source'];
          this.code = params['cognito_code'];
        }
      });
  }

  resetAuthError() {
    this.authFacade.resetAuthError();
  }
}
