import { lastValueFrom } from 'rxjs';

/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef,
    Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentDtoModel } from '@heitown/common-dto';
import { isImage } from '@heitown/common-utils';
import { FileApiClient } from '@heitown/frontend-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

let nextId = 0;

@Component({
  selector: 'heitown-file-reader',
  templateUrl: './file-reader.component.html',
  styleUrls: ['./file-reader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileReaderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileReaderComponent),
      multi: true,
    },
  ],
})
export class FileReaderComponent implements OnDestroy, ControlValueAccessor {
  id = `fr-${nextId++}`;

  @ViewChild('input', { static: true })
  input?: ElementRef;

  @Input()
  accept = '*';

  @Input()
  maximumSizeMB = 5;

  @Input()
  multiple = false;

  @Output()
  fileChange = new EventEmitter<File[]>();

  @Output()
  maxSizeError = new EventEmitter<number>();

  value?: AttachmentDtoModel[] = [];
  isValid = true;

  isLoading = false;

  selectedAttachment?: AttachmentDtoModel;

  isDisabled = false;

  @Input() readonly = false;

  // get filename() {
  //   if (!this.value) {
  //     return '';
  //   }
  //   return this.value.name.substring(this.value.name.lastIndexOf('/') + 1);
  // }

  constructor(
    private cd: ChangeDetectorRef,
    private fileService: FileApiClient,
    private dialogService: NgbModal,
    public domSanitizer: DomSanitizer
  ) {}

  propagateChange = (file?: AttachmentDtoModel[]) => {};
  onTouched = () => {};
  validateFn: any = () => {};

  writeValue(value?: AttachmentDtoModel[]) {
    this.setValue(value).then(() => {
      this.cd.detectChanges();
    });
  }

  registerOnChange(fn: (value?: AttachmentDtoModel[]) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    this.isValid = res == null;
    return res;
  }

  ngOnDestroy() {
    this.cd.detach();
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files as FileList;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > this.maximumSizeMB * 1024 * 1024) {
          this.maxSizeError.emit(this.maximumSizeMB);
          return;
        }
      }

      this.onLoad(files);
    }
  }

  isImage(media: AttachmentDtoModel) {
    return isImage(media.mimeType);
  }

  private async onLoad(files?: FileList) {
    if (files && files.length) {
      this.isLoading = true;
      try {
        const res = await lastValueFrom(this.fileService.upload(files));

        if (!this.value) {
          this.value = [];
        }
        await this.setValue([...this.value, ...res]);
        this.propagateChange(this.value);
        this.onTouched();

        if (this.input) {
          this.input.nativeElement.value = null;
        }

        this.isLoading = false;
        this.cd.detectChanges();
      } catch (err) {
        console.error(err);
        this.isLoading = false;
        this.cd.detectChanges();
      }
    }
  }

  public remove(file: AttachmentDtoModel) {
    // this.uploadService.delete(file.id).subscribe((res) => {
    this.value = this.value?.filter((f) => f !== file);

    this.propagateChange(this.value);
    this.cd.detectChanges();
    this.onTouched();
    // });
  }

  public async download(file: AttachmentDtoModel) {
    this.downloadFromUrl(file.publicUrl as string, file.fileName);
  }

  private downloadFromUrl(url: string, name: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private downloadFile(data: Blob, type: string, name: string) {
    // const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(data);
    this.downloadFromUrl(url, name);
    window.URL.revokeObjectURL(url);
  }

  private async setValue(value?: AttachmentDtoModel[]) {
    if (!value) this.value = undefined;
    else {
      this.value = [...value].map((val) => {
        return { ...val };
      });

      for (let i = 0; i < this.value.length; i++) {
        const val = this.value[i];
        if (!val.publicUrl) {
          val.publicUrl = await this.fileService.getUrl(val, false);
        }
      }
    }
  }

  public zoom(file: AttachmentDtoModel, content: any) {
    this.selectedAttachment = file;
    this.dialogService.open(content, {
      size: 'xl',
    });
  }
}
