import cloneDeep from 'lodash-es/cloneDeep';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';

import { ToastComponent } from './toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  errorFeedback(title: string, message?: string, autoDismiss = true) {
    this.toastr.error(
      message,
      title,
      this.buildFeedbackOptions(autoDismiss, 'toast-error')
    );
  }

  successFeedback(title: string, message?: string, autoDismiss = true) {
    this.toastr.success(
      message,
      title,
      this.buildFeedbackOptions(autoDismiss, 'toast-success')
    );
  }

  warnNotification(title: string, message?: string, autoDismiss = true) {
    this.toastr.warning(
      message,
      title,
      this.buildNotificationOptions(autoDismiss, 'toast-warning')
    );
  }

  errorNotification(title: string, message?: string, autoDismiss = true) {
    this.toastr.error(
      message,
      title,
      this.buildNotificationOptions(autoDismiss, 'toast-error')
    );
  }

  private buildFeedbackOptions(
    autoDismiss: boolean,
    cssClass: string
  ): GlobalConfig {
    const opt = this.buildOptions(autoDismiss, cssClass);
    opt.positionClass = 'toast-bottom-left';
    return opt;
  }

  private buildNotificationOptions(
    autoDismiss = false,
    cssClass: string
  ): GlobalConfig {
    const opt = this.buildOptions(autoDismiss, cssClass);
    opt.positionClass = 'toast-top-right';
    return opt;
  }

  private buildOptions(autoDismiss = false, cssClass: string): GlobalConfig {
    const opt = cloneDeep(this.toastr.toastrConfig);
    opt.toastComponent = ToastComponent;
    opt.autoDismiss = autoDismiss;
    opt.disableTimeOut = !autoDismiss;
    opt.tapToDismiss = false;
    opt.progressBar = autoDismiss;
    opt.closeButton = !autoDismiss;
    // opt.toastClass = cssClass;
    return opt;
  }
}
