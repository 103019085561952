import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';

import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseDtoModel } from '@heitown/common-dto';
import { LazyFacadeInterface, SharedFacadeInterface } from '@heitown/frontend-common';
import { RouterService } from '@heitown/frontend-routing-utils';

import { BaseDetailGuard } from './base-detail.guard';

export class LazyDetailGuard<T extends BaseDtoModel>
  extends BaseDetailGuard<T>
  
{
  constructor(
    protected sharedFacade: SharedFacadeInterface<T>,
    protected facade: LazyFacadeInterface<T>,
    breadcrumb: BreadcrumbService,
    routerService: RouterService
  ) {
    super(breadcrumb, routerService);
  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const entityId = route.params['entityId'];

    return this.hasEntity(
      entityId,
      this.routerService.isViewOnlyMode(route),
      this.routerService.isAddMode(route) ||
        this.routerService.isViewOnlyMode(route),
      route
    );
  }

  getOptions(route: ActivatedRouteSnapshot): any {
    return undefined;
  }

  hasEntity(
    id: string,
    disableForm: boolean,
    disableBreadcrumb: boolean,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    return this.facade.getLoadedEntity$.pipe(
      map((item) => {
        const load = id !== '0' && (item === undefined || item?.id !== id);
        if (load) {
          this.sharedFacade.loadSingle(id);
        } else {
          item = id !== '0' ? item : undefined;
          this.facade.formInitialize(item, disableForm, this.getOptions(route));
          this.setBreadcrumb(item, disableBreadcrumb);
        }
        return !load;
      }),
      filter((res) => !!res),
      take(1)
    );
  }
}
