import { Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

export const ngrxRouterFeatureKey = 'router';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface RouterState {
  readonly [ngrxRouterFeatureKey]: RouterReducerState<RouterStateUrl>;
}

// export interface State {
//   routerReducer: RouterReducerState<RouterStateUrl>;
// }

// export const reducers: ActionReducerMap<State> = {
//   routerReducer
// };
