import { BreadcrumbService } from 'xng-breadcrumb';

import { BaseDtoModel } from '@heitown/common-dto';
import { getDescendantProp } from '@heitown/common-utils';
import { BreadcrumbOptions } from '@heitown/frontend-common';
import { RouterService } from '@heitown/frontend-routing-utils';

export class BaseDetailGuard<T extends BaseDtoModel> {
  constructor(
    private breadcrumb: BreadcrumbService,
    protected routerService: RouterService
  ) {}
  breadcrumbDefaultValue: BreadcrumbOptions = {
    showBreadcrumb: true,
    prop: '',
    listDescription: '',
    description: '',
  };

  protected setBreadcrumb(item: T | undefined, disable: boolean) {
    if (this.breadcrumbDefaultValue.showBreadcrumb) {
      this.breadcrumb.set('@entityList', {
        skip: false,
        label: this.breadcrumbDefaultValue.listDescription,
        disable,
      });
      if (this.breadcrumbDefaultValue.prop && item) {
        this.breadcrumb.set('@entityDetail', {
          skip: false,
          label: getDescendantProp(item, this.breadcrumbDefaultValue.prop),
        });
      } else {
        this.breadcrumb.set('@entityDetail', {
          skip: false,
          label: this.breadcrumbDefaultValue.description,
        });
      }
    } else {
      this.breadcrumb.set('@entityList', {
        skip: true,
      });
      this.breadcrumb.set('@entityDetail', {
        skip: true,
      });
    }
  }
}
