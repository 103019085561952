export enum ReservationStatus {
  Created = 'created',
  Confirmed = 'confirmed',
  Canceling = 'canceling',
  Canceled = 'canceled',
  CheckInStarted = 'checkinstarted',
  CheckInCityTaxPaid = 'checkincitytaxpaid',
  CheckInCompleted = 'checkincompleted',
  Done = 'done',
}
