import isEqual from 'lodash-es/isEqual';
import { unbox } from 'ngrx-forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  combineLatest,
  first,
  from,
  map,
  Observable,
  takeUntil,
  withLatestFrom,
} from 'rxjs';

import {
  ColDef,
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  FilterModel,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseDtoModel } from '@heitown/common-dto';
import {
  FieldConfig,
  FieldType,
  FilterType,
  FilterValue,
} from '@heitown/common-interfaces';
import {
  EntityListField,
  FacadeInterface,
  FilterConfig,
  mapFromFilterModel,
  mapToFilterModel,
  SharedFacadeInterface,
} from '@heitown/frontend-common';
import { RouterService } from '@heitown/frontend-routing-utils';
import {
  CustomGridFilterComponent,
  CustomGridFilterParams,
  DataGridAction,
} from '@heitown/frontend-ui-kit';

import { BaseContainer } from './base-container';

@Component({
  template: '',
})
export abstract class BaseListContainer<T extends BaseDtoModel>
  extends BaseContainer
  implements OnInit
{
  abstract entity: string;
  abstract sectionTitle: string;
  // abstract columnDefs: ColDef[];
  // abstract filters: FilterItem[];
  abstract join: string[];
  // se undefined -> uguale a join
  exportJoin: string[] | undefined = undefined;
  abstract fields$: Observable<EntityListField[]>;
  customActions: DataGridAction[] = this.routerService.isFindMode()
    ? [
        {
          description: 'seleziona',
          class: 'icon-arrow-left-circle',
          onAction: (entity: any) => {
            this.select(entity);
          },
          visible: (entity: any) => true,
        },
      ]
    : [];

  createPermissions: string[] = [];
  readPermissions: string[] = [];
  updatePermissions: string[] = [];
  deletePermissions: string[] = [];

  // abstract filters: FilterConfig[];
  // abstract colDefs: ColDef[];

  private _filters?: Observable<FilterConfig[]>;
  get filters$() {
    if (!this._filters) {
      this._filters = this.fields$.pipe(
        map((fields) => fields.filter((f) => !f.hideFilter))
      );
      // .map((f) => {
      //   return {
      //     elementLabel: f.elementLabel,
      //     field: f.field,
      //     filterType: f.filterType,
      //     elementValues$: f.elementValues$,
      //   } as FilterConfig;
      // });
    }
    return this._filters;
  }

  private _fieldToExport?: Observable<FieldConfig[]>;
  get fieldToExport$() {
    if (!this._fieldToExport) {
      this._fieldToExport = this.fields$.pipe(
        map((fields) =>
          fields
            .filter((f) => !f.hideExport)
            .map((f) => {
              return {
                name: f.field,
                label: f.elementLabel,
                type: f.fieldType,
              } as FieldConfig;
            })
        )
      );
    }
    return this._fieldToExport;
  }

  private _columns?: Observable<ColDef[]>;
  get columnDefs$() {
    if (!this._columns) {
      this._columns = this.fields$.pipe(
        map((fields) =>
          fields
            // .filter((f) => !f.hideColumn)
            .map(
              (f) =>
                ({
                  field: f.field,
                  initialHide: f.hideColumn,
                  headerName: f.elementLabel,
                  type: this.getGridColumnType(f.filterType, f.fieldType),
                  filterParams: { suppressAndOrCondition: true },
                  floatingFilter: !f.hideFilter,
                  sortable: !f.hideSort,
                  cellRenderer: f.customCellRenderer
                    ? (params: ICellRendererParams) =>
                        (<any>f.customCellRenderer)(params.data)
                    : null,
                  floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    values: f.elementValues,
                    filterType: f.filterType,
                    field: f.field,
                    entityName: f.entityName,
                  } as CustomGridFilterParams,
                } as ColDef)
            )
        )
      );
    }
    return this._columns;
    // return this.entityFilters?.filter((ef) => ef.isFilter);
  }
  // columnDefs: ColDef[] = [];

  protected gridApi?: GridApi;
  protected columnApi?: ColumnApi;

  commonGridOptions: GridOptions = {
    floatingFiltersHeight: 70,
    pagination: true,
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Colonne',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
      ],
    },
    suppressPaginationPanel: true,
    onGridReady: (e: GridReadyEvent) => this.onGridReady(e),
    onFilterChanged: (e: FilterChangedEvent) => this.onFilterChanged(e),
    onSortChanged: (e: SortChangedEvent) => this.onColumnStateChanged(e),
    onColumnVisible: (e: ColumnVisibleEvent) => this.onColumnStateChanged(e),
    onColumnMoved: (e: ColumnMovedEvent) => this.onColumnStateChanged(e),
    onColumnResized: (e: ColumnResizedEvent) => this.onColumnStateChanged(e),
    defaultColDef: {
      floatingFilter: true,
      resizable: true,
      filter: true,
      suppressMenu: true,
      floatingFilterComponent: 'customGridFilter',
    },
    components: {
      customGridFilter: CustomGridFilterComponent,
    },
    columnTypes: {
      numberColumn: {
        filter: 'agNumberColumnFilter',
      },
      enumColumn: {},
      dateColumn: {
        filter: 'agDateColumnFilter',
      },
    },
  };

  filterFormState$ = this.facade.selectFilterForm$;
  pageSize$ = this.facade.getPageSize$;
  currentPage$ = this.facade.getCurrentPage$;
  totalRows$ = this.facade.getTotalRows$;
  filterModel$ = this.facade.getFilterModel$;

  canAdd$ = new Observable<boolean>();
  canEdit$ = new Observable<boolean>();
  canView$ = new Observable<boolean>();
  canDelete$ = new Observable<boolean>();
  canDownloadExcel$ = new Observable<boolean>();
  canDownloadPdf$ = new Observable<boolean>();

  constructor(
    @Inject('SharedEntityFacade')
    protected sharedFacade: SharedFacadeInterface<T>,
    @Inject('EntityFacade')
    protected facade: FacadeInterface<T>,
    protected routerService: RouterService,
    protected permissionsService: NgxPermissionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.canAdd$ = from(
      this.permissionsService.hasPermission(this.createPermissions)
    ).pipe(
      map((hasPermission) => {
        if (this.routerService.isFindMode()) {
          return false;
        }
        return hasPermission;
      })
    );

    // stessi permessi di lettura
    this.canDownloadExcel$ = from(
      this.permissionsService.hasPermission(this.updatePermissions)
    ).pipe(
      map((hasPermission) => {
        if (this.routerService.isFindMode()) {
          return false;
        }
        return hasPermission;
      })
    );

    this.canDownloadPdf$ = from(
      this.permissionsService.hasPermission(this.updatePermissions)
    ).pipe(
      map((hasPermission) => {
        if (this.routerService.isFindMode()) {
          return false;
        }
        return hasPermission;
      })
    );

    this.canEdit$ = from(
      this.permissionsService.hasPermission(this.updatePermissions)
    ).pipe(
      map((hasPermission) => {
        if (this.routerService.isFindMode()) {
          return false;
        }
        return hasPermission;
      })
    );

    this.canView$ = from(
      this.permissionsService.hasPermission(this.readPermissions)
    ).pipe(
      map((hasPermission) => {
        if (this.routerService.isFindMode()) {
          return false;
        }
        return hasPermission;
      })
    );

    this.canDelete$ = from(
      this.permissionsService.hasPermission(this.deletePermissions)
    ).pipe(
      map((hasPermission) => {
        if (this.routerService.isFindMode()) {
          return false;
        }
        return hasPermission;
      })
    );
  }

  gridOptions$!: Observable<GridOptions>;

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;

    this.pageSize$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pageSize) => {
      this.pageSizeChanged(event.api, pageSize);
    });

    this.facade.getFilterModel$
      .pipe(
        withLatestFrom(this.filterFormState$),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([filterModel, filterForm]) => {
        if (!isEqual(filterModel, this.gridApi?.getFilterModel())) {
          this.gridApi?.setFilterModel(filterModel as FilterModel);
        }

        const newFilters = mapFromFilterModel(filterModel);

        const filterFormValue = unbox(filterForm.value.filters);
        if (!isEqual(newFilters, filterFormValue)) {
          this.facade.setFilterFormValue(newFilters);
        }
      });

    // this.columnDefs$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((colDef) => {
    //   // const actions = this.getActions(
    //   //   canEdit,
    //   //   canDelete,
    //   //   this.routerService.isFindMode()
    //   // );
    //   // colDef = [...colDef, ,];
    //   // console.log('set column defs');
    //   event.api.setColumnDefs(colDef);

    //   event.api.sizeColumnsToFit();
    // });

    this.facade.getColumnState$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((sortModel) => {
        if (
          sortModel &&
          !isEqual(sortModel, event.columnApi.getColumnState())
        ) {
          event.columnApi.applyColumnState({
            state: sortModel,
            applyOrder: true,
          });
        }
      });

    combineLatest([this.currentPage$, this.facade.pageLoaded$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([page, loaded]) => {
        if (loaded) {
          this.gridApi?.paginationGoToPage(page - 1);
        }
      });
  }

  onPageChange(newPage: number) {
    this.facade.setCurrentPage(newPage);
  }

  onPageSizeChanged(size: number) {
    this.facade.setPageSize(size);
  }

  pageSizeChanged(api: GridApi, pageSize: number) {
    // api.purgeInfiniteCache();
    api.paginationSetPageSize(pageSize);
  }

  onColumnStateChanged(
    e:
      | SortChangedEvent
      | ColumnVisibleEvent
      | ColumnMovedEvent
      | ColumnResizedEvent
  ) {
    this.facade.setColumnState(e.columnApi.getColumnState());
  }

  // Ricerca da griglia
  onFilterChanged(e: FilterChangedEvent) {
    this.facade.setFilterModel(e.api.getFilterModel());
  }

  // Ricerca da form esterno
  search(filters: FilterValue[]) {
    const externalFilters = mapToFilterModel(filters);
    this.facade.setFilterModel(externalFilters);
  }

  pdf(fileName: string) {
    this.fieldToExport$.pipe(first()).subscribe((columns) => {
      this.facade.downloadPdf(
        fileName,
        columns,
        this.exportJoin ? this.exportJoin : this.join
      );
    });
  }

  excel(fileName: string) {
    this.fieldToExport$.pipe(first()).subscribe((columns) => {
      this.facade.downloadExcel(
        fileName,
        columns,
        this.exportJoin ? this.exportJoin : this.join
      );
    });
  }

  // protected mapToFilterModel(
  //   filterModel: AgGridFilterModel,
  //   filters: FilterValue[]
  // ) {
  //   return filters.reduce((res, f) => {
  //     res[f.field] = {
  //       type: FilterOperator[f.operator],
  //       filterType: FilterType[f.type],
  //       filter: f.value.length ? f.value[0] : undefined,
  //     };
  //     return res;
  //   }, filterModel);
  // }

  // protected mapFromFilterModel(filterModel: AgGridFilterModel) {
  //   const filters: FilterValue[] = [];
  //   Object.keys(filterModel).forEach((key) => {
  //     const gridFilter = filterModel[key];
  //     filters.push({
  //       field: key,
  //       operator: (<any>FilterOperator)[gridFilter.type],
  //       type: (<any>FilterType)[gridFilter.filterType],
  //       value: [gridFilter.filter],
  //     });
  //   });
  //   return filters;
  // }

  protected getGridColumnType(type: FilterType, fieldType?: FieldType) {
    switch (type) {
      case FilterType.date:
        return fieldType === FieldType.datetime
          ? 'datetimeColumn'
          : 'dateColumn';
      case FilterType.number:
        return 'numberColumn';
      case FilterType.boolean:
        return 'boolColumn';
      case FilterType.enum:
        return 'enumColumn';
      default:
        return ''; // text dà errore in console
    }
  }

  add() {
    this.facade.formInitialize(undefined);
    this.routerService.router.navigate([this.entity, '0']);
  }

  edit(entity: T) {
    this.routerService.router.navigate([this.entity, entity.id]);
  }

  view(entity: T) {
    this.routerService.viewEntityDetail(this.entity, entity.id);
  }

  delete(entity: T) {
    if (confirm('Confermi di voler eliminare questo elemento?')) {
      this.sharedFacade.delete(entity.id, true, false, () => {
        // refresh lista paginata
        if (this.gridApi) {
          this.gridApi.refreshServerSide({}); // { route: route, purge: purge }
        }
      });
    }
  }

  select(item: T) {
    this.routerService.notifySelectedItemToOpener(item);
  }
}
