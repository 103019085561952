import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthFacade } from '../../+state/auth.facade';

@Injectable()
export class ProfileGuard  {
  constructor(private authFacade: AuthFacade) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authFacade.selectLoggedUser$.pipe(
      map((loggedUser) => {
        this.authFacade.profileSettingsInitialize(loggedUser);
        return true;
      })
    );
  }
}
