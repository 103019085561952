import Inputmask from 'inputmask';
import * as momentNs from 'moment';

/* eslint-disable @typescript-eslint/no-empty-function */
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,
    forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

const moment = momentNs;

@Component({
  selector: 'heitown-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges
{
  @Output()
  dateChanged = new EventEmitter<string | null>();

  @Input()
  label = '';

  @Input()
  showIcon = true;

  @Input()
  inputClasses = ['form-control'];

  @Input()
  hasError = false;

  @Input()
  autoClose = true;

  @Input()
  nullable = true;

  @Input()
  container: 'body' | undefined = undefined;

  @Input()
  minDate?: string;

  @Input()
  maxDate?: string;

  @Input()
  hide = false;

  @Input()
  readOnly = false;

  internalMinDate!: NgbDate | null;
  internalMaxDate!: NgbDate | null;

  @ViewChild(NgbInputDatepicker, { static: true })
  dp?: NgbInputDatepicker;

  @ViewChild('input', { static: true })
  input?: ElementRef;

  innerValue?: NgbDate | null;

  public isDisabled = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.minDate) {
      this.internalMinDate = this.dateStringToNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.internalMaxDate = this.dateStringToNgbDate(this.maxDate);
    }

    const inputFormat = moment().localeData().longDateFormat('L').toLowerCase();

    const min = this.minDate ? moment(this.minDate).format('L') : undefined;
    const max = this.maxDate ? moment(this.maxDate).format('L') : undefined;
    const mask = new Inputmask('datetime', {
      alias: 'datetime',
      inputFormat,
      clearMaskOnLostFocus: false,
      min,
      max,
      nullable: this.nullable,
      // positionCaretOnClick: 'none',
      // rightAlign: false
    });

    mask.mask(this.input?.nativeElement);
  }

  propagateChange = (value: string | null) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  writeValue(value: string | null) {
    this.innerValue = this.dateStringToNgbDate(value);

    this.cd.detectChanges();
  }

  registerOnChange(fn: (value: string | null) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  get value() {
    return this.ngbDateToDateString(this.innerValue);
  }

  set value(val: string | null) {
    this.innerValue = this.dateStringToNgbDate(val);
    this.propagateChange(val);
    this.cd.markForCheck();
    this.dateChanged.emit(val);
  }

  ngOnInit(): void {
    if (this.minDate) {
      this.internalMinDate = this.dateStringToNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.internalMaxDate = this.dateStringToNgbDate(this.maxDate);
    }
  }

  ngAfterViewInit(): void {
    const inputFormat = moment().localeData().longDateFormat('L').toLowerCase();

    const min = this.minDate ? moment(this.minDate).format('L') : undefined;
    const max = this.maxDate ? moment(this.maxDate).format('L') : undefined;
    const mask = new Inputmask('datetime', {
      alias: 'datetime',
      inputFormat,
      clearMaskOnLostFocus: false,
      min,
      max,
      nullable: this.nullable,
      // positionCaretOnClick: 'none',
      // rightAlign: false
    });

    mask.mask(this.input?.nativeElement);
  }

  ngOnDestroy() {
    this.cd.detach();
  }

  onModelChange() {
    console.log('datepicker model changes', this.value);
    this.propagateChange(this.value);
    this.onTouched();
    this.dateChanged.emit(this.value);
  }

  clear() {
    this.value = '';
  }

  onToggle() {
    if (!this.isDisabled) {
      this.dp?.toggle();
      setTimeout(() => {
        const el = document.querySelector('ngb-datepicker');
        if (el) {
          el.classList.add('ag-custom-component-popup');
        }
      });
    }
  }

  private ngbDateToDateString(ngbDate?: NgbDate | null): string | null {
    if (!ngbDate || typeof ngbDate === 'string') {
      return null;
    } else {
      return new Date(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day,
        22,
        0,
        0
      ).toISOString();
    }
  }

  private dateStringToNgbDate(dateString: string | null) {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (date) {
      return new NgbDate(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );
    } else {
      return null;
    }
  }
}
