<form
  *ngIf="loginForm$ | async as form"
  [ngrxFormState]="form"
  (submit)="onSubmit(form)"
>
  <div *ngIf="(userMustResetPassword$ | async) === false">
    <h5 class="text-muted fw-normal mb-4">
      Bentornato! Entra con il tuo account.
    </h5>
    <div class="mb-3">
      <heitown-text-field
        [label]="'Email'"
        [ngrxFormControlState]="form.controls.username"
        [errors]="form.controls.username.errors | ngrxFormsErr"
        [shouldShowError]="form.controls.username.isInvalid && form.controls.username.isSubmitted"
      >
      </heitown-text-field>
    </div>
    <div class="mb-3">
      <heitown-text-field
        [label]="'Password'"
        [controlType]="'password'"
        [ngrxFormControlState]="form.controls.password"
        [errors]="form.controls.password.errors | ngrxFormsErr"
        [shouldShowError]="form.controls.password.isInvalid && form.controls.password.isSubmitted"
      >
      </heitown-text-field>
    </div>
    <div>
      <heitown-check-field
        [label]="'Ricordami'"
        [ngrxFormControlState]="form.controls.rememberMe"
      >
      </heitown-check-field>
    </div>
  </div>
  <div *ngIf="userMustResetPassword$ | async">
    <div class="login-heitown mat-title">Scegli la tua password</div>
    <div>
      <heitown-text-field
        [label]="'Password'"
        [controlType]="'password'"
        [ngrxFormControlState]="form.controls.newPassword"
        [errors]="form.controls.newPassword.errors | ngrxFormsErr"
        [shouldShowError]="form.controls.newPassword.isSubmitted"
      >
      </heitown-text-field>
    </div>
    <div>
      <heitown-text-field
        [label]="'Password'"
        [controlType]="'password'"
        [ngrxFormControlState]="form.controls.confirmPassword"
        [errors]="form.controls.confirmPassword.errors | ngrxFormsErr"
        [shouldShowError]="form.controls.confirmPassword.isSubmitted"
      >
      </heitown-text-field>
    </div>
    <div translate class="password-hint">
      La password deve essere lunga 8 caratteri. <br />
      Deve contenere maiuscole e minuscole. <br />
      Deve contenere un numero. <br />
      Deve contenere caratteri speciali.
    </div>
    <div translate class="mt-3 privacy-policy">
      Facendo login, acconsento a
      <a href="https://www.iubenda.com/privacy-policy/" target="_blank"
        >Privacy Policy</a
      >
      e
      <a href="https://www.iubenda.com/termini-e-condizioni/" target="_blank"
        >Termini e condizioni</a
      >.
    </div>
  </div>

  <div class="mt-3 text-end">
    <button type="submit" class="btn btn-primary">Login</button>

    <button
      *ngIf="(userMustResetPassword$ | async) === false"
      type="button"
      class="ms-2 btn btn-primary"
      (click)="googleLogin(form)"
    >
      Login with Google
    </button>

    <button
      *ngIf="(userMustResetPassword$ | async) === false"
      type="button"
      class="ms-2 btn btn-primary"
      (click)="facebookLogin(form)"
    >
      Login with Facebook
    </button>
  </div>
</form>
<div *ngIf="(userMustResetPassword$ | async) === false" class="mt-2">
  <a [routerLink]="['/auth/forgot-password']" translate class="forgot-link"
    >Password dimenticata?</a
  >
</div>
<div class="login-error text-danger text-end mt-3">
  {{ loginError$ | ngrxPush }}
</div>
