import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true,
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/home',
  },
  {
    label: 'Prenotazioni',
    isTitle: true,
  },
  {
    label: 'Calendario',
    icon: 'calendar',
    link: '/booking',
  },
  {
    label: 'Lista',
    icon: 'align-justify',
    link: '/reservation',
  },
  {
    label: 'Moduli',
    isTitle: true,
  },
  {
    label: 'Clienti',
    icon: 'smile',
    link: '/customer',
  },
  {
    label: 'Staff',
    icon: 'users',
    link: '/user',
  },
  {
    label: 'Manutenzioni',
    icon: 'tool',
    link: '/maintenance-request',
  },
  {
    label: 'Schedine PS',
    icon: 'clipboard',
    link: '/ps-istat-card',
  },
  {
    label: 'Servizi extra',
    icon: 'coffee',
    link: '/service-request',
  },
  {
    label: 'Log pulizia camere',
    icon: 'droplet',
    link: '/room-cleaning',
  },
  {
    label: 'Log apertura camere',
    icon: 'unlock',
    link: '/room-opening',
  },
  {
    label: 'Configurazione',
    isTitle: true,
  },
  {
    label: 'Hotel',
    icon: 'briefcase',
    link: '/hotel',
  },
];
