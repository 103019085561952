import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmDialogData {
  /** Title (primary text) */
  title: string;
  /** Message (secondary, explanation text) */
  message: string;
  /** Text for ok button  */
  okText: string;
  /** Text for cancel button  */
  cancelText: string;
  /** Message (secondary, optional explanation text) */
  extraMessage: string;
}

@Component({
  selector: 'heitown-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  /** Title (primary text) */
  public title!: string;
  /** Message (secondary, explanation text) */
  public message!: string;

  /** Text for ok button  */
  public okText!: string;
  /** Text for cancel button  */
  public cancelText!: string;

  constructor(public activeModal: NgbActiveModal) {}

  /**
   * The callback function for button click
   * @param answer The answer
   */
  public respond(answer: boolean) {
    this.activeModal.close(answer);
  }
}
