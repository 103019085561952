import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit,
    Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterOperator, FilterValue } from '@heitown/common-interfaces';
import { AgGridFilterModel, equalFilters, FilterConfig } from '@heitown/frontend-common';

@Component({
  selector: 'heitown-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FiltersFormComponent),
      multi: true,
    },
  ],
})
export class FiltersFormComponent implements ControlValueAccessor, OnInit {
  @Input()
  filterConfig: FilterConfig[] = [];

  @Input()
  filterModel?: AgGridFilterModel;

  @Output()
  applyFilters: EventEmitter<FilterValue[]> = new EventEmitter<FilterValue[]>();

  values: FilterValue[] = [];

  applyEnabled = false;

  form!: FormGroup;

  get disableApply() {
    return (
      equalFilters(this.filterModel, this.values) || this.values.length === 0
    );
  }

  get disableReset() {
    return this.values.length === 0;
  }
  get existsMoreFilters() {
    return this.filterConfig.filter((f) => f.showIntoMoreFilters).length > 0;
  }

  showFilters = true;
  showMoreFilters = false;
  propagateChange: ((values: FilterValue[]) => void) | undefined;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  getValueToEmit() {
    const v = this.form.value;
    const newValues: FilterValue[] = [];

    Object.keys(v).forEach((field) => {
      const filterValue: { operator: FilterOperator; value: any[] } | null =
        v[field];
      const cfg = this.filterConfig.find((f) => f.field === field);
      if (filterValue && cfg) {
        newValues.push({ ...filterValue, type: cfg.filterType, field });
      }
    });

    return newValues;
  }

  ngOnInit(): void {
    this.form = new FormGroup({});

    this.filterConfig.forEach((f) =>
      this.form.addControl(f.field, new FormControl())
    );

    this.form.valueChanges.subscribe((v) => {
      this.values = this.getValueToEmit();

      if (this.propagateChange) {
        this.propagateChange(this.values);
        this.onTouched();
        // this.setApplyButtonState(this.values);
      }
    });
  }

  writeValue(obj: FilterValue[]) {
    if (obj) {
      this.filterConfig.forEach((cfg) => {
        const newValue = obj.find((fv) => fv.field === cfg.field) || null;
        const fc = this.form.controls[cfg.field] as FormControl;
        if (newValue) {
          fc.patchValue(
            {
              value: [...newValue.value],
              operator: newValue.operator,
            },
            { emitEvent: false }
          );
        } else {
          fc.patchValue(null, { emitEvent: false });
        }
      });
      this.values = this.getValueToEmit();
    }

    this.cd.detectChanges();
  }

  filterField(index: number, item: FilterConfig) {
    return item.field;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  search() {
    this.applyFilters.emit(this.values);
  }

  reset() {
    this.writeValue([]);
    this.applyFilters.emit(this.values);
  }

  setApplyButtonState(newValues: FilterValue[]) {
    this.applyEnabled = !equalFilters(this.filterModel, newValues);
    this.cd.detectChanges();
  }
}
