import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { NgrxErrorFacade } from './+state/ngrx-error.facade';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private zone: NgZone) {}

  handleError(error: Error) {
    const facade = this.injector.get(NgrxErrorFacade);
    this.zone.run(() => facade.addError(error));
  }
}
