<input
  #input
  type="tel"
  [class]="inputClasses"
  [class.is-invalid]="hasError"
  (input)="onInput()"
  (focus)="selectAllContent($event)"
  (blur)="onBlur()"
  [readonly]="readOnly"
  [formControl]="formControl"
/>
