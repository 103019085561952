import { Subject } from 'rxjs';

import { Component, OnDestroy } from '@angular/core';

@Component({
  template: '',
})
export abstract class BaseContainer implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
