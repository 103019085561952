import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoomPriceDtoModel } from '@heitown/common-dto';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class RoomPriceApiClient extends BaseCRUDApiClient<RoomPriceDtoModel> {
  baseUrl = this.config.apiEndpoint + '/room-price';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }
}
