import { of } from 'rxjs';
import { concatMap, first, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BaseFormContainer } from '@heitown/frontend-base-ng-classes';

@Injectable({
  providedIn: 'root',
})
export class DetailFormCanDeactivateGuard  {
  constructor(private router: Router, private readonly location: Location) {}

  canDeactivate(
    component: BaseFormContainer<any, any>,
    currentRoute: ActivatedRouteSnapshot
  ) {
    if (!component) {
      console.log('DetailFormCanDeactivateGuard null component');
    }
    if (!component || component.forceCanDeactivate) {
      return of(true);
    }

    // const title = 'Attenzione!';
    const msg = 'Ci sono modifiche non salvate, procedere comunque?';

    return component.canDeactivate().pipe(
      concatMap((result) =>
        of(result).pipe(withLatestFrom(component.formState$))
      ),
      mergeMap(([canDeactivateResult, form]) => {
        if (
          canDeactivateResult &&
          (form?.isPristine || (form?.isSubmitted && form?.isValid))
        ) {
          return of(true);
        } else {
          return of(confirm(msg));
        }
      }),
      first()
    );
  }
}
