<input
  #input
  type="file"
  id="{{ id }}"
  [accept]="accept"
  style="display: none"
  (change)="onSelectFile($event)"
  [multiple]="multiple"
  [readOnly]="readonly"
  [disabled]="isDisabled"
/>

<div class="d-flex">
  <div class="media-preview mx-2" *ngFor="let media of value">
    <div
      *ngIf="isImage(media)"
      class="image-preview"
      [style.backgroundImage]="'url(' + media.publicUrl + ')'"
    ></div>
    <div
      class="attachment-preview d-flex flex-column align-items-center justify-content-center px-1"
      *ngIf="!isImage(media)"
    >
      <i class="feather icon-paperclip"></i>
      <span class="title ellipsis w-100 text-center" [title]="media.fileName">{{
        media.fileName
      }}</span>
    </div>

    <div class="actions">
      <i
        *ngIf="!isDisabled && !readonly"
        class="icon-btn feather icon-x-circle"
        (click)="remove(media)"
        title="elimina"
      >
      </i>
      <i
        class="icon-btn feather icon-download"
        (click)="download(media)"
        title="download"
      >
      </i>
      <i
        class="icon-btn feather icon-eye"
        (click)="zoom(media, content)"
        *ngIf="isImage(media)"
        title="anteprima"
      >
      </i>
    </div>
  </div>

  <div
    class="media-preview mx-2 d-flex align-items-center justify-content-center"
    *ngIf="multiple || !value || value.length === 0"
  >
    <label
      *ngIf="!isLoading && !isDisabled && !readonly"
      class="cursor-pointer"
      #fileInputLabel
      for="{{ id }}"
      disabled
    >
      <i class="icon-btn feather icon-plus"> </i>
    </label>
  </div>
</div>

<ng-template #content>
  <div class="modal-body text-center">
    <img
      [src]="
        domSanitizer.bypassSecurityTrustResourceUrl(
          selectedAttachment?.publicUrl || ''
        )
      "
    />
  </div>
</ng-template>
