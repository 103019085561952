<div class="form-check" [class.readonly]="readOnly">
  <input
    type="checkbox"
    [disabled]="isDisabled"
    id="{{ 'spot_check' + id }}"
    class="form-check-input"
    [class.is-invalid]="hasError"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
    (click)="onClick($event)"
  />
  <label class="form-check-label" [for]="readOnly ? undefined : 'spot_check' + id">
    <span class="inner-label">{{ label }}</span>
    <ng-content></ng-content>
  </label>
</div>
