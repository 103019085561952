import { Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';

import { BaseDtoModel } from '@heitown/common-dto';
import { SharedFacadeInterface } from '@heitown/frontend-common';

@Injectable()
export class BaseLoadAllGuard<T extends BaseDtoModel>  {
  constructor(
    @Inject('SharedEntityFacade') private sharedFacade: SharedFacadeInterface<T>
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkLoaded().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkLoaded(): Observable<boolean> {
    return this.sharedFacade.loadedAll$.pipe(
      tap((loaded) => {
        if (!loaded) {
          this.sharedFacade.loadAll();
        }
      }),
      filter((loaded) => loaded),
      first()
    );
  }
}
