/* eslint-disable @typescript-eslint/member-ordering */
import { merge } from 'lodash';
import * as moment from 'moment';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CustomGridFilterComponent } from '../custom-grid-filter.component';
import { DataGridAction } from './data-grid-action';
import { DataGridActionRenderer } from './data-grid-action-renderer.component';

@Component({
  selector: 'heitown-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
})
export class DataGridComponent implements OnInit {
  @Input()
  canEdit? = false;

  @Input()
  canDelete? = false;

  @Input()
  canView? = false;

  @Input()
  gridOptions?: GridOptions;

  internalGridOptions!: GridOptions;

  private _columnDefs?: ColDef[];
  get columnDefs(): ColDef[] | undefined {
    return this._columnDefs;
  }
  @Input() set columnDefs(value: ColDef[] | undefined) {
    this._columnDefs = value;
    if (this._columnDefs) {
      this._columnDefs = [
        ...this._columnDefs,
        {
          colId: 'actions',
          pinned: 'right',
          width: 80, // todo calcolare width corretta
          type: 'actionColumn',
          cellRendererParams: {
            context: this,
          },
          filter: false,
          sortable: false,
          resizable: false,
          minWidth: 0,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
        } as ColDef,
      ];
      this.gridApi?.setColumnDefs(this._columnDefs);
    }
  }

  @Input()
  items?: any[];

  @Input()
  showDeleteBtn?: (x: any) => boolean;

  @Input()
  customActions: DataGridAction[] = [];

  get actions(): DataGridAction[] {
    return this.getActions();
  }

  @Output()
  add: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  download: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<any>();

  @Output()
  view = new EventEmitter<any>();

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  select: EventEmitter<string> = new EventEmitter<string>();

  modules = [
    RowGroupingModule,
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    ServerSideRowModelModule,
    ClipboardModule,
  ];

  gridApi?: GridApi;
  columnApi?: ColumnApi;

  commonGridOptions: GridOptions = {
    rowHeight: 30,
    floatingFiltersHeight: 70,
    // suppressCellSelection: true,
    onGridReady: (e: GridReadyEvent) => this.onGridReady(e),
    defaultColDef: {
      editable: false,
      enablePivot: false,
      enableValue: false,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 200,
      suppressMenu: true,
      floatingFilter: true,
      filter: true,
      floatingFilterComponent: 'customGridFilter',
    },
    components: {
      gridComponentBooleanRenderer: this.gridComponentBooleanRenderer,
      customGridActionRenderer: DataGridActionRenderer,
      customGridFilter: CustomGridFilterComponent,
    },
    columnTypes: {
      boolColumn: {
        cellRenderer: 'gridComponentBooleanRenderer',
      },
      numberColumn: {
        filter: 'agNumberColumnFilter',
      },
      currencyColumn: {
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return '';
          }
          return (
            '€ ' + this.currencyPipe.transform(params.value + '', ' ') || ''
          );
        },
      },
      enumColumn: {},
      dateColumn: {
        filter: 'agDateColumnFilter',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return '';
          }
          return moment(params.value).format('L');
        },
        valueGetter: (params: ValueGetterParams) => {
          const field = params.colDef.field as string;
          const data = field
            .split('.')
            .reduce((p, c) => (p && p[c]) || null, params.data);
          if (data) {
            return new Date(data);
          } else {
            return null;
          }
        },
      },
      datetimeColumn: {
        filter: 'agDateColumnFilter',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return '';
          }
          return moment(params.value).format('LLL');
        },
        valueGetter: (params: ValueGetterParams) => {
          const field = params.colDef.field as string;
          const data = field
            .split('.')
            .reduce((p, c) => (p && p[c]) || null, params.data);
          if (data) {
            return new Date(data);
          } else {
            return null;
          }
        },
      },
      actionColumn: {
        cellRenderer: 'customGridActionRenderer',
      },
    },
  };

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {
    if (this.gridOptions) {
      this.internalGridOptions = merge(this.commonGridOptions, {
        ...this.gridOptions,
        onGridReady: (e: GridReadyEvent) => {
          this.onGridReady(e);
          if (this.gridOptions?.onGridReady) {
            this.gridOptions.onGridReady(e);
          }
        },
      });
    } else {
      this.internalGridOptions = this.commonGridOptions;
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    console.log('onGridReady');
  }

  gridComponentBooleanRenderer(params: ICellRendererParams) {
    const value = params.value;
    let template = `<div class="d-flex align-items-center h-100">`;
    if (value) {
      template += `Sì`;
      // <i class="material-icons" data-action-type="check">check</i>
    } else {
      template += `No`;
      // template += `<i class="material-icons" data-action-type="close">close</i>`;
    }
    return template + '</div>';
  }

  protected getActions() {
    const actions: DataGridAction[] = [];

    actions.push({
      description: 'modifica',
      class: 'icon-edit-2',
      //class: 'mdi mdi-pencil',
      onAction: (entity: any) => {
        this.edit.emit(entity);
      },
      visible: (entity: any) => !!this.canEdit,
    });
    actions.push({
      description: 'visualizza',
      class: 'icon-eye',
      //class: 'mdi mdi-pencil',
      onAction: (entity: any) => {
        this.view.emit(entity);
      },
      visible: (entity: any) => !!this.canView,
    });
    actions.push({
      description: 'elimina',
      class: 'icon-trash red',
      onAction: (entity: any) => {
        this.delete.emit(entity);
      },
      visible: (entity: any) =>
        this.showDeleteBtn ? this.showDeleteBtn(entity) : !!this.canDelete,
    });

    return [...actions, ...this.customActions];
  }
}
