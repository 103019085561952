import { mergeMap, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthFacade } from './+state/auth.facade';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private authFacade: AuthFacade
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.headers.has('x-allow-anonymous')) {
      return next.handle(request);
    }

    return this.authService.getToken().pipe(
      mergeMap((tk) => {
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + tk),
        });

        return next.handle(request).pipe(
          tap(
            () => {},
            (err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                  return;
                }
                this.authFacade.sessionExpired();
              }
            }
          )
        );
      })
    );
  }
}
