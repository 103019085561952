import { debounceTime } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthFacade } from '@heitown/frontend-auth';
import { NgrxLoaderFacade } from '@heitown/frontend-ngrx-loader';

@Component({
  selector: 'heitown-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'frontend';
  isLoaderVisible$ = this.loaderFacade.isLoaderVisible$.pipe(debounceTime(250));
  constructor(
    public authFacade: AuthFacade,
    private loaderFacade: NgrxLoaderFacade
  ) {}
  ngOnInit() {
    console.log('on init app component');
    // this.authFacade.checkAuth();
    // this.oidcSecurityService
    //   .checkAuth()
    //   .subscribe(({ isAuthenticated, userData, accessToken }) => {
    //     console.log('app authenticated', isAuthenticated);
    //     console.log('userData', userData);
    //     console.log(`Current access token is '${accessToken}'`);
    //     const token = this.oidcSecurityService.getAccessToken();
    //     console.log(`token'${token}'`);
    //   });
  }

  //   const token = this.oidcSecurityService.getAccessToken();
  //   import { HttpHeaders } from '@angular/common/http';

  // const token = this.oidcSecurityServices.getAccessToken();

  // const httpOptions = {
  //   headers: new HttpHeaders({
  //     Authorization: 'Bearer ' + token,
  //   }),
  // };
}
