import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '@heitown/common-utils';
import { ToastService } from '@heitown/frontend-ui-kit';

import { AuthFacade } from './+state/auth.facade';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private authFacade: AuthFacade,
    private authService: AuthService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private notificationService: ToastService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.signedIn().pipe(
      mergeMap((isAuthenticated) => {
        if (isAuthenticated) {
          return this.checkStore();
        } else {
          return this.router.navigateByUrl('/auth/login');
        }
      }),
      catchError(() => {
        this.router.navigateByUrl('/auth/login');
        return of(false);
      })
    );
  }

  checkStore(): Observable<boolean> {
    return this.authFacade.selectLoggedUser$.pipe(
      tap((loggedUser) => {
        if (!loggedUser) {
          this.authFacade.loadLoggedUserProfile();
        }
      }),
      filter((loaded) => !!loaded),
      map((u) => {
        return u?.userType?.code === UserType.Admin;
      }),
      tap((result) => {
        if (!result) {
          this.notificationService.warnNotification(
            'Login web consentito solo per Amministratori, utilizza la tua app.'
          );
          this.authFacade.logout();
        }
        this.permissionsService.loadPermissions([
          'read_all',
          'create_all',
          'update_all',
          'delete_all',
        ]);
      }),
      first()
    );
  }
}
