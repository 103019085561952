import { FormGroupState } from 'ngrx-forms';

/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthFacade } from '../../+state/auth.facade';
import { LoginFormValue } from '../../+state/auth.reducer';

@Component({
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainer {
  loginForm$ = this.authFacade.selectLoginForm$;
  userMustResetPassword$ = this.authFacade.selectUserMustResetPassword$;
  loginError$ = this.authFacade.selectAuthError$;

  constructor(private authFacade: AuthFacade) {}

  onSubmit(form: FormGroupState<LoginFormValue>) {
    if (form.isValid) {
      this.authFacade.login(form.value);
    }
  }

  googleLogin(form: FormGroupState<LoginFormValue>) {
    this.authFacade.googleLogin(form.value.rememberMe);
  }

  facebookLogin(form: FormGroupState<LoginFormValue>) {
    this.authFacade.facebookLogin(form.value.rememberMe);
  }
}
