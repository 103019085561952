<div class="modal-header">
  <h6 class="modal-title">{{ title }}</h6>
</div>
<div *ngIf="message.length > 0" class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="respond(true)">{{ okText }}</button>
  <button
    class="btn btn-secondary ml-3"
    *ngIf="cancelText.length > 0"
    (click)="respond(false)"
  >
    {{ cancelText }}
  </button>
</div>
