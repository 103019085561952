import { Observable } from 'rxjs';

import { BaseDtoModel } from '@heitown/common-dto';

import { FacadeInterface } from './facade.interface';

export interface LazyFacadeInterface<T extends BaseDtoModel>
  extends FacadeInterface<T> {
  getLoadedEntity$: Observable<T | undefined>;
}
