/* eslint-disable @angular-eslint/component-class-suffix */
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export class OAuthContainer implements OnInit {
  constructor(private location: Location, private router: Router) {}
  ngOnInit(): void {
    // console.log(document.location.search);
    this.router.navigate([''], { replaceUrl: true, queryParams: {} });
  }
}
