import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { Component } from '@angular/core';

@Component({
  selector: 'heitown-toast',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        /* background-color: #ff69b4; */
        position: relative;
        overflow: hidden;
        /* margin: 0 0 6px; */
        /* padding: 10px 10px 10px 10px; */
        /* width: 300px; */
        /* border-radius: 3px 3px 3px 3px; */
        /* color: #ffffff; */
        /* pointer-events: all; */
        /* cursor: pointer; */
      }
      /* .btn-pink {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      } */
    `,
  ],
  template: `
    <div
      class="d-flex flex-grow-1"
      [style.display]="state.value === 'inactive' ? 'none' : ''"
    >
      <div class="flex-grow-1 d-flex flex-column justify-content-center">
        <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
        >
          {{ title }}
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          {{ message }}
        </div>
      </div>
      <div class="text-right">
        <span
          *ngIf="options.closeButton"
          class="material-icons-outlined cursor-pointer"
          (click)="remove()"
          >close</span
        >
      </div>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {
  // constructor is only necessary when not using AoT
  constructor(toastrService: ToastrService, toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }
}
