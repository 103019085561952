/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { ForgotPasswordFormValue } from '../../+state/auth.reducer';
import { AuthFacade } from './../../+state/auth.facade';

@Component({
  templateUrl: './forgot-password.container.html',
  styleUrls: ['./forgot-password.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordContainer {
  forgotPasswordForm$ = this.authFacade.selectForgotPasswordForm$;
  resetPasswordEmailSent$ = this.authFacade.resetPasswordEmailSent$;

  constructor(private authFacade: AuthFacade) {}

  onSubmit(form: FormGroupState<ForgotPasswordFormValue>) {
    if (form.isValid) {
      this.authFacade.forgotPassword(form.value.username);
    }
  }
}
