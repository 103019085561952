import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@heitown/frontend-auth';

@Component({
  selector: 'heitown-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  loggedUser$ = this.authFacade.selectLoggedUser$;

  @Input()
  hideNavbar = false;

  @Output()
  logout: EventEmitter<void> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private authFacade: AuthFacade
  ) {}

  ngOnInit(): void {}

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    // e.preventDefault();
    // localStorage.removeItem('isLoggedin');

    // if (!localStorage.getItem('isLoggedin')) {
    //   this.router.navigate(['login']);
    // }
    // this.logout.emit();

    this.authFacade.logout();
  }
}
