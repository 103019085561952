/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-radio-field',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-radio
        [hasError]="shouldShowError"
        [items]="items"
        [formControl]="formControl"
        [readOnly]="readOnly"
      >
      </heitown-radio>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioFieldComponent extends BaseFormFieldComponent {
  @Input()
  items!: { label: string; value: any }[];
}
