import { of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';

import { AuthConfig } from './auth-config';
import { AuthService } from './auth.service';

export enum AuthErrors {
  LoginFailed = 0,
  UserMustResetPassword = 1,
}
@Injectable()
export class MockAuthService extends AuthService {
  private get token() {
    return localStorage.getItem('mockAuthToken') as string;
  }

  private set token(tk: string | undefined) {
    if (!tk) {
      localStorage.removeItem('mockAuthToken');
    } else {
      localStorage.setItem('mockAuthToken', tk);
    }
  }

  constructor(@Optional() config: AuthConfig, http: HttpClient) {
    super(config, http);
  }

  login(
    username: string,
    password: string,
    rememberMe: boolean,
    newPassword?: string
  ) {
    this.token = username;
    return of(this.token);
  }

  logout() {
    this.token = undefined;
    return of(void 0);
  }

  signedIn() {
    return of(!!this.token);
  }

  getToken() {
    return of(this.token);
  }

  googleSignIn(rememberMe: boolean) {
    return of(false);
  }

  facebookSignIn(rememberMe: boolean) {
    return of(false);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return of(true);
  }

  forgotPassword(username: string) {
    return of('');
  }

  resetPassword(username: string, code: string, new_password: string) {
    return of(void 0);
  }
}
