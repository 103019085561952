import { first, takeUntil } from 'rxjs/operators';

/* eslint-disable @angular-eslint/component-class-suffix */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit
} from '@angular/core';
import { BaseContainer } from '@heitown/frontend-base-ng-classes';

import { AuthFacade } from '../../+state/auth.facade';

@Component({
  templateUrl: './accept-invitation.container.html',
  styleUrls: ['./accept-invitation.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptInvitationContainer
  extends BaseContainer
  implements OnInit, OnDestroy
{
  queryParams$ = this.authFacade.seletQueryParams$;

  private username?: string;
  private tmpPassword?: string;
  private source?: string;

  get showAcceptButton() {
    return !!this.username && !!this.tmpPassword;
  }

  constructor(private authFacade: AuthFacade, private cd: ChangeDetectorRef) {
    super();
  }

  accept() {
    if (this.username && this.tmpPassword) {
      this.authFacade.accept(this.username, this.tmpPassword, this.source);
    }
  }

  ngOnInit() {
    this.queryParams$
      .pipe(first(), takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        if (params['data']) {
          const data = params['data'];
          const obj: { username: string; tmpPassword: string } = JSON.parse(
            atob(data)
          );
          this.username = obj.username;
          this.tmpPassword = obj.tmpPassword;
        } else {
          this.username = params['username'];
          this.tmpPassword = params['tmpPassword'];
          this.source = params['source'];
        }

        this.accept();
      });
  }
}
