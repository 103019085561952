import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as momentNs from 'moment';
const moment = momentNs;

@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const date = moment.utc(value, 'L', true).toDate();
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  } // from input -> internal model

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }

    const dt = new Date(date.year, date.month - 1, date.day);
    const result = moment(dt).format('L');

    return result;
  }
}
