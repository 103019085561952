/* eslint-disable @typescript-eslint/no-empty-function */
import Inputmask from 'inputmask';

import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef,
    Inject, Input, LOCALE_ID, OnDestroy, ViewChild
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'heitown-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumericInputComponent),
      multi: true,
    },
  ],
})
export class NumericInputComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input()
  inputClasses = ['form-control'];

  @Input()
  digits: number | '*' = 2;

  @Input()
  nullable = true;

  @Input()
  readOnly = false;

  @Input()
  hasError = false;

  @Input()
  min?: number;

  @Input()
  max?: number;

  @Input()
  rightAlign = false;

  @Input()
  digitsOptional = true;

  @Input()
  prefix = '';

  formControl = new FormControl();

  // public value: number | null = null;

  @ViewChild('input', { static: true })
  input?: ElementRef;

  private get inputValue(): number | null {
    const val = this.input?.nativeElement.inputmask.unmaskedvalue();
    if (typeof val === 'string' && val !== '') {
      // BUG inputmask!
      return 0;
    }

    if (val === '') {
      return this.nullable ? null : 0;
    }
    return val;
  }

  // public get textValue() {
  //   return this.value === null ? '' : this.value.toString();
  // }

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  public onInput() {
    // console.log(this.inputValue);
    // this.value = this.inputValue;
    // this.onModelChange();
  }

  propagateChange = (value: number | null) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  writeValue(value: number | null) {
    // if (value) {
    // this.value = value;
    this.formControl.setValue(value, {
      emitEvent: false,
    });
    this.cd.markForCheck();

    // console.log(this.input?.nativeElement.inputmask.unmaskedvalue());

    // if (value == null) this.input?.nativeElement.inputmask.setValue(null);
    // this.input?.nativeElement.inputmask.setValue(value);
    // }
  }

  registerOnChange(fn: (value: number | null) => void) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  ngAfterViewInit(): void {
    // console.log(
    //   'separatore',
    //   getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal)
    // );

    const mask = new Inputmask('numeric', {
      alias: 'numeric',
      prefix: this.prefix,
      allowMinus: false,
      // allowPlus: false,
      unmaskAsNumber: true,
      radixPoint: getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal),
      groupSeparator: getLocaleNumberSymbol(this.locale, NumberSymbol.Group),
      min: this.min,
      max: this.max,
      digits: this.digits,
      clearMaskOnLostFocus: false,
      nullable: this.nullable,
      // positionCaretOnClick: 'none',
      rightAlign: this.rightAlign,
      digitsOptional: this.digitsOptional,
      placeholder: this.nullable ? '' : '0',
    });

    // if (!this.nullable) this.value = 0;

    mask.mask(this.input?.nativeElement);

    this.formControl.valueChanges.subscribe(() => {
      this.onModelChange();
    });
  }

  ngOnDestroy() {
    this.cd.detach();
  }

  private focusIn() {
    window.setTimeout(() => {
      this.input?.nativeElement.focus();
    });
  }

  onBlur() {
    this.onModelChange();
  }

  public selectAllContent($event: FocusEvent) {
    this.focusIn();
    setTimeout(() => {
      const tgt = $event.target as HTMLInputElement;
      if (tgt === document.activeElement) {
        tgt.select();
      }
    }, 50);
  }

  onModelChange() {
    this.propagateChange(this.inputValue);
    this.onTouched();
  }
}
