<footer class="footer border-top">
  <div
    class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between py-3 small"
  >
    <p class="text-muted mb-1 mb-md-0">
      Copyright © 2022
      <a href="https://www.heitown.com" target="_blank">Homie</a>
    </p>
    <p class="text-muted">
      Handcrafted With
      <i class="feather icon-heart mb-1 text-primary ms-1 icon-sm"></i>
    </p>
  </div>
</footer>
