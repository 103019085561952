<!-- <select >
  <option *ngFor="let item of items" [ngValue]="item.value">
    {{ item.description }}
  </option>
</select> -->
<!-- <heitown-select
  [formControl]="operatorControl"
  [items]="items"
  [clearable]="false"
>
</heitown-select> -->
<div ngbDropdown class="d-inline-block" container="body">
  <button type="button" class="btn text-primary p-0" ngbDropdownToggle>
    {{ selectedItem?.description }}
  </button>
  <div ngbDropdownMenu>
    <button
      type="button"
      ngbDropdownItem
      *ngFor="let item of items"
      (click)="setOperator(item)"
    >
      {{ item.description }}
    </button>
  </div>
</div>
