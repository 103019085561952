import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  template: '',
})
export abstract class BaseFormFieldComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  label = '';

  @Input()
  errors: string[] = [];

  @Input()
  shouldShowError = false;

  @Input()
  readOnly = false;

  @Input()
  isValid = true;

  formControl = new FormControl();

  protected ngUnsubscribe = new Subject<void>();

  propagateChange = (value: any) => {};
  onTouched = () => {};

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((v) => {
        this.propagateChange(v);
        this.onTouched();
      });
  }

  onChanged(v: any) {
    this.propagateChange(v);
    this.onTouched();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(obj: any) {
    this.formControl.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.formControl.disable();
    else this.formControl.enable();
  }
}
