import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PsIstatCardDtoModel } from '@heitown/common-dto';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class PsIstatCardApiClient extends BaseCRUDApiClient<PsIstatCardDtoModel> {
  baseUrl = this.config.apiEndpoint + '/ps-istat-card';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  async downloadFromId(item: PsIstatCardDtoModel) {
    const blob = await lastValueFrom(
      this.http.post(
        this.baseUrl + '/' + item.id + '/download',
        {},
        {
          responseType: 'arraybuffer',
        }
      )
    );

    this.downloadFile(
      blob,
      'text/plain',
      item.hotel?.name + ' ' + moment(item.date).format('DD_MM_YYYY') + '.txt'
    );
  }

  private downloadFromUrl(url: string, name: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private downloadFile(data: ArrayBuffer, type: string, name: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    this.downloadFromUrl(url, name);
    window.URL.revokeObjectURL(url);
  }
}
