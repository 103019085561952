import { ResetAction, SetValueAction } from 'ngrx-forms';

import { Injectable } from '@angular/core';
import { AdminSignUpRequestDto } from '@heitown/common-dto';
import { select, Store } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { LoggedUser } from './auth.models';
import {
  LOGIN_FORM_ID,
  loginFormInitialState,
  LoginFormValue,
  PROFILE_CHANGE_PASSWORD_FORM_ID,
  PROFILE_SETTINGS_FORM_ID,
  ProfileChangePasswordFormValue,
  ProfileSettingsFormValue,
} from './auth.reducer';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  selectLoginForm$ = this.store.pipe(select(AuthSelectors.selectLoginForm));
  selectLoggedUser$ = this.store.pipe(select(AuthSelectors.selectLoggedUser));

  selectAuthError$ = this.store.pipe(select(AuthSelectors.selectAuthError));
  resetPasswordEmailSent$ = this.store.pipe(
    select(AuthSelectors.resetPasswordEmailSent)
  );

  selectUserMustResetPassword$ = this.store.pipe(
    select(AuthSelectors.selectUserMustResetPassword)
  );

  selectSource$ = this.store.pipe(select(AuthSelectors.selectSource));

  // selectQueryDomain$ = this.store.pipe(select(AuthSelectors.selectQueryDomain));
  selectProfileSettingsForm$ = this.store.pipe(
    select(AuthSelectors.selectProfileSettingsForm)
  );
  selectProfilePhotoForm$ = this.store.pipe(
    select(AuthSelectors.selectProfilePhotoForm)
  );
  selectProfileChangePasswordForm$ = this.store.pipe(
    select(AuthSelectors.selectProfileChangePasswordForm)
  );
  selectCanSaveProfile$ = this.store.pipe(
    select(AuthSelectors.selectCanSaveProfile)
  );
  selectCanSaveProfileSettings$ = this.store.pipe(
    select(AuthSelectors.selectCanSaveProfileSettings)
  );
  selectCanSaveProfileChangePassword$ = this.store.pipe(
    select(AuthSelectors.selectCanSaveProfileChangePassword)
  );
  getSelectedProfileTab$ = this.store.pipe(
    select(AuthSelectors.getSelectedProfileTab)
  );
  selectForgotPasswordForm$ = this.store.pipe(
    select(AuthSelectors.selectForgotPasswordForm)
  );
  selectResetPasswordForm$ = this.store.pipe(
    select(AuthSelectors.selectResetPasswordForm)
  );
  seletQueryParams$ = this.store.pipe(select(AuthSelectors.selectQueryParams));
  constructor(private store: Store) {}

  sessionExpired() {
    this.store.dispatch(AuthActions.sessionExpired());
  }

  login(form: LoginFormValue) {
    this.store.dispatch(
      AuthActions.login({
        username: form.username,
        password: form.password,
        rememberMe: form.rememberMe,
        newPassword: form.newPassword,
      })
    );
  }

  invite(request: AdminSignUpRequestDto) {
    this.store.dispatch(AuthActions.invite({ request }));
  }

  resetAuthError() {
    this.store.dispatch(AuthActions.resetAuthError());
  }

  accept(username: string, tempPassword: string, source?: string) {
    this.store.dispatch(
      AuthActions.login({
        username,
        password: tempPassword,
        rememberMe: false,
        source,
      })
    );
  }

  googleLogin(rememberMe: boolean) {
    this.store.dispatch(AuthActions.googleSignIn({ rememberMe }));
  }

  facebookLogin(rememberMe: boolean) {
    this.store.dispatch(AuthActions.facebookSignIn({ rememberMe }));
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  loginFormInitialize() {
    this.store.dispatch(
      new SetValueAction<LoginFormValue>(LOGIN_FORM_ID, loginFormInitialState)
    );
    this.store.dispatch(new ResetAction(LOGIN_FORM_ID));
  }

  loginFormInitializeWithTmpPassword(username: string, password: string) {
    this.store.dispatch(
      new SetValueAction<LoginFormValue>(LOGIN_FORM_ID, {
        username,
        password,
        rememberMe: false,
        newPassword: '',
        confirmPassword: '',
      })
    );
    this.store.dispatch(new ResetAction(LOGIN_FORM_ID));
  }

  loadLoggedUserProfile() {
    this.store.dispatch(AuthActions.loadLoggedUser());
  }

  profileFormSave() {
    this.store.dispatch(AuthActions.profileSettingsSave());
  }

  profileSettingsInitialize(user?: LoggedUser) {
    this.profileSettingsFormInitialize(user);
    this.profileChangePasswordFormInitialize();
    this.setSelectProfileTab(0);
  }

  private profileSettingsFormInitialize(user?: LoggedUser) {
    this.store.dispatch(
      new SetValueAction<ProfileSettingsFormValue>(PROFILE_SETTINGS_FORM_ID, {
        firstName: user?.baseInfo.firstname || '',
        lastName: user?.baseInfo.lastname || '',
        email: user?.baseInfo.email || '',
        roleId: user?.userTypeId || '',
        // gender: user?.person?.gender || '',
      })
    );
    this.profileSettingsFormActionInitialize();
  }

  profileSettingsFormActionInitialize() {
    this.store.dispatch(new ResetAction(PROFILE_SETTINGS_FORM_ID));
  }

  private profileChangePasswordFormInitialize() {
    this.store.dispatch(
      new SetValueAction<ProfileChangePasswordFormValue>(
        PROFILE_CHANGE_PASSWORD_FORM_ID,
        {
          password: '',
          newPassword: '',
          confirmPassword: '',
        }
      )
    );
    this.store.dispatch(new ResetAction(PROFILE_CHANGE_PASSWORD_FORM_ID));
  }

  profileChangePasswordSave() {
    this.store.dispatch(AuthActions.profileSettingsSave());
  }

  profileChangePasswordFormActionInitialize() {
    this.store.dispatch(new ResetAction(PROFILE_CHANGE_PASSWORD_FORM_ID));
  }

  showProfile() {
    this.store.dispatch(AuthActions.showProfile());
  }

  showTutorial(onlyFirstTime = true) {
    this.store.dispatch(AuthActions.showTutorial({ onlyFirstTime }));
  }

  setSelectProfileTab(index: number) {
    this.store.dispatch(AuthActions.selectProfileTab({ index }));
  }

  forgotPassword(username: string) {
    this.store.dispatch(AuthActions.forgotPassword({ username }));
  }

  resetPassword(username: string, code: string, source?: string) {
    this.store.dispatch(AuthActions.resetPassword({ username, code, source }));
  }

  profilePhotoSave() {
    this.store.dispatch(AuthActions.profilePhotoSave());
  }

  showAbout() {
    this.store.dispatch(AuthActions.showAuth());
  }
}
