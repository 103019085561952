<div
  class="form-check form-check-inline"
  *ngFor="let item of items"
  [class.readonly]="readOnly"
>
  <input
    name="spot_radio_{{ id }}"
    [disabled]="isDisabled"
    type="radio"
    id="spot_radio_{{ id }}_{{ item.value }}"
    class="form-check-input"
    [class.is-invalid]="hasError"
    (ngModelChange)="onModelChange($event)"
    [value]="item.value"
    [(ngModel)]="value"
    (click)="onClick($event)"
  />
  <label
    class="form-check-label"
    [for]="readOnly ? undefined : 'spot_radio_' + id + '_' + item.value"
    >{{ item.label }}</label
  >
</div>
