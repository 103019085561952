import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'heitown-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  @Input()
  type!: string;

  @Input()
  title!: string;

  @Input()
  desc!: string;

  @Input()
  showBack = true;

  @Output()
  back = new EventEmitter<void>();
}
