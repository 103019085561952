import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';

import { ColumnState } from '@ag-grid-community/core';
import { BaseDtoModel } from '@heitown/common-dto';
import { FieldConfig, FilterValue } from '@heitown/common-interfaces';

import { AgGridFilterModel } from './aggrid-models';
import { FilterFormValueInterface } from './filter-form-value.interface';
import { FormValueInterface } from './form-value.interface';

export interface FacadeInterface<T extends BaseDtoModel> {
  getPageSize$: Observable<number>;
  getCurrentPage$: Observable<number>;
  getTotalRows$: Observable<number>;
  selectFilterForm$: Observable<FormGroupState<FilterFormValueInterface>>;
  getColumnState$: Observable<ColumnState[] | undefined>;
  getFilterModel$: Observable<AgGridFilterModel | undefined>;
  pageLoaded$: Observable<boolean>;
  selectedEntity$: Observable<T | undefined>;
  selectEntityId$: Observable<number | string>;
  selectForm$: Observable<FormGroupState<FormValueInterface>>;

  setPageSize: (pageSize: number) => void;
  setCurrentPage: (currentPage: number) => void;
  formInitialize: (
    item: T | undefined,
    disable?: boolean,
    options?: any
  ) => void;
  setPageLoaded: (loaded: boolean) => void;
  setTotalRows: (totalRows: number) => void;
  formSave: (form: FormGroupState<FormValueInterface>) => void;
  formDelete: () => void;
  setFilterModel: (filterModel: AgGridFilterModel) => void;
  setColumnState: (sortModel: ColumnState[]) => void;
  setFilterFormValue: (value: FilterValue[]) => void;

  downloadPdf: (
    fileName?: string,
    columns?: FieldConfig[],
    join?: string[]
  ) => void;
  downloadExcel: (
    fileName?: string,
    columns?: FieldConfig[],
    join?: string[]
  ) => void;
  downloadLabel: () => void;
}
