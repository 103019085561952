import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmDialogComponent } from './confirm-dialog.component';

/**
 * Confirm modal service
 * @description Shows a simple confirm dialog
 */
@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  /** Bs modal reference to the intername modal component */

  public modalRef?: NgbModalRef;

  /**
   * Class constructor
   * @param modalService Bs modal service
   */
  constructor(private modalService: NgbModal) {}

  public async confirm(
    title: string,
    message: string = '',
    cancelText: string = '',
    okText: string = '',
    showcancel: boolean = true
  ) {
    if (!cancelText) {
      cancelText = 'Annulla';
    }
    if (!showcancel) {
      cancelText = '';
    }
    if (!okText) {
      okText = 'Ok';
    }

    this.modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    const dialog = this.modalRef.componentInstance as ConfirmDialogComponent;

    dialog.title = title;
    dialog.message = message;
    dialog.cancelText = cancelText;
    dialog.okText = okText;
    return this.modalRef.result;
  }
}
