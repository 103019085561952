<form
  *ngIf="resetForm$ | async as form"
  [ngrxFormState]="form"
  (submit)="onSubmit(form)"
>
  <div class="login-heitown mat-title">Scegli la tua password</div>
  <div>
    <heitown-text-field
      [label]="'Password'"
      [controlType]="'password'"
      [ngrxFormControlState]="form.controls.newPassword"
      [errors]="form.controls.newPassword.errors | ngrxFormsErr"
      [shouldShowError]="form.controls.newPassword.isInvalid &&form.controls.newPassword.isSubmitted"
    >
    </heitown-text-field>
  </div>
  <div>
    <heitown-text-field
      [label]="'Password'"
      [controlType]="'password'"
      [ngrxFormControlState]="form.controls.confirmPassword"
      [errors]="form.controls.confirmPassword.errors | ngrxFormsErr"
      [shouldShowError]="form.controls.confirmPassword.isInvalid && form.controls.confirmPassword.isSubmitted"
    >
    </heitown-text-field>
  </div>
  <div translate class="password-hint">
    La password deve essere lunga 8 caratteri. <br />
    Deve contenere maiuscole e minuscole. <br />
    Deve contenere un numero. <br />
    Deve contenere caratteri speciali.
  </div>
  <div class="mt-3 text-end">
    <button type="submit" class="btn btn-primary">Reset password</button>
  </div>
</form>
<div
  class="login-error text-error text-right mt-3"
  *ngIf="authError$ | ngrxPush as authError"
>
  {{ authError }}
  <br />
  <br />
  <yam-ui-button
    (click)="resetAuthError()"
    [routerLink]="['/auth/login']"
    buttonType="button"
    color="primary"
    >Torna al login
  </yam-ui-button>
  <!-- <a
    [routerLink]="['/auth/forgot-password']"
    translate
    class="forgot-link"
    translate
    >Try again</a
  > -->
</div>
