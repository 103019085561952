import 'moment-timezone';

import * as moment from 'moment';

export class DateUtils {
  static timezoneList(): string[] {
    return moment.tz.names();
  }

  static convertToTimezone(date: Date, timezone: string): Date {
    return new Date(moment(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss'));
  }

  static getTimezoneUtcOffsetInHour(timezone: string): number {
    return moment().tz(timezone).utcOffset() / 60;
  }

  static diffInDays(to: Date, from: Date): number {
    const momentFrom = moment(from);
    const momentTo = moment(to);

    return momentTo.diff(momentFrom, 'days');
  }

  static formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  static getCurrentDateTimeUTC(): Date {
    return new Date(moment.utc().format());
  }

  static getCurrentDayUTCDate(): Date {
    return new Date(moment.utc().startOf('day').format());
  }

  static getCurrentDayUTC(): string {
    return moment.utc().startOf('day').format('YYYY-MM-DD');
  }

  static getCurrentStartOfMonth(): string {
    return moment().startOf('month').format('YYYY-MM-DD');
  }

  static getCurrentStartOfMonthDate(): Date {
    return new Date(moment().startOf('month').format('YYYY-MM-DD'));
  }

  static subtractDaysFromDate(date: Date, dayNumber: number): Date {
    return new Date(
      moment(date).subtract(dayNumber, 'days').format('YYYY-MM-DD')
    );
  }

  static subtractHoursFromDate(date: Date, hoursNumber: number): Date {
    return new Date(
      moment(date).subtract(hoursNumber, 'hours').format('YYYY-MM-DD HH:mm:ss')
    );
  }

  static addMonthFromDate(date: Date, monthNumber: number): Date {
    return new Date(moment(date).add(monthNumber, 'M').format('YYYY-MM-DD'));
  }

  static addDaysFromDate(date: Date, daysNumber: number): Date {
    return new Date(moment(date).add(daysNumber, 'days').format('YYYY-MM-DD'));
  }

  static getDaysInRange(
    from: Date,
    to: Date,
    includeLastDay?: boolean
  ): string[] {
    const days: string[] = [];
    for (
      const start = moment(from);
      includeLastDay ? start.isSameOrBefore(to) : start.isBefore(to);
      start.add(1, 'days')
    ) {
      days.push(start.format('YYYY-MM-DD'));
    }
    return days;
  }

  static isValidDate(date: Date): boolean {
    return moment(date, 'YYYY-MM-DD', true).isValid();
  }
}
