/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-text-field',
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-text-input
        [controlType]="controlType"
        [placeholder]="placeholder"
        [hasError]="shouldShowError"
        [formControl]="formControl"
        [longText]="longText"
        [readOnly]="readOnly"
        (blur)="onBlur()"
      >
      </heitown-text-input>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent extends BaseFormFieldComponent {
  @Input()
  controlType: 'text' | 'password' | 'tel' | 'email' = 'text';

  @Input()
  placeholder = '';

  @Input()
  longText = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  onBlur() {
    this.blur.emit();
  }
}
