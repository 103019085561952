import { Observable, of } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseDtoModel } from '@heitown/common-dto';

export class ListGuard<T extends BaseDtoModel>  {
  constructor(private breadcrumb: BreadcrumbService) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.setBreadcrumb();
    return of(true);
  }

  setBreadcrumb() {
    this.breadcrumb.set('@entityList', {
      skip: true,
    });
  }
}
