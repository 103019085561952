import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseContainer } from '@heitown/frontend-base-ng-classes';
import { ConfirmService } from '@heitown/frontend-ui-kit';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AuthFacade } from '../../+state/auth.facade';
import { LoggedUser } from '../../+state/auth.models';

export interface FileReaderValue {
  contentType: string;
  name: string;
  content: string;
  existingFileUrl: string;
}
@Component({
  templateUrl: './profile.container.html',
  styleUrls: ['./profile.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileContainer extends BaseContainer implements OnInit {
  settingsFormState$ = this.authFacade.selectProfileSettingsForm$;
  settingsPhotoForm$ = this.authFacade.selectProfilePhotoForm$;
  changePasswordFormState$ = this.authFacade.selectProfileChangePasswordForm$;
  canSaveProfile$ = this.authFacade.selectCanSaveProfile$;
  forgotPasswordFormState$ = this.authFacade.selectForgotPasswordForm$;

  loggedUser: LoggedUser | undefined;

  genders = [
    { value: 'F', description: 'Donna' },
    { value: 'M', description: 'Uomo' },
  ];

  canSaveProfileSettingsOrChangePassword = false;
  canSaveProfile = false;

  constructor(
    private dialogRef: NgbModalRef,
    private authFacade: AuthFacade,
    private confirmService: ConfirmService
  ) {
    super();
  }
  ngOnInit(): void {
    this.authFacade.selectLoggedUser$.pipe(take(1)).subscribe((loggedUser) => {
      this.loggedUser = loggedUser;
      this.authFacade.profileSettingsInitialize(loggedUser);
    });
    this.canSaveProfile$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((canSave) => {
        this.canSaveProfile = canSave;
      });
    combineLatest([
      this.authFacade.selectCanSaveProfileSettings$,
      this.authFacade.selectCanSaveProfileChangePassword$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([canSaveProfileSettings, canSaveProfileChangePassword]) =>
          (this.canSaveProfileSettingsOrChangePassword =
            canSaveProfileSettings || canSaveProfileChangePassword)
      );
  }

  canModify(prop: string) {
    return false;
  }

  cancel() {
    if (this.canSaveProfileSettingsOrChangePassword) {
      const title = 'Attenzione!';
      const msg = 'Ci sono delle modifiche non salvate, procedere comunque?';

      //show popup
      this.confirmService
        .confirm(title, msg)
        .then((result) => (result === true ? this.dialogRef.close() : null));
    } else {
      this.dialogRef.close();
    }
  }

  save() {
    this.authFacade.profileFormSave();
  }

  onselectedIndexChange(tabIndex: number) {
    this.authFacade.setSelectProfileTab(tabIndex);
  }

  onFileChange() {
    if (this.loggedUser) {
      this.authFacade.profilePhotoSave();
    }
  }
}
