import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ErrorEffects } from './+state/ngrx-error.effects';
import { NgrxErrorFacade } from './+state/ngrx-error.facade';
import * as fromState from './+state/ngrx-error.reducer';
import { GlobalErrorHandler } from './global-error-handler.service';
import { HttpErrorInterceptor } from './http-error.interceptor';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.ERROR_FEATURE_KEY, fromState.reducer),
    EffectsModule.forFeature([ErrorEffects]),
  ],
})
export class FrontendErrorModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FrontendErrorModule
  ) {
    if (parentModule) {
      throw new Error(
        'FrontendErrorModule is already loaded. Import only in AppModule'
      );
    }
  }

  static forRoot(): ModuleWithProviders<FrontendErrorModule> {
    return {
      ngModule: FrontendErrorModule,
      providers: [
        NgrxErrorFacade,
        {
          // processes all errors
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}
