import {
    catchError, debounceTime, distinctUntilChanged, filter, map, Observable, of, OperatorFunction,
    Subscription, switchMap, tap
} from 'rxjs';

import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector,
    Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {
    ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { BaseDtoModel } from '@heitown/common-dto';
import { FilterOperator, FilterType } from '@heitown/common-interfaces';
import { BaseApiClient } from '@heitown/frontend-api-client';
import { getDefaultOperator, SelectItem } from '@heitown/frontend-common';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { OperatorItem, OPERATORS } from '../operators/operators';

// interface FilterFormValue {
//   operator: FilterOperator;
//   value: any;
//   value2: any;
// }

@Component({
  selector: 'heitown-single-filter',
  templateUrl: './single-filter.component.html',
  styleUrls: ['./single-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleFilterComponent),
      multi: true,
    },
  ],
})
export class SingleFilterComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input()
  label!: string;

  @Input()
  type!: FilterType;

  @Input()
  items: SelectItem[] = [];

  @Input()
  field!: string;

  @Input()
  entityName?: string;

  @Input()
  showLabel = true;

  autocompleteLoading?: boolean;

  get valueArray() {
    return this.valueForm.controls['value'] as FormArray;
  }

  get operatorControl() {
    return this.valueForm.controls['operator'] as FormControl;
  }

  get valueToEmit() {
    if (!this.isNotEmpty()) {
      return null;
    } else {
      return {
        ...this.valueForm.value,
        value: this.valueForm.value.value ? this.valueForm.value.value : [],
      };
    }
  }

  get decodeField() {
    const splittedField = this.field.split('.').filter((s) => !!s);
    return splittedField[splittedField.length - 1];
  }

  @Output()
  valueChange = new EventEmitter<{
    operator: FilterOperator;
    value: any[];
  } | null>();

  subscriptions: Subscription[] = [];

  valueForm!: FormGroup;

  operators!: OperatorItem[];

  filterTypes = FilterType;

  constructor(private cd: ChangeDetectorRef, private injector: Injector) {}

  propagateChange?: (value: { value: any[]; operator: FilterOperator }) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(obj: { value: any[]; operator: FilterOperator } | null): void {
    if (obj) {
      this.valueForm.patchValue(obj, { emitEvent: false });
    } else {
      this.clear(false);
    }

    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    if (this.type == null) {
      throw new Error('Filter type mandatory');
    }

    this.operators = OPERATORS.filter(
      (o) => o.filters.filter((f) => f === this.type).length > 0
    );
    if (this.type === this.filterTypes.boolean) {
      this.items = [
        { value: 'true', description: 'Sì' },
        { value: 'false', description: 'No' },
      ];
    }

    this.valueForm = new FormGroup({
      value: new FormArray([new FormControl()]),
      // value2: new FormControl(),
      operator: new FormControl(getDefaultOperator(this.type)),
    });

    this.subscriptions.push(
      this.operatorControl.valueChanges.subscribe((op) => {
        this.onSelectOperator(op);
      })
    );

    this.subscriptions.push(
      this.valueForm.valueChanges.subscribe((v) => {
        if (this.propagateChange) this.propagateChange(this.valueToEmit);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onSelectOperator(operator: FilterOperator) {
    const arr = this.valueArray;

    if (operator === FilterOperator.inRange && arr.length === 1) {
      arr.push(new FormControl());
    } else if (arr.length === 2) {
      arr.removeAt(1);
    }

    if (operator === FilterOperator.empty && this.valueArray.enabled) {
      this.valueArray.disable({ emitEvent: false });
    } else if (this.valueArray.disabled) {
      this.valueArray.enable({ emitEvent: false });
    }

    this.cd.detectChanges();
  }

  clear(emitEvent: boolean) {
    this.valueForm.patchValue(
      {
        value: [null],
        operator: getDefaultOperator(this.type),
      },
      {
        emitEvent,
      }
    );
    this.cd.detectChanges();
  }

  isNotEmpty() {
    const isNotEmpty =
      this.valueArray.getRawValue().some((v) => v !== '' && v != null) ||
      this.operatorControl.value === FilterOperator.empty;
    return isNotEmpty;
  }

  showClear() {
    return (
      this.operatorControl.value !== getDefaultOperator(this.type) ||
      this.valueArray.getRawValue().some((v) => v !== '' && v != null)
    );
  }

  selected($event: NgbTypeaheadSelectItemEvent, control: FormControl) {
    // this.decodeFormControl.setValue($event.item);
    control.setValue($event.item);
  }

  blur(decodeInput: HTMLInputElement, control: FormControl) {
    if (!control.value) {
      decodeInput.value = '';
    }
  }

  search: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      tap(() => {
        this.autocompleteLoading = true;
        this.cd.detectChanges();
      }),
      switchMap((term) => {
        const apiClient = this.injector.get(
          this.entityName + 'ApiClient'
        ) as BaseApiClient<BaseDtoModel>;

        return apiClient
          .getPaged(
            10,
            0,
            {
              [this.decodeField]: 'asc',
            },
            [
              {
                field: this.decodeField,
                operator: FilterOperator.contains,
                value: [term],
                type: FilterType.text,
              },
            ]
          )
          .pipe(
            map((res) => res.items.map((x: any) => x[this.decodeField])),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.autocompleteLoading = false;
              this.cd.detectChanges();
            })
          );
      })
    );
  };
}
