import { FilterType } from '@heitown/common-interfaces';

import { SelectItem } from './select-item';

export interface FilterConfig {
  // per form ricerca
  field: string;
  elementLabel: string;
  filterType: FilterType;
  elementValues?: SelectItem[];
  showIntoMoreFilters?: boolean;
  entityName?: string;
}
