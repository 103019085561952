import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { CustomSerializer } from './custom-serializer';
import { ngrxRouterFeatureKey } from './router.interfaces';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ngrxRouterFeatureKey, routerReducer),
    StoreRouterConnectingModule.forRoot({
      stateKey: ngrxRouterFeatureKey,
      serializer: CustomSerializer,
    }),
  ],
})
export class RoutingUtilsModule {}
