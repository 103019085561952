/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-date-field',
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-datepicker
        [hasError]="shouldShowError"
        [readOnly]="readOnly"
        [formControl]="formControl"
        (blur)="onBlur()"
      >
      </heitown-datepicker>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFieldComponent extends BaseFormFieldComponent {
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  onBlur() {
    this.blur.emit();
  }
}
