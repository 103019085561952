import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerViewDtoModel } from '@heitown/common-dto';
import { FilterValue, PagedResultDto } from '@heitown/common-interfaces';

import { ApiClientConfig } from '../api-client-config';
import { BaseApiClient } from './base.api-client';

@Injectable()
export class CustomerApiClient extends BaseApiClient<CustomerViewDtoModel> {
  baseUrl = this.config.apiEndpoint + '/user';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  sendEmail(link: string, email: string) {
    return this.http.post<void>(`${this.baseUrl}/customer/send-ts-email`, {
      link,
      email,
    });
  }

  override getPaged(
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' },
    filters?: FilterValue[],
    join?: string[]
  ) {
    return this.http.post<PagedResultDto<CustomerViewDtoModel>>(
      `${this.baseUrl}/customer/search`,
      {
        skip,
        take,
        orderBy,
        filters,
        join,
      }
    );
  }
}
