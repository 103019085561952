<ng-container [formGroup]="valueForm">
  <div class="d-flex align-items-center">
    <label class="text-secondary" *ngIf="showLabel">{{ label }}</label>

    <heitown-operators
      class="ms-auto"
      [items]="operators"
      name="operator"
      formControlName="operator"
    ></heitown-operators>

    <i
      class="icon ms-1 text-danger feather icon-x"
      [style.visibility]="showClear() ? 'visible' : 'hidden'"
      (click)="clear(true)"
    ></i>
    <!-- x-circle -->
  </div>
  <div class="d-flex align-items-center" formArrayName="value">
    <ng-container
      *ngFor="let valueControl of valueArray.controls; let i = index"
    >
      <heitown-text-input
        *ngIf="type === filterTypes.text"
        class="flex-grow-1"
        name="value"
        [formControl]="$any(valueControl)"
      >
      </heitown-text-input>

      <heitown-numeric-input
        *ngIf="type === filterTypes.number"
        class="flex-grow-1"
        name="value"
        [formControl]="$any(valueControl)"
      >
      </heitown-numeric-input>

      <heitown-numeric-input
        *ngIf="type === filterTypes.currency"
        class="flex-grow-1"
        name="value"
        [prefix]="'€'"
        [digitsOptional]="false"
        [nullable]="false"
        [formControl]="$any(valueControl)"
      >
      </heitown-numeric-input>

      <heitown-datepicker
        *ngIf="type === filterTypes.date"
        class="flex-grow-1"
        name="value"
        [showIcon]="false"
        [formControl]="$any(valueControl)"
      >
      </heitown-datepicker>

      <heitown-select
        *ngIf="type === filterTypes.enum || type === filterTypes.boolean"
        class="flex-grow-1"
        name="value"
        [items]="items"
        [clearable]="false"
        [bindValue]="'value'"
        [formControl]="$any(valueControl)"
      >
      </heitown-select>

      <div
        class="position-relative decode"
        *ngIf="type === filterTypes.autocomplete"
      >
        <input
          #decodeInput
          type="text"
          class="form-control"
          [formControl]="$any(valueControl)"
          [ngbTypeahead]="search"
          [showHint]="true"
          [editable]="false"
          [container]="'body'"
          (selectItem)="selected($event, $any(valueControl))"
          (blur)="blur(decodeInput, $any(valueControl))"
        />
        <div
          *ngIf="autocompleteLoading"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="visually-hidden">Caricamento...</span>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- <strong>
    {{ valueForm.value | json }}
  </strong> -->
</ng-container>
