import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationMessageProvider {
  public defaultValidationMessages: {
    [key: string]:
      | string
      | ((x: { allowEqual: boolean; comparand: string }) => string)
      | ((x: {
          comparand: string;
          actual: string;
          field: string;
          compareField: string;
        }) => string);
  } = {
    email: 'formato email non valido',
    required: 'campo obbligatorio',
    min: 'minimum value is _min',
    max: 'maximum value is _max',
    pattern: 'formato non corretto',
    dateLessThan: (errorInfo: { allowEqual: boolean; comparand: string }) =>
      `la data deve essere precedente ${
        errorInfo.allowEqual ? 'o uguale' : ''
      } a ${errorInfo.comparand}`,
    dateGreaterThan: (errorInfo: { allowEqual: boolean; comparand: string }) =>
      `la data deve essere successiva ${
        errorInfo.allowEqual ? 'o uguale' : ''
      } a ${errorInfo.comparand}`,
  };

  getMessage(key: string, errorInfo: any): string {
    let msg:
      | string
      | ((info: { allowEqual: boolean; comparand: string }) => string)
      | ((x: {
          comparand: string;
          actual: string;
          field: string;
          compareField: string;
        }) => string) = this.defaultValidationMessages[key] || '';

    if (typeof msg === 'string') {
      const parameters: { [key: string]: string } = {};
      Object.keys(errorInfo).forEach((ph) => {
        if (errorInfo[ph] && typeof errorInfo[ph] === 'string') {
          parameters[ph] = errorInfo[ph];
        }
      });
      if (Object.keys(parameters).length > 0) {
        msg = msg as string;
      }
      if (msg.indexOf('_') > -1 && errorInfo) {
        Object.keys(errorInfo).forEach((ph) => {
          const message = msg as string;
          msg = message.replace('_' + ph, errorInfo[ph]);
        });
      }
    } else {
      msg = msg(errorInfo);
    }
    if (!msg) {
      return '';
    } else {
      return msg;
    }
  }
}
