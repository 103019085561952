// import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { UiKitCoreModule } from '../core/uikit-core.module';
import { AttachmentFieldComponent } from './attachment-field/attachment-field.component';
import { AutocompleteFieldComponent } from './autocomplete-field/autocomplete-field.component';
import { CheckFieldComponent } from './check-field/check-field.component';
import { DateFieldComponent } from './date-field/date-field.component';
import { FormFieldWrapperComponent } from './form-field-wrapper.component';
import { NumericFieldComponent } from './numeric-field/numeric-field.component';
import { RadioFieldComponent } from './radio-field/radio-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TimeFieldComponent } from './time-field/time-field.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    UiKitCoreModule,
  ],
  declarations: [
    FormFieldWrapperComponent,
    TextFieldComponent,
    AutocompleteFieldComponent,
    CheckFieldComponent,
    DateFieldComponent,
    NumericFieldComponent,
    SelectFieldComponent,
    RadioFieldComponent,
    AttachmentFieldComponent,
    TimeFieldComponent,
  ],
  exports: [
    TextFieldComponent,
    AutocompleteFieldComponent,
    CheckFieldComponent,
    DateFieldComponent,
    NumericFieldComponent,
    SelectFieldComponent,
    RadioFieldComponent,
    AttachmentFieldComponent,
    TimeFieldComponent,
  ],
})
export class FormFieldsModule {}
