import {
    catchError, concat, distinctUntilChanged, Observable, of, Subject, switchMap, tap
} from 'rxjs';

/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input,
    OnChanges, Output
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { BaseDtoModel } from '@heitown/common-dto';
import { BaseApiClient } from '@heitown/frontend-api-client';
import { RouterService } from '@heitown/frontend-routing-utils';
import { DropdownPosition } from '@ng-select/ng-select';

export interface AutocompleteSelectConfig {
  searchFields: string[];
  orderBy: { [field: string]: 'asc' | 'desc' };
  apiClient: BaseApiClient<BaseDtoModel>;
  bindLabel: string;
}

@Component({
  selector: 'heitown-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnChanges {
  @Input()
  appendTo?: string = 'body';

  @Input()
  clearable = true;

  @Input()
  searchable = false;

  @Input()
  items: any[] = [];

  @Input()
  bindValue?: string;

  @Input()
  bindLabel = 'description';

  @Input()
  placeholder = '';

  @Output()
  changed = new EventEmitter();

  isDisabled = false;

  @Input()
  hasError = false;

  @Input()
  multiple = false;

  @Input()
  autocompleteConfig?: AutocompleteSelectConfig;

  @Input()
  minTermLength = 0;

  @Input()
  canAdd? = false;

  @Input()
  canView? = false;

  @Input()
  entityName?: string;

  @Input()
  readOnly = false;

  @Input()
  dropdownPosition: DropdownPosition = 'auto';

  @Input()
  searchFunction?: (term: string) => Observable<any[]>;

  value: any;

  items$?: Observable<BaseDtoModel[]>;
  dataLoading = false;
  textInput$: Subject<string> = new Subject<string>();

  get typeToSearchText() {
    return `inserisci ${this.minTermLength} o più caratteri per cercare`;
  }

  propagateChange = (value: any) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  constructor(
    private cd: ChangeDetectorRef,
    private routerService: RouterService
  ) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: any): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  ngOnChanges(): void {
    if (this.searchFunction) {
      const searchFunction = this.searchFunction;

      this.items$ = concat(
        of([]), // default items
        this.textInput$.pipe(
          distinctUntilChanged(),
          tap(() => {
            this.dataLoading = true;
          }),
          switchMap((term) =>
            searchFunction(term).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.dataLoading = false))
            )
          )
        )
      );
    } else {
      this.items$ = of(this.items);
    }
  }

  onModelChange($event: any) {
    this.changed.emit($event);
    this.propagateChange($event);
    this.onTouched();
  }

  async add() {
    if (this.entityName) {
      return this.routerService.addNewEntity(this.entityName);
    }
  }

  async view() {
    if (this.entityName) {
      this.routerService.viewEntityDetail(this.entityName, this.value);
    }
    // if (w) {
    //   w.onunload = (e) => alert('new window closed');
    // }
  }
}
