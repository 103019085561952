import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class LoaderEffects {
  constructor(private actions$: Actions) {}

  // showLoader$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoaderActions.showLoader),
  //     filter((action) => action.payload && action.payload.showLoader),
  //     map((action) => LoaderActions.showLoader(action))
  //   )
  // );

  // hideLoader$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoaderActions.hideLoader),
  //     filter((action) => action && !!action.payload.triggerAction),
  //     map((action) => LoaderActions.hideLoader(action))
  //   )
  // );
}
