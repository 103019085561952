import { FilterOperator, FilterType } from '@heitown/common-interfaces';
import { SelectItem } from '@heitown/frontend-common';

export interface OperatorItem extends SelectItem {
  filters: FilterType[];
  value: FilterOperator;
  // apply: (value: any, valueToCompare: any) => boolean;
}

// Date: equals, notEqual, lessThan, greaterThan, inRange, empty
// Numeri:  equals, notEqual, lessThan, lessThanOrEqual, greaterThan, greaterThanOrEqual, inRange, empty
// Testi: equals, notEqual, contains, notContains, startsWith, endsWith, empty

const allFilters = [
  FilterType.text,
  FilterType.date,
  FilterType.boolean,
  FilterType.number,
  FilterType.currency,
  FilterType.enum,
  FilterType.autocomplete,
];
const dateAndNumbers = [
  FilterType.date,
  FilterType.number,
  FilterType.currency,
];

export const OPERATORS: OperatorItem[] = [
  // {
  //   value: undefined,
  //   description: '',
  //   filters: [...allFilters],
  //   // apply: () => true
  // },
  {
    value: FilterOperator.contains,
    description: 'contiene',
    filters: [FilterType.text],
    // apply: (value: any, valueToCompare: any) =>
    //   value.toLowerCase().indexOf(valueToCompare.toLowerCase()) >= 0,
  },
  {
    value: FilterOperator.notContains,
    description: 'non contiene',
    filters: [FilterType.text],
    // apply: (value: any, valueToCompare: any) =>
    //   value.toLowerCase().indexOf(valueToCompare.toLowerCase()) < 0,
  },
  {
    value: FilterOperator.equals,
    description: 'uguale a',
    filters: [...allFilters],
    // apply: (value: any, valueToCompare: any) =>
    //   value === valueToCompare.toLowerCase,
  },
  {
    value: FilterOperator.notEqual,
    description: 'diverso da',
    filters: [...allFilters],
    // apply: (value: any, valueToCompare: any) => value != valueToCompare,
  },
  {
    value: FilterOperator.startsWith,
    description: 'inizia con',
    filters: [FilterType.text],
    // apply: (value: any, valueToCompare: any) =>
    //   value.toLowerCase().startsWith(valueToCompare.toLowerCase()),
  },
  {
    value: FilterOperator.endsWith,
    description: 'finisce per',
    filters: [FilterType.text],
    // apply: (value: any, valueToCompare: any) =>
    //   value.toLowerCase().endsWith(valueToCompare.toLowerCase()),
  },
  // {
  //   value: FilterOperator.empty,
  //   description: 'vuoto',
  //   filters: [...allFilters],
  // },
  {
    value: FilterOperator.lessThan,
    description: 'minore di',
    filters: [...dateAndNumbers],
  },
  {
    value: FilterOperator.lessThanOrEqual,
    description: 'minore o uguale a',
    filters: [...dateAndNumbers],
  },
  {
    value: FilterOperator.greaterThan,
    description: 'maggiore di',
    filters: [...dateAndNumbers],
  },
  {
    value: FilterOperator.greaterThanOrEqual,
    description: 'maggiore o uguale a',
    filters: [...dateAndNumbers],
  },
  {
    value: FilterOperator.inRange,
    description: 'compreso fra',
    filters: [...dateAndNumbers],
  },
];
