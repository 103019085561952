export interface FilterItem {
  // per form ricerca
  elementLabel: string;
  elementType: string; // tipo di filtro html, creare enum
  elementValues?: { id: string; description: string }[];

  // per filtro
  field: string; // nome del campo
  filterType: string; // tipo campo (text, data, ...) ?
  type?: string; // operatore
  filter?: string; // valore
}

