

/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseContainer } from '@heitown/frontend-base-ng-classes';



@Component({
  templateUrl: './password-changed.container.html',
  styleUrls: ['./password-changed.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChangedContainer extends BaseContainer {}
