<input
  *ngIf="!longText"
  #input
  [type]="controlType"
  class="form-control"
  [class.is-invalid]="hasError"
  (blur)="onBlur()"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="onChange()"  [readOnly]="readOnly"
/>
<textarea
  *ngIf="longText"
  #input
  class="form-control"
  [class.is-invalid]="hasError"
  (blur)="onBlur()"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="onChange()"  [readOnly]="readOnly"
></textarea>
