import { NgrxFormsModule } from 'ngrx-forms';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';

import { NgrxFormsErrorPipe } from './pipes/ngrx-forms-errors-pipe';

@NgModule({
  imports: [
    CommonModule,
    NgrxFormsModule,
    LetDirective, PushPipe,
    ReactiveFormsModule,
  ],
  exports: [
    NgrxFormsModule,
    NgrxFormsErrorPipe,
    LetDirective, PushPipe,
    ReactiveFormsModule,
  ],
  declarations: [NgrxFormsErrorPipe],
})
export class FrontendFormsModule {}
