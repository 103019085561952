import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaintenanceRequestDtoModel } from '@heitown/common-dto';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class MaintenanceRequestApiClient extends BaseCRUDApiClient<MaintenanceRequestDtoModel> {
  baseUrl = this.config.apiEndpoint + '/maintenance-request';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }
}
