/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';

let nextId = 0;

@Component({
  selector: 'heitown-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements OnInit, ControlValueAccessor {
  @Input()
  items!: { label: string; value: any }[];

  @Input()
  readOnly = false;

  @Input()
  hasError = false;

  id = `chk-${nextId++}`;

  value: any;
  isDisabled = false;

  propagateChange = (value: any) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  setDisabledState?(isDisabled: any): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    return res;
  }

  ngOnInit(): void {
    if (this.items == undefined) {
      throw console.error('items undefined');
    }
  }

  onModelChange($event: any) {
    this.propagateChange($event);
    this.onTouched();
  }

  onClick($event: Event) {
    if (this.readOnly) $event.preventDefault();
  }
}
