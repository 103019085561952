import { v4 as uuidv4 } from 'uuid';

/* eslint-disable no-empty */
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BaseDtoModel } from '@heitown/common-dto';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private _entityConfiguration = new Map<string, any>();
  ADD_MODE_PARAM = 'addMode';
  VIEW_MODE_PARAM = 'viewMode';
  FIND_MODE_PARAM = 'findMode';
  EDIT_MODE_PARAM = 'editMode';

  constructor(public router: Router, public activatedRoute: ActivatedRoute) {}

  isAddMode(route?: ActivatedRouteSnapshot) {
    route = route ?? this.activatedRoute.snapshot;
    return !!route.queryParams[this.ADD_MODE_PARAM];
  }

  isOnline() {
    return navigator.onLine;
  }

  isViewOnlyMode(route?: ActivatedRouteSnapshot) {
    route = route ?? this.activatedRoute.snapshot;
    return !!route.queryParams[this.VIEW_MODE_PARAM];
  }

  isFindMode(route?: ActivatedRouteSnapshot) {
    route = route ?? this.activatedRoute.snapshot;
    return !!route.queryParams[this.FIND_MODE_PARAM];
  }

  notifySelectedItemToOpener(item: BaseDtoModel) {
    const broadcastChannelId: string =
      this.activatedRoute.snapshot.queryParams[this.ADD_MODE_PARAM] ||
      this.activatedRoute.snapshot.queryParams[this.VIEW_MODE_PARAM] ||
      this.activatedRoute.snapshot.queryParams[this.FIND_MODE_PARAM] ||
      this.activatedRoute.snapshot.queryParams[this.EDIT_MODE_PARAM];
    if (broadcastChannelId) {
      const bc = new BroadcastChannel(broadcastChannelId);
      bc.postMessage(item);
    }
  }

  notifyDeletedItemToOpener(id: string) {
    const broadcastChannelId: string =
      this.activatedRoute.snapshot.queryParams[this.ADD_MODE_PARAM] ||
      this.activatedRoute.snapshot.queryParams[this.VIEW_MODE_PARAM] ||
      this.activatedRoute.snapshot.queryParams[this.FIND_MODE_PARAM] ||
      this.activatedRoute.snapshot.queryParams[this.EDIT_MODE_PARAM];
    if (broadcastChannelId) {
      const bc = new BroadcastChannel(broadcastChannelId);
      bc.postMessage({ id });
    }
  }

  addRoute(entityName: string, permissions: string[]) {
    this._entityConfiguration.set(entityName, permissions);
  }

  addNewEntity(entityName: string, queryParams?: string) {
    return this.openTab(
      window.location.origin + `/${entityName}/0`,
      this.ADD_MODE_PARAM,
      queryParams
    );
  }

  editEntityDetail(entityName: string, id: string) {
    return this.openTab(
      window.location.origin + `/${entityName}/${id}`,
      this.EDIT_MODE_PARAM
    );
  }

  searchEntity(entityName: string) {
    return this.openTab(
      window.location.origin + `/${entityName}`,
      this.FIND_MODE_PARAM
    );
  }

  async viewEntityDetail(entityName: string, id: string) {
    try {      
      await this.openTab(
        window.location.origin + `/${entityName}/${id}`,
        this.VIEW_MODE_PARAM
      );
    } catch {}
  }

  private openTab(url: string, modeParam: string, queryParams?: string) {
    return new Promise<any>((resolve, reject) => {
      const channelName = uuidv4();

      const h = 700;
      const width = 900;

      const top = (screen.height - h) / 4,
        left = (screen.width - width) / 2;
      const w = window.open(
        url +
          `?${modeParam}=${channelName}${queryParams ? '&' + queryParams : ''}`,
        '',
        `scrollbars=yes width=${width},height=${h},top=${top},left=${left}`
      );

      if (w == null) {
        reject('cannot open popup');
      } else {
        const loop = setInterval(() => {
          if (w.closed) {
            clearInterval(loop);
            reject('closed');
          }
        }, 500);
        const bc = new BroadcastChannel(channelName);
        bc.onmessage = (ev) => {
          console.log('received msg item added', ev.data);

          clearInterval(loop);
          w.close();
          bc.close();

          resolve(ev.data);
        };
      }
    });
  }
}
