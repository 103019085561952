import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReservationDtoModel } from '@heitown/common-dto';
import {
    FilterOperator, FilterType, FilterValue, PagedResultDto
} from '@heitown/common-interfaces';
import { ReservationStatus } from '@heitown/common-utils';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class ReservationApiClient extends BaseCRUDApiClient<ReservationDtoModel> {
  baseUrl = this.config.apiEndpoint + '/reservation';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  override getPaged(
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' } | undefined,
    filters?: FilterValue[] | undefined,
    join?: string[] | undefined
  ): Observable<PagedResultDto<ReservationDtoModel>> {
    if (!orderBy || Object.keys(orderBy).length === 0) {
      orderBy = { progressiveNumber: 'desc' };
    }

    return super.getPaged(take, skip, orderBy, filters, join);
  }

  getReservations(hotelId: string, dateFrom: string, dateTo: string) {
    return this.getPaged(
      9999,
      0,
      {},
      [
        {
          field: 'reservationFrom',
          operator: FilterOperator.lessThan,
          type: FilterType.date,
          value: [dateTo],
        },
        {
          field: 'reservationTo',
          operator: FilterOperator.greaterThanOrEqual,
          type: FilterType.date,
          value: [dateFrom],
        },
        {
          field: 'hotelId',
          operator: FilterOperator.equals,
          type: FilterType.text,
          value: [hotelId],
        },
        {
          field: 'status',
          operator: FilterOperator.notEqual,
          type: FilterType.text,
          value: [ReservationStatus.Canceled],
        },
      ],
      ['reservationRooms.room', 'reservationPersons.person', 'user']
    );
  }

  cancel(id: string) {
    return this.http.post<ReservationDtoModel>(`${this.baseUrl}/cancel`, {
      reservationId: id,
    });
  }
}
