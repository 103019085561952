import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { ApiClientConfig } from './api-client-config';
import { AllotmentApiClient } from './entities/allotment.api-client';
import { AuditApiClient } from './entities/audit.api-client';
import { CountryApiClient } from './entities/country.api-client';
import { CustomerApiClient } from './entities/customer.api-client';
import { HotelSettingApiClient } from './entities/hotel-setting.api-client';
import { HotelApiClient } from './entities/hotel.api-client';
import { MaintenanceRequestApiClient } from './entities/maintenance-request.api-client';
import { MunicipalityApiClient } from './entities/municipality.api-client';
import { PaymentApiClient } from './entities/payment.api-client';
import { PersonApiClient } from './entities/person.api-client';
import { PsIstatCardApiClient } from './entities/ps-istat-card.api-client';
import { ReservationPersonApiClient } from './entities/reservation-person.api-client';
import { ReservationApiClient } from './entities/reservation.api-client';
import { RoomPriceApiClient } from './entities/room-price.api-client';
import { RoomTypeApiClient } from './entities/room-type.api-client';
import { RoomApiClient } from './entities/room.api-client';
import { ServiceRequestApiClient } from './entities/service-request.api-client';
import { ServiceTypeApiClient } from './entities/service-type.api-client';
import { UserTypeApiClient } from './entities/user-type.api-client';
import { UserApiClient } from './entities/user.api-client';
import { FileApiClient } from './file.api-client';
import { StatusPageApiClient } from './status-page.api-client';

@NgModule({
  imports: [CommonModule],
})
export class ApiClientModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ApiClientModule
  ) {
    if (parentModule) {
      throw new Error(
        'ApiClientModule is already loaded. Import only in AppModule'
      );
    }
  }

  static forRoot(
    config: ApiClientConfig
  ): ModuleWithProviders<ApiClientModule> {
    return {
      ngModule: ApiClientModule,
      providers: [
        {
          provide: ApiClientConfig,
          useValue: config,
        },
        StatusPageApiClient,
        FileApiClient,
        UserApiClient,
        PaymentApiClient,
        {
          provide: 'userApiClient',
          useExisting: UserApiClient,
        },
        CustomerApiClient,
        {
          provide: 'customerApiClient',
          useExisting: CustomerApiClient,
        },
        CountryApiClient,
        {
          provide: 'countryApiClient',
          useExisting: CountryApiClient,
        },
        MunicipalityApiClient,
        {
          provide: 'municipalityApiClient',
          useExisting: MunicipalityApiClient,
        },
        ReservationApiClient,
        {
          provide: 'reservationApiClient',
          useExisting: ReservationApiClient,
        },
        UserTypeApiClient,
        {
          provide: 'userTypeApiClient',
          useExisting: UserTypeApiClient,
        },
        HotelApiClient,
        {
          provide: 'hotelApiClient',
          useExisting: HotelApiClient,
        },
        RoomTypeApiClient,
        {
          provide: 'roomTypeApiClient',
          useExisting: RoomTypeApiClient,
        },
        AllotmentApiClient,
        {
          provide: 'allotmentApiClient',
          useExisting: AllotmentApiClient,
        },
        PersonApiClient,
        {
          provide: 'personApiClient',
          useExisting: PersonApiClient,
        },
        ReservationPersonApiClient,
        {
          provide: 'reservationPersonApiClient',
          useExisting: ReservationPersonApiClient,
        },
        RoomApiClient,
        {
          provide: 'roomApiClient',
          useExisting: RoomApiClient,
        },
        MaintenanceRequestApiClient,
        {
          provide: 'maintenanceRequestApiClient',
          useExisting: MaintenanceRequestApiClient,
        },
        ServiceRequestApiClient,
        {
          provide: 'serviceRequestApiClient',
          useExisting: ServiceRequestApiClient,
        },
        RoomPriceApiClient,
        {
          provide: 'roomPriceApiClient',
          useExisting: RoomPriceApiClient,
        },
        HotelSettingApiClient,
        {
          provide: 'hotelSettingApiClient',
          useExisting: HotelSettingApiClient,
        },
        PsIstatCardApiClient,
        {
          provide: 'psIstatCardApiClient',
          useExisting: PsIstatCardApiClient,
        },
        ServiceTypeApiClient,
        {
          provide: 'serviceTypeApiClient',
          useExisting: ServiceTypeApiClient,
        },
        AuditApiClient,
        {
          provide: 'auditApiClient',
          useExisting: AuditApiClient,
        },
      ],
    };
  }
}
