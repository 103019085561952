<ng-select
  (ngModelChange)="onModelChange($event)"
  [clearable]="clearable"
  [class.is-invalid]="hasError"
  [disabled]="isDisabled"
  [items]="items$ | async"
  [bindLabel]="bindLabel"
  [bindValue]="$any(bindValue)"
  [(ngModel)]="value"
  [appendTo]="$any(appendTo)"
  [searchable]="searchable"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [typeahead]="textInput$"
  [loading]="dataLoading"
  [minTermLength]="minTermLength"
  [typeToSearchText]="typeToSearchText"
  [readonly]="readOnly"
  [dropdownPosition]="dropdownPosition"
>
  <ng-template ng-option-tmp let-item="item">
    <div [title]="item[bindLabel]">{{ item[bindLabel] }}</div>
  </ng-template>
</ng-select>
<i
  *ngIf="canView && value"
  class="ms-1 cursor-pointer text-primary feather icon-corner-up-right"
  (click)="view()"
></i>
<i
  *ngIf="canAdd"
  class="ms-1 cursor-pointer text-primary feather icon-file-plus"
  (click)="add()"
></i>
