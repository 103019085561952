/* eslint-disable @typescript-eslint/no-explicit-any */

import { Stream } from 'node:stream';

export const isImage = (mimeType: string) => {
  return mimeType.startsWith('image');
};

export const sort = (
  item1: any,
  item2: any,
  prop: string,
  caseSensitive = false,
  asc = true
) => {
  let item1Descr = item1[prop];
  let item2Descr = item2[prop];
  if (!caseSensitive) {
    item1Descr = item1[prop].toLowerCase();
    item2Descr = item2[prop].toLowerCase();
  }
  if (asc) {
    return item1Descr === item2Descr ? 0 : item1Descr < item2Descr ? -1 : 1;
  } else {
    return item1Descr === item2Descr ? 0 : item1Descr < item2Descr ? 1 : -1;
  }
};

export const hasPermission = (
  permission: string,
  entity: string,
  permissions?: string[]
) => {
  const entityName = entity.replace('Entity', '').toLowerCase(); // entity[0].toLowerCase() + entity.slice(1).replace('Entity', '');
  console.log('*-*-', permission, entityName, permissions);
  if (!permissions) {
    return false;
  }
  if (permissions?.indexOf(`${permission}_all`) > -1) {
    return true;
  } else {
    return permissions.indexOf(`${permission}_${entityName}`) > -1;
  }
};

export const getDescendantProp = (obj: any, path: string) => {
  const arr = path.split('.');
  while (arr.length && (obj = obj[arr.shift() as string]));
  return obj;
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export async function stream2buffer(stream: Stream): Promise<Buffer> {
  return new Promise<Buffer>((resolve, reject) => {
    const _buf = Array<any>();

    stream.on('data', (chunk) => _buf.push(chunk));
    stream.on('end', () => resolve(Buffer.concat(_buf)));
    stream.on('error', (err) => reject(`error converting stream - ${err}`));
  });
}
