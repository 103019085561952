import { map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FieldConfig, FilterValue, PagedResultDto } from '@heitown/common-interfaces';

import { ApiClientConfig } from '../api-client-config';

@Injectable()
export abstract class BaseApiClient<T> {
  abstract baseUrl: string;

  constructor(protected http: HttpClient, protected config: ApiClientConfig) {}

  getAll() {
    return this.http.get<T[]>(this.baseUrl);
  }

  getPaged(
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' },
    filters?: FilterValue[],
    join?: string[]
  ) {
    return this.http.post<PagedResultDto<T>>(`${this.baseUrl}/search`, {
      skip,
      take,
      orderBy,
      filters,
      join,
    });
  }

  downloadExcel(
    fileName?: string,
    columns?: FieldConfig[],
    filters?: FilterValue[],
    orderBy?: { [field: string]: 'asc' | 'desc' },
    join?: string[]
  ): Observable<ArrayBuffer> {
    return this.http
      .post(
        this.baseUrl + '/download-excel',
        { fileName, columns, filters, orderBy, join },
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  downloadPdf(
    fileName?: string,
    columns?: FieldConfig[],
    filters?: FilterValue[],
    orderBy?: { [field: string]: 'asc' | 'desc' },
    join?: string[]
  ): Observable<ArrayBuffer> {
    return this.http
      .post(
        this.baseUrl + '/download-pdf',
        { fileName, columns, filters, orderBy, join },
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  downloadLabel() {
    return this.http.get(this.baseUrl + '/download-label');
  }
}
