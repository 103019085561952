import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit,
    Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterOperator } from '@heitown/common-interfaces';

import { OperatorItem } from './operators';

@Component({
  selector: 'heitown-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorFilterComponent),
      multi: true,
    },
  ],
})
export class OperatorFilterComponent implements OnInit, ControlValueAccessor {
  private _value!: FilterOperator;
  get value() {
    return this._value;
  }

  // @Input()
  set value(value: FilterOperator) {
    this._value = value;
    // if (value !== this.operatorControl.value) {
    //   this.operatorControl.setValue(value);
    // }
    this.selectedItem = this.items.find((o) => o.value === value);
  }

  @Input()
  items: OperatorItem[] = [];

  selectedItem?: OperatorItem;

  @Output()
  valueChanged = new EventEmitter<FilterOperator>();

  constructor(private cd: ChangeDetectorRef) {}

  propagateChange!: (value: FilterOperator) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(obj: FilterOperator | undefined): void {
    if (obj != null) this.value = obj;
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setOperator(item: OperatorItem) {
    this.selectedItem = item;

    this.valueChanged.emit(item.value);

    this.propagateChange(item.value);
  }

  ngOnInit(): void {
    if (this.value === undefined) {
      // throw new Error('Operator value not set.');
    }
  }
}
