import { ColumnState } from '@ag-grid-community/core';

import { AgGridFilterModel } from './aggrid-models';

export interface GridState {
  pageSize: number;
  currentPage: number;
  totalRows: number;
  pageLoaded: boolean;
  columnState?: ColumnState[];
  filterModel?: AgGridFilterModel;
}
