/* eslint-disable @typescript-eslint/no-explicit-any */
export function notUndefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}

export function notNull<T>(item: T | null): item is T {
  return item !== null;
}

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}
