<div class="content-panel filters">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="showFilters = !showFilters"
  >
    <div class="title mb-0">Filtri</div>
    <div
      class="badge ms-2 d-flex align-items-center justify-content-center"
      *ngIf="values.length > 0"
    >
      <div>{{ values.length }}</div>
    </div>
    <i
      class="ms-auto action feather"
      [class]="{
        'icon-chevron-down': !showFilters,
        'icon-chevron-up': showFilters
      }"
    ></i>
  </div>
  <form (submit)="search()" [formGroup]="form" [(ngbCollapse)]="!showFilters">
    <ng-content></ng-content>
    <div class="mt-2 d-flex">
      <div class="row flex-grow-1">
        <ng-container
          *ngFor="
            let filter of filterConfig;
            let i = index;
            trackBy: filterField
          "
        >
          <div
            class="col-md-6 col-lg-3 heitown-form-field"
            *ngIf="!filter.showIntoMoreFilters"
          >
            <heitown-single-filter
              [label]="filter.elementLabel"
              [items]="filter.elementValues || []"
              [field]="filter.field"
              [entityName]="filter.entityName"
              [type]="filter.filterType"
              [formControlName]="filter.field"
            ></heitown-single-filter>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="d-flex align-items-center text-primary"
      *ngIf="existsMoreFilters"
    >
      <div class="fw-bolder">Altri filtri</div>
      <i
        class="ms-2 action feather cursor-pointer"
        [class]="{
          'icon-arrow-down-circle': !showMoreFilters,
          'icon-arrow-up-circle': showMoreFilters
        }"
        (click)="showMoreFilters = !showMoreFilters"
      ></i>
    </div>
    <div
      *ngIf="existsMoreFilters"
      class="mt-3"
      [(ngbCollapse)]="!showMoreFilters"
    >
      <div class="row flex-grow-1">
        <ng-container
          *ngFor="
            let filter of filterConfig;
            let i = index;
            trackBy: filterField
          "
        >
          <div
            class="col-md-6 col-lg-3 heitown-form-field"
            *ngIf="filter.showIntoMoreFilters"
          >
            <heitown-single-filter
              [label]="filter.elementLabel"
              [items]="filter.elementValues || []"
              [type]="filter.filterType"
              [formControlName]="filter.field"
            ></heitown-single-filter>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="mt-3 action-button-container">
      <button class="btn btn-primary action-button" type="submit">
        Applica
      </button>
      <button class="btn clear action-button" (click)="reset()" type="reset">
        Pulisci filtri
      </button>
    </div>
  </form>
</div>
