import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ToastService } from '@heitown/frontend-ui-kit';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as ErrorActions from './ngrx-error.actions';

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions, private toastService: ToastService) {}

  apiError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.addApiErrorMsg),
        tap((action) => {
          if (action.errorMessage) {
            this.toastService.errorFeedback(action.errorMessage);
          }
          console.error(action.errorMessage);
        })
      ),
    { dispatch: false }
  );

  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.addError),
        tap((action) => {
          console.error('Errore imprevisto:', action.error);
        })
      ),
    { dispatch: false }
  );
}
