import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClientConfig } from '../api-client-config';


@Injectable()
export class PaymentApiClient  {
  baseUrl = this.config.apiEndpoint + '/payment';

  constructor(private http: HttpClient, private config: ApiClientConfig) {
    
  }

  getDailyRevenue (
    hotelId: string,
    startDate: string,
    endDate: string,    
  ) {
    return this.http.post<{ day: string; reservationsRevenue: number;extraServicesRevenue:number }[]>(
      this.baseUrl + '/daily-revenue',
      {
        hotelId:hotelId,        
        from:startDate,
        to:endDate,
      },
      
    );
  }
}
