import { FieldType } from '@heitown/common-interfaces';

import { FilterConfig } from './filter-config';

export interface EntityListField extends FilterConfig {
  hideFilter?: boolean;
  hideSort?: boolean;
  hideColumn?: boolean;
  hideExport?: boolean;
  fieldType?: FieldType;
  customCellRenderer?: (data: any) => string;
}
