import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from './api-client-config';

@Injectable()
export class StatusPageApiClient {
  baseUrl = this.config.apiEndpoint + '/status-page';

  constructor(private http: HttpClient, private config: ApiClientConfig) {}

  getDbVersion() {
    return this.http.get<unknown>(this.baseUrl);
  }
}
