<div class="main-wrapper" [class.hide-nav]="hideNav$ | ngrxPush">
  <!-- partial:sidebar -->
  <heitown-sidebar [hidden]="hideNav$ | ngrxPush"></heitown-sidebar>
  <!-- partial -->

  <div class="page-wrapper" [class.hide-nav]="hideNav$ | ngrxPush">
    <!-- partial:navbar -->
    <heitown-navbar
      [hideNavbar]="(hideNav$ | ngrxPush) || false"
    ></heitown-navbar>
    <!-- partial -->

    <div class="page-content d-flex flex-column flex-grow-1">
      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <div
        class="d-flex flex-column flex-grow-1"
        contentAnimate
        *ngIf="!isLoading"
      >
        <heitown-breadcrumb></heitown-breadcrumb>
        <router-outlet></router-outlet>
      </div>
    </div>

    <!-- partial:footer -->
    <heitown-footer></heitown-footer>
    <!-- partial -->
  </div>
</div>
