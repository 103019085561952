import { map } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { RouterService } from '@heitown/frontend-routing-utils';

@Component({
  selector: 'heitown-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  isLoading: boolean | undefined;

  hideNav$ = this.activatedRoute.queryParams.pipe(
    map(
      (params) =>
        !!params[this.routerService.VIEW_MODE_PARAM] ||
        !!params[this.routerService.ADD_MODE_PARAM] ||
        !!params[this.routerService.FIND_MODE_PARAM] ||
        !!params[this.routerService.EDIT_MODE_PARAM]
    )
  );

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService
  ) {
    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
        this.cd.detectChanges();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
        this.cd.detectChanges();
      }
    });
  }
}
