import { AgFrameworkComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { DataGridActionRendererParams } from './data-grid-action-renderer-params';

// export interface CustomGridAction {
//   description: string;
//   class: string;
//   onAction: (entity: any) => void;
// }
// export interface CustomGridActionRendererParams extends ICellRendererParams {
//   actions: CustomGridAction[];
// }

@Component({
  template: `
    <div class="d-flex h-100 align-items-center grid-action-container">
      <ng-container *ngFor="let action of actions">
        <i
          class="action feather"
          [class]="action.class"
          (click)="action.onAction(params.data)"
          [title]="action.description"
        ></i>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .grid-action-container .action {
        font-size: 20px;
        cursor: pointer;
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
      }
      .grid-action-container .action.red {
        color: red !important;
      }
    `,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DataGridActionRenderer
  implements AgFrameworkComponent<DataGridActionRendererParams>
{
  public params!: DataGridActionRendererParams;

  agInit(params: DataGridActionRendererParams): void {
    this.params = params;

    this.params.api.setColumnWidths([
      { key: 'actions', newWidth: this.actions.length * 28 + 10 },
    ]);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    // set value into cell again
    console.log('refresh');
  }

  get actions() {
    return this.params.context.actions.filter((a) =>
      a.visible(this.params.data)
    );
  }
}
