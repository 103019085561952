/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input
} from '@angular/core';
import {
    ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';

let nextId = 0;
@Component({
  selector: 'heitown-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  public value = false;
  public isDisabled = false;

  id = `chk-${nextId++}`;

  @Input()
  readOnly = false;

  @Input()
  hasError = false;

  @Input()
  label!: string;

  propagateChange = (value: boolean) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: boolean) => void) {
    this.propagateChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  // ngOnInit(): void {
  //   if (this.label == undefined) {
  //     throw console.error('label undefined');
  //   }
  // }

  onModelChange() {
    this.propagateChange(this.value);
    this.onTouched();
  }

  onClick($event: Event) {
    if (this.readOnly) $event.preventDefault();
  }
}
