/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormFieldComponent } from '../base-form-field.component';

@Component({
  selector: 'heitown-select-field',
  template: `
    <heitown-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError"
      [errors]="errors"
    >
      <heitown-select
        [hasError]="shouldShowError"
        [formControl]="formControl"
        [bindValue]="bindValue"
        [clearable]="clearable"
        [bindLabel]="bindLabel"
        [multiple]="multiple"
        [placeholder]="placeholder"
        [searchable]="searchable"
        [readOnly]="readOnly"
        [items]="items"
        [canAdd]="canAdd"
        [canView]="canView"
      >
      </heitown-select>
    </heitown-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFieldComponent extends BaseFormFieldComponent {
  @Input()
  placeholder = '';

  @Input()
  bindValue = 'id';

  @Input()
  bindLabel = 'description';

  @Input()
  multiple = false;

  @Input()
  searchable = false;

  @Input()
  clearable = false;

  @Input()
  items!: any[];

  @Input()
  canAdd? = false;

  @Input()
  canView? = false;

  override ngOnInit() {
    super.ngOnInit();
    if (!this.items) {
      throw new Error('items unspecified for select');
    }
  }
}
