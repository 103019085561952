import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDtoModel } from '@heitown/common-dto';
import { FilterValue, PagedResultDto } from '@heitown/common-interfaces';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class UserApiClient extends BaseCRUDApiClient<UserDtoModel> {
  baseUrl = this.config.apiEndpoint + '/user';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  override getPaged(
    take: number,
    skip: number,
    orderBy?: { [field: string]: 'asc' | 'desc' },
    filters?: FilterValue[],
    join?: string[]
  ) {
    return this.http.post<PagedResultDto<UserDtoModel>>(
      `${this.baseUrl}/staff/search`,
      {
        skip,
        take,
        orderBy,
        filters,
        join,
      }
    );
  }
}
