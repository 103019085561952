import * as moment from 'moment';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiClientModule } from '@heitown/frontend-api-client';
import { AuthModule } from '@heitown/frontend-auth';
import { ENVIRONMENT } from '@heitown/frontend-common';
import { FrontendErrorModule } from '@heitown/frontend-error';
import { FrontendFormsModule } from '@heitown/frontend-forms';
import { FrontendNgrxLoaderModule } from '@heitown/frontend-ngrx-loader';
import { RoutingUtilsModule } from '@heitown/frontend-routing-utils';
import { UiKitModule } from '@heitown/frontend-ui-kit';
import {
  NgbCollapseModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LayoutComponent } from './containers/layout/layout.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    //routes
    AppRoutingModule,
    //ngrx store
    StoreModule.forRoot(
      {},
      {
        // metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgxPermissionsModule.forRoot(),
    EffectsModule.forRoot([]),
    FrontendErrorModule.forRoot(),
    ApiClientModule.forRoot({
      apiEndpoint: environment.apiBaseUrl,
    }),
    // from theme
    CommonModule,
    FrontendFormsModule,
    NgbDropdownModule,
    NgbCollapseModule,

    UiKitModule,
    AuthModule.forRoot({
      apiEndpoint: environment.apiBaseUrl,
      userPoolId: environment.cognitoUserPoolId,
      clientId: environment.cognitoClientId,
      oauthDomain: environment.cognitoOAuthDomain,
      region: environment.cognitoRegion,
      identityPoolId: environment.cognitoIdentityPoolId,
      localMock: environment.environment === 'local',
    }),
    FrontendNgrxLoaderModule,
    RoutingUtilsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: ENVIRONMENT, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeIt);
    moment.locale('it');
  }
}
