/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { AuthFacade } from '../../+state/auth.facade';

@Component({
  templateUrl: './login-root.container.html',
  styleUrls: ['./login-root.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginRootContainer {
  constructor(private authFacade: AuthFacade) {}

  @ViewChild('img')
  image?: ElementRef<HTMLImageElement>;

  onError() {
    this.image?.nativeElement.setAttribute('src', '/assets/img/logo.png');
  }
}
