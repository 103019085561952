<div class="custom-control custom-switch">
  <input
    type="checkbox"
    [disabled]="isDisabled"
    class="custom-control-input"
    id="heitown-sw-{{ id }}"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
  />
  <label class="custom-control-label" for="heitown-sw-{{ id }}"
    >{{ label }}
  </label>
</div>
