import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbModule } from 'xng-breadcrumb';

import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LetDirective, PushPipe } from '@ngrx/component';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UiKitCoreModule } from './core/uikit-core.module';
import { EntityListComponent } from './entity-list/entity-list.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FeatherIconDirective } from './feather-icon/feather-icon.directive';
import { FiltersFormComponent } from './filters/filters-form/filters-form.component';
import { OperatorFilterComponent } from './filters/operators/operators.component';
import { SingleFilterComponent } from './filters/single-filter/single-filter.component';
import { FormFieldsModule } from './form-fields/form-fields.module';
import { CustomGridFilterComponent } from './grid/custom-grid-filter.component';
import { DataGridActionRenderer } from './grid/data-grid/data-grid-action-renderer.component';
import { DataGridComponent } from './grid/data-grid/data-grid.component';
import { LoaderComponent } from './loader/loader.component';
import { ToastComponent } from './toast/toast.component';

// import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbNavModule,
    LetDirective,
    PushPipe,
    NgxPermissionsModule.forChild(),
    UiKitCoreModule,
    NgbDropdownModule,
    FormFieldsModule,
    BreadcrumbModule,
    NgbCollapseModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      tapToDismiss: true,
      // iconClasses: {
      //   error: 'toast-error',
      //   info: 'toast-info',
      //   success: 'toast-success',
      //   warning: 'toast-warning',
      // },
    }),
  ],
  declarations: [
    FiltersFormComponent,
    SingleFilterComponent,
    OperatorFilterComponent,
    EntityListComponent,
    CustomGridFilterComponent,
    DataGridActionRenderer,
    BreadcrumbComponent,
    LoaderComponent,
    ToastComponent,
    ErrorPageComponent,
    DataGridComponent,
    FeatherIconDirective,
  ],
  exports: [
    ErrorPageComponent,
    FiltersFormComponent,
    EntityListComponent,
    UiKitCoreModule,
    CustomGridFilterComponent,
    FormFieldsModule,
    BreadcrumbComponent,
    LoaderComponent,
    DataGridComponent,
    FeatherIconDirective,
    NgbNavModule,
  ],
  // schemas: [NO_ERRORS_SCHEMA],
})
export class UiKitModule {}
