import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminSignUpRequestDto, UserDtoModel } from '@heitown/common-dto';

import { AuthConfig } from './auth-config';

export enum AuthErrors {
  LoginFailed = 0,
  UserMustResetPassword = 1,
}

@Injectable()
export abstract class AuthService {
  constructor(protected config: AuthConfig, protected http: HttpClient) {}

  static configureAuth() {}

  abstract login(
    username: string,
    password: string,
    rememberMe: boolean,
    newPassword?: string
  ): Observable<any>;

  abstract googleSignIn(rememberMe: boolean): Observable<any>;

  abstract facebookSignIn(rememberMe: boolean): Observable<any>;

  abstract logout(): Observable<void>;

  abstract signedIn(): Observable<boolean>;

  abstract getToken(): Observable<string | undefined>;

  abstract changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<boolean>;

  abstract forgotPassword(username: string): Observable<any>;

  abstract resetPassword(
    username: string,
    code: string,
    new_password: string
  ): Observable<any>;

  me() {
    return this.http.get<UserDtoModel>(
      `${this.config.apiEndpoint}/auth/user/me`
    );
  }

  updateMe(changes: Partial<UserDtoModel>) {
    return this.http.put<UserDtoModel>(
      this.config.apiEndpoint + '/auth/user/me',
      changes
    );
  }

  invite(signupRequest: AdminSignUpRequestDto) {
    return this.http.post<UserDtoModel>(
      this.config.apiEndpoint + '/auth/user/admin-sign-up',
      signupRequest
    );
  }
}
