/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'heitown-form-field-wrapper',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
  template: `
    <label class="text-secondary">{{ label }}</label>
    <ng-content></ng-content>
    <ng-container *ngIf="shouldShowError">
      <div class="invalid-feedback" *ngFor="let err of errors">{{ err }}</div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldWrapperComponent {
  @Input()
  label = '';

  @Input()
  errors: string[] = [];

  @Input()
  shouldShowError = false;
}
