import { NgxPermissionsService } from 'ngx-permissions';
import { firstValueFrom } from 'rxjs';

import { IServerSideGetRowsParams } from '@ag-grid-community/core';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseDtoModel } from '@heitown/common-dto';
import { FilterValue } from '@heitown/common-interfaces';
import { BaseApiClient } from '@heitown/frontend-api-client';
import {
  FacadeInterface,
  SharedFacadeInterface,
} from '@heitown/frontend-common';
import { NgrxLoaderFacade } from '@heitown/frontend-ngrx-loader';
import { RouterService } from '@heitown/frontend-routing-utils';

import { BasePagedListContainer } from './base-paged-list.container';

@Component({
  template: '',
})
export abstract class BaseEntityPagedListContainer<T extends BaseDtoModel>
  extends BasePagedListContainer<T>
  implements OnInit
{
  constructor(
    @Inject('SharedEntityFacade')
    sharedFacade: SharedFacadeInterface<T>,
    @Inject('EntityFacade')
    facade: FacadeInterface<T>,
    routerService: RouterService,
    permissionsService: NgxPermissionsService,
    protected apiClient: BaseApiClient<T>,
    loaderFacade: NgrxLoaderFacade
  ) {
    super(
      sharedFacade,
      facade,
      routerService,
      permissionsService,
      loaderFacade
    );
  }

  override async getRows(
    pageSize: number,
    params: IServerSideGetRowsParams,
    orderBy: { [x: string]: any } | undefined,
    filters: FilterValue[]
  ) {
    const result = await firstValueFrom(
      this.apiClient.getPaged(
        pageSize,
        params.request.startRow || 0,
        orderBy,
        filters,
        this.join
      )
    );

    this.sharedFacade.loadPagedSuccess(result.items);

    params.success({
      rowData: result.items,
      rowCount: result.totalCount,
    });
    return result.totalCount;
  }
}
