import { of } from 'rxjs';
import {
  catchError,
  first,
  flatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgrxLoaderFacade } from '@heitown/frontend-ngrx-loader';
import { ToastService } from '@heitown/frontend-ui-kit';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, pessimisticUpdate } from '@ngrx/router-store/data-persistence';

import { AuthErrors, AuthService } from '../auth.service';
import { ProfileContainer } from '../containers/profile/profile.container';
import * as AuthActions from './auth.actions';
import { AuthFacade } from './auth.facade';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authFacade: AuthFacade,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderFacade: NgrxLoaderFacade,
    private dialogService: NgbModal,
    private toastsService: ToastService
  ) {}

  failure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.loadLoggedUserFailure,
          AuthActions.profileFormSaveFailure,
          AuthActions.profileChangePasswordFormSaveFailure,
          AuthActions.forgotPasswordFailure,
          AuthActions.profilePhotoSaveFailure,
          AuthActions.inviteFailure
        ),
        tap((a) => this.toastsService.errorFeedback(a.error))
      ),
    { dispatch: false }
  );

  invite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.invite),
      pessimisticUpdate({
        // provides an action
        run: (action) => {
          this.loaderFacade.showLoader(action);
          const req = action.request;
          return this.authService.invite(req).pipe(
            map((invitedUser) => {
              this.loaderFacade.hideLoader(action);
              return AuthActions.inviteSuccess({ user: invitedUser });
            })
          );
        },
        onError: (action, error) => {
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          console.error('Error', error);
          this.loaderFacade.hideLoader(action);
          return AuthActions.inviteFailure({ error });
        },
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      fetch({
        run: (a) => {
          this.loaderFacade.showLoader(a);
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.authService
            .login(a.username, a.password, a.rememberMe, a.newPassword)
            .pipe(
              switchMap(() => {
                this.loaderFacade.hideLoader(a);
                return of(AuthActions.loginSuccess({}));
              })
            );
        },
        onError: (action, error) => {
          this.loaderFacade.hideLoader(action);
          if (error === AuthErrors.UserMustResetPassword) {
            this.authFacade.loginFormInitializeWithTmpPassword(
              action.username,
              action.password
            );
            this.router.navigateByUrl('auth/login');

            return AuthActions.setUserMustResetPassword({
              source: action.source,
            });
          }
          return AuthActions.loginFailure({
            error: error.message ? error.message : error,
          });
        },
      })
    )
  );

  googleSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.googleSignIn),
      tap((a) => {
        console.log('google sign in effect');
        this.authService.googleSignIn(a.rememberMe);
      }),
      first()
    )
  );

  facebookSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.facebookSignIn),
      tap((a) => {
        this.authService.facebookSignIn(a.rememberMe);
      }),
      first()
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        withLatestFrom(this.authFacade.selectSource$),
        tap(([a, source]) => {
          if (source === 'admin') this.router.navigateByUrl('/');
          else this.router.navigateByUrl('/auth/password-changed');
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        return this.authService.logout().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError(() => of(AuthActions.logoutFailure))
        );
      })
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        tap(() => this.router.navigateByUrl('/auth/login'))
      ),
    { dispatch: false }
  );

  loadLoggedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadLoggedUser),
      fetch({
        run: (a) => {
          this.loaderFacade.showLoader(a);
          // Your custom service 'load' logic goes here. For now just return a success action...

          return this.authService.me().pipe(
            switchMap((userResponse) => {
              this.loaderFacade.hideLoader(a);
              return of(
                AuthActions.loadLoggedUserSuccess({
                  user: {
                    ...userResponse,
                  },
                })
              );
            })
          );
        },
        onError: (action, error) => {
          this.loaderFacade.hideLoader(action);
          console.error('Error', error);
          return AuthActions.loadLoggedUserFailure({ error });
        },
      })
    )
  );

  loadLoggedUserFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadLoggedUserFailure),
      map(() => AuthActions.logout())
    )
  );

  showProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.showProfile),
        tap((a) => {
          this.dialogService.open(ProfileContainer, {
            size: 'lg',
            backdrop: 'static',
            modalDialogClass: 'profile-container',
          });
        })
      ),
    { dispatch: false }
  );

  profileSettingsSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.profileSettingsSave),
      withLatestFrom(
        this.authFacade.selectLoggedUser$,
        this.authFacade.getSelectedProfileTab$,
        this.authFacade.selectProfileSettingsForm$,
        this.authFacade.selectProfileChangePasswordForm$
      ),
      map(
        ([
          action,
          loggedUser,
          selectedTab,
          ProfileSettingsForm,
          ProfileChangePasswordForm,
        ]) => {
          if (loggedUser) {
            if (selectedTab === 0) {
              // Set First access

              return AuthActions.profileFormSave({
                profile: {
                  ...loggedUser,
                  ...ProfileSettingsForm.value,
                },
              });
            } else if (selectedTab === 1) {
              return AuthActions.profileChangePasswordFormSave({
                ...ProfileChangePasswordForm.value,
              });
            }
            return AuthActions.profileFormSaveFailure({
              error: 'Invalid selected tab',
            });
          }
          return AuthActions.profileFormSaveFailure({
            error: 'Loggeduser not present',
          });
        }
      )
    )
  );

  saveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.profileFormSaveSuccess,
          AuthActions.profileChangePasswordFormSaveSuccess,
          AuthActions.resetPasswordSuccess,
          AuthActions.profilePhotoSaveSuccess
        ),
        tap(() =>
          this.toastsService.successFeedback(
            'Salvataggio completato con successo'
          )
        )
      ),
    { dispatch: false }
  );

  profileFormSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.profileFormSave),
      pessimisticUpdate({
        // provides an action
        run: (action) => {
          this.loaderFacade.showLoader(action);
          const loggedUser = action.profile;
          return this.authService.updateMe(loggedUser).pipe(
            map((updated) => {
              this.loaderFacade.hideLoader(action);
              // reset form action
              this.authFacade.profileSettingsFormActionInitialize();
              return AuthActions.profileFormSaveSuccess({ profile: updated });
            })
          );
        },
        onError: (action, error) => {
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          console.error('Error', error);
          this.loaderFacade.hideLoader(action);
          return AuthActions.profileFormSaveFailure({ error });
        },
      })
    )
  );

  profileFormSaveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.profileFormSaveSuccess),
      map((action) => {
        // aggiorno lo stato Auth
        return AuthActions.loadLoggedUserSuccess({
          user: {
            ...action.profile,
          },
        });
      })
    )
  );

  profileChangePasswordFormSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.profileChangePasswordFormSave),
      pessimisticUpdate({
        // provides an action
        run: (action) => {
          this.loaderFacade.showLoader(action);
          return this.authService
            .changePassword(action.password, action.newPassword)
            .pipe(
              map((result) => {
                this.loaderFacade.hideLoader(action);
                // reset form action
                this.authFacade.profileChangePasswordFormActionInitialize();
                return AuthActions.profileChangePasswordFormSaveSuccess();
              })
            );
        },
        onError: (action, error) => {
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          console.error('Error', error);
          this.loaderFacade.hideLoader(action);
          return AuthActions.profileChangePasswordFormSaveFailure({
            ...error,
            error: error.message,
          });
        },
      })
    )
  );

  forgotPassword = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      fetch({
        run: (a) => {
          this.loaderFacade.showLoader(a);
          return this.authService.forgotPassword(a.username).pipe(
            map((res) => {
              this.loaderFacade.hideLoader(a);
              if (res === 'ERROR') {
                return AuthActions.forgotPasswordFailure({
                  error: 'There was a problem sending the email',
                });
              } else {
                return AuthActions.forgotPasswordSuccess(
                  res.CodeDeliveryDetails
                );
              }
            })
          );
        },
        onError: (action, error) => {
          this.loaderFacade.hideLoader(action);
          return AuthActions.forgotPasswordFailure({
            error: error.message ? error.message : error,
          });
        },
      })
    )
  );

  forgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.forgotPasswordSuccess),
        tap(() =>
          this.toastsService.successFeedback(
            "L'email per il reset della password è stata inviata."
          )
        )
      ),
    { dispatch: false }
  );

  resetPassword = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      fetch({
        run: (a) => {
          this.loaderFacade.showLoader(a);
          // Your custom service 'load' logic goes here. For now just return a success action...
          return of(a).pipe(
            withLatestFrom(this.authFacade.selectResetPasswordForm$),
            flatMap(([action, resetPasswordForm]) => {
              return this.authService
                .resetPassword(
                  action.username,
                  action.code,
                  resetPasswordForm.value.newPassword
                )
                .pipe(
                  map(() => {
                    this.loaderFacade.hideLoader(action);

                    if (a.source === 'admin') {
                      this.router.navigateByUrl('auth/login');
                    } else {
                      this.router.navigateByUrl('/auth/password-changed');
                    }
                    return AuthActions.resetPasswordSuccess();
                  })
                );
            })
          );
        },
        onError: (action, error) => {
          this.loaderFacade.hideLoader(action);

          if (error.code === 'ExpiredCodeException') {
            error.message =
              'Your reset password request is invalid or expired. Please reset your password again';
          }

          return AuthActions.resetPasswordFailure({
            error: error.message ? error.message : error,
          });
        },
      })
    )
  );

  // profilePhotoSave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.profilePhotoSave),
  //     pessimisticUpdate({
  //       // provides an action
  //       run: (action) => {
  //         this.loaderFacade.showLoader(action);
  //         return of(action).pipe(
  //           withLatestFrom(
  //             this.authFacade.selectLoggedUser$,
  //             this.authFacade.selectProfilePhotoForm$
  //           ),
  //           switchMap(([action, loggedUser, profilePhotoForm]) => {
  //             let picture = '';
  //             const photo = unbox(profilePhotoForm.value.photo);
  //             if (photo) {
  //               picture =
  //                 'data:' + photo.contentType + ';base64,' + photo.content;
  //             }
  //             const profile = {
  //               ...loggedUser,
  //               image: '',
  //               picture,
  //             };
  //             return this.service.updateMe(profile).pipe(
  //               map((updated) => {
  //                 this.loaderFacade.hideLoader(action);
  //                 return AuthActions.profilePhotoSaveSuccess({
  //                   profile: updated,
  //                 });
  //               })
  //             );
  //           })
  //         );
  //       },
  //       onError: (action, error) => {
  //         // we don't need to undo the changes on the client side.
  //         // we can dispatch an error, or simply log the error here and return `null`
  //         console.error('Error', error);
  //         this.loaderFacade.hideLoader(action);
  //         return AuthActions.profilePhotoSaveFailure({
  //           error,
  //         });
  //       },
  //     })
  //   )
  // );

  profilePhotoSaveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.profilePhotoSaveSuccess),
      map((action) => {
        // aggiorno lo stato Auth
        return AuthActions.loadLoggedUserSuccess({
          user: {
            ...action.profile,
          },
        });
      })
    )
  );
}
