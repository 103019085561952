import { createAction, props } from '@ngrx/store';

export const addError = createAction(
  '[Ngrx/Error] Add Error',
  props<{ error: Error }>()
);

export const addApiErrorMsg = createAction(
  '[Ngrx/Error] Add API Error',
  props<{ errorStatusCode: number; errorMessage: string }>()
);
